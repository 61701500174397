import React from 'react';
import NavigationItem from "../../Layouts/Footer/NavigationItem";
import {appConfig} from "../../../config";
import {Link} from "react-router-dom";

const Icons = () => {


  return (
    <section>


      <div className={"nav-item-container home-icons-container"}>
        {/*<NavigationItem className={"b1"} link={"https://bimeh.mashinno.com"} title={"خرید بیمه"} image={"/assets/app/insurance.png"} width={38} height={40}/>*/}
        {/*<NavigationItem className={"b8"} link={"https://bimeh.mashinno.com"} title={"خرید بیمه"} image={"/assets/1/bime.png"} width={38} height={40}/>*/}

        <Link to={"/insurance/car-body"} className={"nav-item home-item"}>
          <div className={`nav-image ${"b8"}`}>
            <img  src={"/icons/1.png"} width={48}/>
          </div>
          <span style={{fontSize: "9pt", color: "#222", lineHeight: "16px"}}>بیمه بدنه</span>
        </Link>

        <Link to={"/insurance/third-party"} className={"nav-item home-item"}>
          <div className={`nav-image ${"b5"}`}>
            <img src={"/icons/2.png"} width={48}/>
          </div>
          <span style={{fontSize: "9pt", color: "#222", lineHeight: "16px"}}>بیمه شخص ثالث</span>
        </Link>

        <Link to={"/insurance/third-party-motor"} className={"nav-item home-item"}>
          <div className={`nav-image ${"b7"}`}>
            <img src={"/icons/3.png"} width={48}/>
          </div>
          <span style={{fontSize: "9pt", color: "#222", lineHeight: "16px"}}>بیمه موتور</span>
        </Link>



      </div>


      <div className={"nav-item-container home-icons-container"} style={{justifyContent: "flex-start"}}>
        <Link to={`/inquiry/license-negative-score`} className={"nav-item home-item"}>
          <div className={`nav-image b5`}>
            <svg width="42" height="42" viewBox="0 0 32 32" fill="#fff" xmlns="http://www.w3.org/2000/svg" style={{display: "block", margin: "8px auto"}}>
              <path fillRule="evenodd" clipRule="evenodd" d="M17.4515 9.98139C17.4515 9.6132 17.7499 9.31472 18.1181 9.31472H23.3358C23.704 9.31472 24.0025 9.6132 24.0025 9.98139C24.0025 10.3496 23.704 10.6481 23.3358 10.6481H18.1181C17.7499 10.6481 17.4515 10.3496 17.4515 9.98139Z" fill="#ffffff"/>
              <path fillRule="evenodd" clipRule="evenodd" d="M19.465 12.5687C19.465 12.2005 19.7635 11.902 20.1317 11.902H23.3357C23.7039 11.902 24.0024 12.2005 24.0024 12.5687C24.0024 12.9369 23.7039 13.2353 23.3357 13.2353H20.1317C19.7635 13.2353 19.465 12.9369 19.465 12.5687Z" fill="#ffffff"/>
              <path fillRule="evenodd" clipRule="evenodd" d="M11.4523 9.19818C11.0197 9.19818 10.6691 9.54882 10.6691 9.98136C10.6691 10.4139 11.0197 10.7645 11.4523 10.7645C11.8848 10.7645 12.2354 10.4139 12.2354 9.98136C12.2354 9.54883 11.8848 9.19818 11.4523 9.19818ZM9.33574 9.98136C9.33574 8.81244 10.2833 7.86485 11.4523 7.86485C12.6212 7.86485 13.5688 8.81244 13.5688 9.98136C13.5688 11.1503 12.6212 12.0979 11.4523 12.0979C10.2833 12.0979 9.33574 11.1503 9.33574 9.98136Z" fill="#ffffff"/>
              <path fillRule="evenodd" clipRule="evenodd" d="M12.1243 12.6259C13.603 12.7602 14.7767 13.9242 14.9196 15.4043C14.955 15.7708 14.6866 16.0966 14.3201 16.1319C13.9536 16.1673 13.6278 15.8989 13.5924 15.5324C13.5112 14.6907 12.8443 14.0291 12.0017 13.9536L11.9949 13.953C11.6389 13.9174 11.2744 13.9172 10.9087 13.9531C10.0589 14.0351 9.39339 14.6944 9.31248 15.5324C9.27709 15.8989 8.95131 16.1673 8.58483 16.1319C8.21834 16.0966 7.94993 15.7708 7.98531 15.4043C8.12867 13.9195 9.30628 12.7685 10.7791 12.626C11.2292 12.582 11.6805 12.5817 12.1243 12.6259Z" fill="#ffffff"/>
              <path fillRule="evenodd" clipRule="evenodd" d="M22.0599 17.3383C19.4814 17.3383 17.3932 19.4265 17.3932 22.005C17.3932 22.88 17.6381 23.7057 18.0673 24.4017L18.0729 24.4108C18.8772 25.7629 20.3569 26.6717 22.0599 26.6717C23.7629 26.6717 25.2427 25.763 26.0469 24.4109L26.0524 24.4017C26.4816 23.7057 26.7266 22.88 26.7266 22.005C26.7266 19.4265 24.6384 17.3383 22.0599 17.3383ZM16.0599 22.005C16.0599 18.6902 18.745 16.005 22.0599 16.005C25.3748 16.005 28.0599 18.6902 28.0599 22.005C28.0599 23.1281 27.7459 24.194 27.1902 25.0971C26.1538 26.8358 24.2483 28.005 22.0599 28.005C19.8715 28.005 17.966 26.8358 16.9296 25.0971C16.3739 24.194 16.0599 23.1281 16.0599 22.005Z" fill="#ffffff"/>
              <path fillRule="evenodd" clipRule="evenodd" d="M19.4252 22.005C19.4252 21.6369 19.7237 21.3384 20.0919 21.3384H23.9602C24.3284 21.3384 24.6269 21.6369 24.6269 22.005C24.6269 22.3732 24.3284 22.6717 23.9602 22.6717H20.0919C19.7237 22.6717 19.4252 22.3732 19.4252 22.005Z" fill="#ffffff"/>
              <path d="M6 5.33333H26C26.3682 5.33333 26.6667 5.63181 26.6667 6V16H28V6C28 4.89543 27.1046 4 26 4H6C4.89543 4 4 4.89543 4 6V18C4 19.1046 4.89543 20 6 20H14.9229V18.6667H6C5.63181 18.6667 5.33333 18.3682 5.33333 18V6C5.33333 5.63181 5.63181 5.33333 6 5.33333Z" fill="#ffffff"/>
            </svg>
          </div>
          <span style={{fontSize: "8pt", lineHeight: "16px"}}>نمره منفی گواهینامه</span>
        </Link>
        <NavigationItem className={"b2"} title={"کارت و سند خودرو"} image={"/assets/1/kas.png"} link={`/inquiry/card-and-document`} width={38} height={40}/>
        <NavigationItem className={"b2"} title={"پلاک های فعال"} image={"/assets/1/kas.png"} link={`/inquiry/active-plates`} width={38} height={40}/>



      </div>
      <div className={"nav-item-container home-icons-container"} style={{justifyContent: "flex-start"}}>

        <NavigationItem className={"b3"} title={"خلافی خودرو"} image={"/assets/1/police.png"} link={`/inquiry/vehicle-violation-inquiry`}/>
        <NavigationItem className={"b6"} title={"استعلام گواهینامه"} image={"/assets/1/id-card.png"} link={`/inquiry/car-licence-inquiry`} width={36} height={36}/>
        {/*<a href={`${appConfig.inquiryEndpoint}/inquiry/وضعیت-کارت-و-سند-خودرو`} className={"nav-item home-item"}>*/}
        {/*  <div className={`nav-image b7`}>*/}
        {/*    <svg width="42" height="42" style={{margin:"auto"}} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">*/}
        {/*      <path fillRule="evenodd" clipRule="evenodd" d="M18.6668 4.00033H12.0002V6.66699H18.6668V4.00033ZM12.0002 2.66699C11.2638 2.66699 10.6668 3.26395 10.6668 4.00033V6.66699C10.6668 7.40337 11.2638 8.00033 12.0002 8.00033H18.6668C19.4032 8.00033 20.0002 7.40337 20.0002 6.66699V4.00033C20.0002 3.26395 19.4032 2.66699 18.6668 2.66699H12.0002Z" fill="#ffffff"/>*/}
        {/*      <path fillRule="evenodd" clipRule="evenodd" d="M14.4047 27.667V27.6236C14.4047 26.7031 15.1509 25.957 16.0713 25.957H22.9334C23.8539 25.957 24.6 26.7031 24.6 27.6236V27.667C24.6 27.8511 24.7493 28.0004 24.9334 28.0004H25.0002C25.1843 28.0004 25.3335 27.8511 25.3335 27.667V24.4791C25.3335 23.3745 24.4381 22.4791 23.3335 22.4791H15.6712C14.5667 22.4791 13.6712 23.3745 13.6712 24.4791V27.667C13.6712 27.8511 13.8205 28.0004 14.0046 28.0004H14.0713C14.2554 28.0004 14.4047 27.8511 14.4047 27.667ZM15.738 27.6236V27.667C15.738 28.5875 14.9918 29.3337 14.0713 29.3337H14.0046C13.0841 29.3337 12.3379 28.5875 12.3379 27.667V24.4791C12.3379 22.6381 13.8303 21.1457 15.6712 21.1457H23.3335C25.1744 21.1457 26.6668 22.6381 26.6668 24.4791V27.667C26.6668 28.5875 25.9206 29.3337 25.0002 29.3337H24.9334C24.0129 29.3337 23.2667 28.5875 23.2667 27.667V27.6236C23.2667 27.4395 23.1175 27.2903 22.9334 27.2903H16.0713C15.8872 27.2903 15.738 27.4395 15.738 27.6236Z" fill="#ffffff"/>*/}
        {/*      <path fillRule="evenodd" clipRule="evenodd" d="M21.9402 18.4857H17.0644C16.7441 18.4857 16.4691 18.7134 16.4094 19.028L15.9951 21.2105H23.0095L22.5952 19.028C22.5355 18.7134 22.2605 18.4857 21.9402 18.4857ZM23.9051 18.7793C23.726 17.8355 22.901 17.1524 21.9402 17.1524H17.0644C16.1036 17.1524 15.2786 17.8355 15.0995 18.7793L14.3848 22.5438H24.6198L23.9051 18.7793Z" fill="#ffffff"/>*/}
        {/*      <path fillRule="evenodd" clipRule="evenodd" d="M13.2187 19.9795C13.479 19.7191 13.9011 19.7191 14.1615 19.9795L14.8852 20.7032C15.1456 20.9635 15.1456 21.3856 14.8852 21.646C14.6249 21.9063 14.2027 21.9063 13.9424 21.646L13.2187 20.9223C12.9583 20.6619 12.9583 20.2398 13.2187 19.9795Z" fill="#ffffff"/>*/}
        {/*      <path fillRule="evenodd" clipRule="evenodd" d="M25.815 19.9795C25.5546 19.7191 25.1325 19.7191 24.8722 19.9795L24.1484 20.7032C23.8881 20.9635 23.8881 21.3856 24.1484 21.646C24.4088 21.9063 24.8309 21.9063 25.0912 21.646L25.815 20.9223C26.0753 20.6619 26.0753 20.2398 25.815 19.9795Z" fill="#ffffff"/>*/}
        {/*      <path fillRule="evenodd" clipRule="evenodd" d="M14.7419 24.2163C14.7419 23.8481 15.0404 23.5496 15.4086 23.5496H16.9438C17.312 23.5496 17.6105 23.8481 17.6105 24.2163C17.6105 24.5845 17.312 24.8829 16.9438 24.8829H15.4086C15.0404 24.8829 14.7419 24.5845 14.7419 24.2163Z" fill="#ffffff"/>*/}
        {/*      <path fillRule="evenodd" clipRule="evenodd" d="M21.3946 24.2163C21.3946 23.8481 21.693 23.5496 22.0612 23.5496H23.5965C23.9647 23.5496 24.2631 23.8481 24.2631 24.2163C24.2631 24.5845 23.9647 24.8829 23.5965 24.8829H22.0612C21.693 24.8829 21.3946 24.5845 21.3946 24.2163Z" fill="#ffffff"/>*/}
        {/*      <path fillRule="evenodd" clipRule="evenodd" d="M9.26698 11.3033C9.267 10.9351 9.56548 10.6366 9.93367 10.6366L20.7334 10.6369C21.1016 10.637 21.4 10.9354 21.4 11.3036C21.4 11.6718 21.1015 11.9703 20.7333 11.9703L9.93363 11.9699C9.56544 11.9699 9.26697 11.6714 9.26698 11.3033Z" fill="#ffffff"/>*/}
        {/*      <path fillRule="evenodd" clipRule="evenodd" d="M11.6712 13.9657C11.6712 13.5975 11.9697 13.299 12.3379 13.299H20.7333C21.1015 13.299 21.4 13.5975 21.4 13.9657C21.4 14.3338 21.1015 14.6323 20.7333 14.6323L12.3379 14.6323C11.9697 14.6323 11.6712 14.3338 11.6712 13.9657Z" fill="#ffffff"/>*/}
        {/*      <path fillRule="evenodd" clipRule="evenodd" d="M7.3335 5.33366H10.6668V6.66699H7.3335C6.96531 6.66699 6.66683 6.96547 6.66683 7.33366V22.645C6.66683 23.0132 6.96531 23.3117 7.3335 23.3117H11.2676V24.645H7.3335C6.22893 24.645 5.3335 23.7496 5.3335 22.645V7.33366C5.3335 6.22909 6.22893 5.33366 7.3335 5.33366ZM25.3335 16.7985V7.33366C25.3335 6.22909 24.4381 5.33366 23.3335 5.33366H20.0002V6.66699H23.3335C23.7017 6.66699 24.0002 6.96547 24.0002 7.33366V16.7985H25.3335Z" fill="#ffffff"/>*/}
        {/*    </svg>*/}
        {/*  </div>*/}
        {/*  <span style={{fontSize: "8pt", lineHeight: "16px"}}>کارت و سند خودرو</span>*/}

        {/*<NavigationItem className={"b3"} title={"عوارض آزادراهی"} image={"/assets/1/roll_car_main.png"} link={`/inquiry/freeway-tolls`}/>*/}

        {/*</a>*/}
        {/*<NavigationItem className={"b2"} title={"کارت و سند خودرو"} image={"/assets/1/kas.png"} link={`https://estelam.mashinno.com/inquiry/%D9%88%D8%B6%D8%B9%DB%8C%D8%AA-%DA%A9%D8%A7%D8%B1%D8%AA-%D9%88-%D8%B3%D9%86%D8%AF-%D8%AE%D9%88%D8%AF%D8%B1%D9%88`} width={38} height={40}/>*/}
      </div>


    </section>
  );
};

export default Icons;