import React, {useEffect, useState} from 'react';
import {convertToEnglishDigit, getOnlyNumbers, isNumeric, toTime} from "../../../commons/number";
import xToast from "../../../commons/xToast";
import LoadingButton from "../../Ui/Button/LoadingButton";

interface Props {
  code: string,
  setCode: Function,
  confirmCode: Function,
  loading: boolean,
  mobile: any,
  time: number,
  setTime: Function,
  sendCodeAgain:Function
}

const CodeMode = (props: Props) => {

  let codeChanged = (event: any) => {
    event.preventDefault()
    event.stopPropagation()
    let result = event.target.value
    let value: any = getOnlyNumbers(result);
    props.setCode(value)
  }

  let [sendAgain, setSendAgain] = useState(false);

  useEffect(() => {

    let interval = setInterval(() => {
      if (props.time <= 0) {

        clearInterval(interval)
        setSendAgain(true);
        return;
      }
      props.setTime(props.time - 1)
    }, 1000)

    return () => clearInterval(interval)

  }, [props.time]);

  let sendCodeAgain = () => {
    setSendAgain(false)
    props.sendCodeAgain()
  }


  let time = toTime(props.time);

  const loginClicked = () => {
    try {
      let code = props.code
      if (!code) throw new Error("کد تایید وارد نشده است")
      if (code && code.length != 5) throw new Error("کد تایید اشتباه است")
      props.confirmCode()
    } catch (e: any) {
      xToast.error(e.message)
    }
  }


  let code = convertToEnglishDigit(props.code)

  return (
    <>

      <div className={"login-logo"}>

      </div>

      <div style={{textAlign: "right"}}>
        <h1 style={{fontSize: "17px", fontFamily: "main-font", marginBottom: "0"}}>کد تایید را وارد کنید</h1>
      </div>

      <div style={{textAlign: "right", fontSize: "13px", color: "#333", marginTop: "24px"}}>
        لطفا کد تایید ارسال شده به شماره موبایل
        <span style={{padding: "0 2px", fontFamily: "main-font-bold"}}>
          {props.mobile}
        </span>
        را وارد نمایید
      </div>


      <div className={"login-input-container"}>
        <input type="tel" className={`login-input ${code && code.length == 5 && isNumeric(code) && "input-success"}`} value={code} onChange={codeChanged}/>
      </div>


      {!sendAgain && <div style={{fontSize: "14px", color: "#333", marginTop: "32px",textAlign:'center'}}>
        <span style={{display: "inline-block", width: "50px", textAlign: "left", paddingLeft: "4px"}}>
          {time}
        </span>
        مانده تا دریافت مجدد کد
      </div>}

      <div style={{marginTop: "32px",textAlign:"center"}}>
        {sendAgain && <span style={{fontSize: "13px", color: "#109dcc", cursor: "pointer", borderBottom: "1px dashed #109dcc"}} onClick={sendCodeAgain}>
        ارسال مجدد کد
      </span>}
      </div>


      <div style={{marginTop:"32px"}}>
        <LoadingButton text={"تایید و ورود"} clicked={loginClicked} loading={props.loading}/>
      </div>

    </>
  );
};

export default CodeMode;