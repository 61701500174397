import React, {useState} from 'react';
import Plate from "../Plate";
import Switch from "react-switch";
import ChangePlateDialog from "../../../Pages/Inquiries/FreewayInquiry/ChangePlateDialog";
import AddPlateDialog from "../../../Pages/Inquiries/FreewayInquiry/AddPlateDialog";
import {IPlate, IPlateDetail} from "../IPlate";
import {hasPlate} from "../../../../commons/plate";


interface Props {
  loadingMode: boolean,
  hasPlate: boolean,
  plates: IPlateDetail[],
  plate: IPlateDetail | undefined | null,
  onPlateChanged: Function,
  onPlateDone: Function
}

const PlateTools = (props: Props) => {


  let [showChangePlate, setShowChangePlate] = useState(false);

  let [plateDone, setPlateDone] = useState(false);
  let [isInMyPlate, setIsInMyPlate] = useState(false);
  let [addToMyPlates, setAddToMyPlates] = useState<boolean>(false)
  let [plate, setPlate] = useState<IPlate | undefined>(undefined);

  const onPlateDone = (plate: IPlate) => {
    setPlateDone(true)
    setPlate(plate)
    let result = hasPlate(props.plates, plate)
    setIsInMyPlate(result)

    props.onPlateDone(true,plate)
  }

  const changePlate = (plates: IPlateDetail[]) => {
    let plate = plates.filter(row => row.isDefault)[0]
    props.onPlateChanged(plates,plate)


    if (plates.length > 0) {
      let result = plates.filter((row: IPlateDetail) => row.isDefault)[0]

      if (result) {
        setPlate(result.plate)
      }
    }
    setShowChangePlate(false)
  }



  const addedPlate = () => {
    setAddToMyPlates(false)
  }

  const onPlateUndone = () => {
    setPlateDone(false)
    setIsInMyPlate(false)
    setPlate(undefined)
    props.onPlateDone(false)
  }


  return (
    <>
      <div className={"mb-4"}>
        <div style={{display: "flex", alignItems: "center", justifyContent: "center", height: "70px"}}>
          <Plate loadingMode={props.loadingMode} value={props.plate && props.plate.plate} onDone={onPlateDone} readonly={false} onUnDone={onPlateUndone}/>
          {props.plates && props.plates.length > 1 && <span onClick={() => setShowChangePlate(true)} style={{color: "#129db1", cursor: 'pointer', position: "relative", top: "4px"}}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width={24} height={24} fill={"#555"}>
                <path d="M471.6 21.7c-21.9-21.9-57.3-21.9-79.2 0L362.3 51.7l97.9 97.9 30.1-30.1c21.9-21.9 21.9-57.3 0-79.2L471.6 21.7zm-299.2 220c-6.1 6.1-10.8 13.6-13.5 21.9l-29.6 88.8c-2.9 8.6-.6 18.1 5.8 24.6s15.9 8.7 24.6 5.8l88.8-29.6c8.2-2.8 15.7-7.4 21.9-13.5L437.7 172.3 339.7 74.3 172.4 241.7zM96 64C43 64 0 107 0 160V416c0 53 43 96 96 96H352c53 0 96-43 96-96V320c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H96z"/>
              </svg>
            </span>}
        </div>


        {!isInMyPlate && plateDone && (
          <div style={{display: "flex", height: "40px", position: "relative", top: "12px", justifyContent: "center", alignItems: "center"}}>
            <div>
              <Switch checkedIcon={false} uncheckedIcon={false} height={24} width={50} onChange={(bool) => setAddToMyPlates(bool)} checked={addToMyPlates}/>
            </div>
            <div style={{paddingRight: "8px", fontSize: "14px", position: "relative", top: "-4px"}}>
              در لیست خودرو های من ذخیره شود
            </div>
          </div>
        )}
      </div>

      {props.plate && <ChangePlateDialog onDelete={changePlate} plates={props.plates} show={showChangePlate} setShow={setShowChangePlate} onSelected={changePlate}/>}
      <AddPlateDialog plate={plate} show={addToMyPlates} setShow={setAddToMyPlates} onPlateAdded={addedPlate}/>

    </>
  );
};

export default PlateTools;