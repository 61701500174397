import {appConfig} from "../../config";

export class CookieManager {
  static set(name: any, value: any, days: any) {
    let expires = "";
    if (days) {
      let date = new Date();
      date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
      expires = "; expires=" + date.toUTCString();
    }
    let local = appConfig.local
    if (local) {
      document.cookie = name + "=" + (value || "") + "; domain=localhost; " + expires + "; path=/";
    } else {
      document.cookie = name + "=" + (value || "") + "; domain=mashinno.com; " + expires + "; path=/";
    }
  }


  static get(name: any) {
    let nameEQ = name + "=";
    let ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }

    return null;
  }

}
