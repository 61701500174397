
interface Props {
  width: number | `${number}` | undefined
  height: number | `${number}` | undefined,
  src: string,
  alt: string,
  className?: string,
  optimizeImage?: boolean
}

const XImage = (props: Props) => {

  // const [loading, setLoading] = useState(true);

  // let loaded = () => {
  //   setLoading(false)
  // }
  // useEffect(() => {
  //
  //   if (loading){
  //     if (!props.optimizeImage){
  //       setTimeout(function (){
  //         setLoading(false)
  //       },1200)
  //     }
  //   }
  // }, []);


  let src=props.src.replace("/uploads","https://mashinno.com/uploads")
  // let src=props.src


  return (
    <>
      {/*{loading && props.optimizeImage && <div style={{position: "absolute", maxWidth: "100%", height: "100%", width: "100%"}}>*/}
      {/*  <span className={"preloader"}></span>*/}
      {/*</div>}*/}



        <picture>
          {/*<source srcSet={props.src} type={getImageType(props.src)}/>*/}
          <img
            className={props.className}
            src={src}
            alt={props.alt}
            width={props.width}
            height={props.height}
            // loading={"lazy"}
          />

        </picture>


    </>
  );
};

export default XImage;
