export function roundPrice(price: any) {

  price = parseFloat(price)
  price = Math.ceil(price / 1000)
  return parseInt((price * 1000) + "");
}

export function numberFormat(number:any) {
  if (number === "") return "";
  let a = new Intl.NumberFormat();
  return a.format(number)
}

export const isNumeric = (data: any) => {
  try {
    // data = convertToEnglishDigit(data)
    let result = parseInt(data)
    return Number.isInteger(result);
  } catch (e: any) {
    return false;
  }
}


export function getOnlyNumbers(data: any) {
  try {

    if (data.trim().length === 0) return "";


    data=convertToEnglishDigit(data)

    const numberPattern = /\d+/g;
    let numberResult = data.match(numberPattern)
    if (numberResult == null) {
      return;
    }

    let result = "";
    numberResult.map((row: any) => {
      result += row
    })
    return result;
  } catch (e) {
    return "0";
  }

}


export function toPersianNumber(n: any) {
  const farsiDigits = ['۰', '۱', '۲', '۳', '۴', '۵', '۶', '۷', '۸', '۹'];

  return n
    .toString()
    .replace(/\d/g, (x: any) => farsiDigits[x]);
}

export function convertToEnglishDigit(str: any) {
  let
    persianNumbers = [/۰/g, /۱/g, /۲/g, /۳/g, /۴/g, /۵/g, /۶/g, /۷/g, /۸/g, /۹/g],
    arabicNumbers = [/٠/g, /١/g, /٢/g, /٣/g, /٤/g, /٥/g, /٦/g, /٧/g, /٨/g, /٩/g];
  if (typeof str === 'string') {
    for (var i = 0; i < 10; i++) {
      str = str.replace(persianNumbers[i], i).replace(arabicNumbers[i], i);
    }
  }
  return str;
}



export function toTime(time:any) {
  let  sec_num = parseInt(time, 10);
  let  hours :any= Math.floor(sec_num / 3600);
  let minutes :any= Math.floor((sec_num - (hours * 3600)) / 60);
  var seconds:any = sec_num - (hours * 3600) - (minutes * 60);

  if (hours < 10) {
    hours = "0" + hours;
  }
  if (minutes < 10) {
    minutes = "0" + minutes;
  }
  if (seconds < 10) {
    seconds = "0" + seconds;
  }
  if (hours==="00"){
    return  minutes + ':' + seconds;
  }
  return  minutes + ':' + seconds;
}

