import React from 'react';
import {convertToEnglishDigit, getOnlyNumbers} from "../../../commons/number";
import {Link} from "react-router-dom";
import xToast from "../../../commons/xToast";
import LoadingButton from "../../Ui/Button/LoadingButton";


interface Props {
  mobile: any,
  setMobile: Function,
  onLoginClicked: Function,
  loading:boolean
}

const MobileMode = (props: Props) => {

  let mobileChanged = (event: any) => {
    event.preventDefault()
    event.stopPropagation()
    let result = event.target.value
    let value: any = getOnlyNumbers(result);
    props.setMobile(value)
  }


  const loginClicked = () => {
    try {
      let mobile = props.mobile
      if (!mobile) throw new Error("شماره موبایل وارد نشده است")
      if (mobile && mobile.length < 11) throw new Error("شماره موبایل باید 11 کاراکتر باشد")
      if (mobile && mobile.length == 11 && !isValidMobile()) throw new Error("شماره موبایل اشتباه است")
      if (mobile && mobile.length > 11) throw new Error("شماره موبایل اشتباه است")
      props.onLoginClicked()
    } catch (e: any) {
      xToast.error(e.message)
    }
  }



  let mobile = convertToEnglishDigit(props.mobile)


  const isValidMobile = (): boolean => {
    if (mobile) {
      try {
        let result = mobile.match(/^(?:98|\+98|0098|0)?9[0-9]{9}$/)
        if (typeof result === "object") {
          return result!.length > 0;
        }
      } catch (e: any) {
        return false
      }
    }
    return false;
  }


  return (
    <>

      <div className={"login-logo"}>
        <Link to={"/"}>
          <img src="https://mashinno.com/uploads/settings/settings-13-color.png" alt=""/>
        </Link>
      </div>

      <div style={{textAlign: "right"}}>
        <h1 style={{fontSize: "16px", fontFamily: "main-font", marginBottom: "0"}}>ورود
          |
          ثبت نام</h1>
      </div>

      <div style={{textAlign: "right", fontSize: "13px", color: "#333", marginTop: "24px"}}>
        سلام!
      </div>
      <div style={{textAlign: "right", fontSize: "13px", color: "#333"}}>
        لطفا شماره موبایل خود را وارد کنید
      </div>


      <div className={"login-input-container"}>

        <input type="tel" className={`login-input ${mobile && mobile.length == 11 && isValidMobile() && "input-success"}`} value={mobile} onChange={mobileChanged}/>
        {!mobile && <span className={"input-hint"}>لطفا این قسمت را خالی نگذارید</span>}
        {mobile && mobile.length < 11 && <span className={"input-hint"}>شماره موبایل باید 11 کاراکتر باشد</span>}
        {mobile && mobile.length == 11 && !isValidMobile() && <span className={"input-hint"}>شماره موبایل اشتباه است</span>}
        {mobile && mobile.length > 11 && <span className={"input-hint"}>شماره موبایل اشتباه است</span>}
        {mobile && mobile.length == 11 && isValidMobile() && <span className={"input-hint success"}>شماره موبایل صحیح است</span>}

      </div>


      <div style={{marginTop:"32px"}}>
        <LoadingButton text={"ادامه"} clicked={loginClicked} loading={props.loading}/>
      </div>
      <div className={"subtext"}>
        ورود شما به معنای پذیرش
        <Link to="/laws">شرایط ماشین نو</Link>
        و
        <Link to={"/privacy"}>
          قوانین حریم‌خصوصی
        </Link>
        است
      </div>
    </>
  );
};

export default MobileMode;