import React from 'react';
import InquiryHeader from "./InquiryHeader";

const InquiryLayout = ({children}:{children:React.ReactNode}) => {
  return (
    <>
      <InquiryHeader/>

      <main className={"inquiry-main"}>
        {children}
      </main>

      <footer>
        {/*footer*/}
      </footer>
    </>
  );
};

export default InquiryLayout;