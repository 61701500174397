import React from 'react';

const FlagSign = () => {
  return (
    <div className={"flag"}>
      <img src="/assets/app/flag.png" alt="" className={"flagImage"} width={20} height={16}/>
      <div className={"flagCountry"}>
        <div className={"tal"}>
          I.R
        </div>
        <div className={"tal"}>
          IRAN
        </div>
      </div>
    </div>
  );
};

export default FlagSign;