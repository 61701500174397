import React from 'react';
import NavigationItem from "./NavigationItem";
import {Link} from "react-router-dom";
import {Auth} from "../../../Infrastructure/Auth/Auth";

const MainFooter = () => {

  let isLoggedIn = false;
  try {
    let auth = Auth.get()
    if (auth.authorisation.token) {
      isLoggedIn=true
    }
  } catch (e: any) {
    isLoggedIn=false
  }



  let link =isLoggedIn?  "https://mashinno.com/user/profile":"/login";


  return (
    <footer id={"main-footer"}>
      <div className={"footer-white"}></div>
      <div className={"footer-shadow"}></div>

      <div className={"nav-item-container bn-container"}>
        <NavigationItem className={"b1"} title={"لوازم یدکی"} image={"/assets/app/shop.png"} link={"/shop"} width={32} height={32}/>
        <NavigationItem className={"b9"} title={"مکانیک"} image={"/assets/1/mechanic_main.png"} link={"/ai"} width={32} height={32}/>
        <NavigationItem className={"b4"} title={"درباره ما"} image={"/assets/app/about.png"} link={"/about-us"} width={32} height={32}/>

        <Link to={link} className={"nav-item home-item"}>
          <div className={`nav-image ${"b3"}`}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" fill={"#fff"} width={24} height={24} style={{margin: "auto"}}>
              <path d="M304 128a80 80 0 1 0 -160 0 80 80 0 1 0 160 0zM96 128a128 128 0 1 1 256 0A128 128 0 1 1 96 128zM49.3 464l349.5 0c-8.9-63.3-63.3-112-129-112l-91.4 0c-65.7 0-120.1 48.7-129 112zM0 482.3C0 383.8 79.8 304 178.3 304l91.4 0C368.2 304 448 383.8 448 482.3c0 16.4-13.3 29.7-29.7 29.7L29.7 512C13.3 512 0 498.7 0 482.3z"/>
            </svg>
          </div>
          پروفایل
        </Link>
      </div>
    </footer>
  );
};

export default MainFooter;