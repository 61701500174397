import React from 'react';
import "./button.css"

interface Props {
  clicked: Function,
  title: string,
  block?: boolean,
  type: "success" | "warning" | "error" | "green"
}

const Button = (props: Props) => {


  return (

    <div className={"mt-3"}>
      <span className={`btn btn-${props.type} ${props.block ? "block" : ""}`} onClick={() => props.clicked()}>{props.title}</span>
    </div>

  );
};

export default Button;