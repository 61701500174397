const appConfig = {
  appUrl: "https://app.mashinno.com",
  api: "https://mashinno.com/x-api",
  endPoint: "/pwa/v1",
  port: 6004,
  version: 1,
  local:false
}


export {
  appConfig
}
