import React, {ReactNode, useEffect, useState} from 'react';
import "./Installable.css"
import useAppContext from "../../state/useAppContext";
import Dialog from "./Dialog/Dialog";

const InstallableLayout = ({children}: { children: ReactNode }) => {

  let {state, dispatch} = useAppContext()

  // eslint-disable-next-line @typescript-eslint/no-unused-expressions


  let [show, setShow] = useState(false)
  let [defferedPrompt, setDefferedPrompt] = useState<any>()

  let [isIos, setIsIos] = useState(false)


  useEffect(() => {
    let result=window.localStorage.getItem("ios_show")
    if (result){
      return;
    }


    setIsIos(iOS())
  }, []);


  function iOS() {
    return [
        'iPad Simulator',
        'iPhone Simulator',
        'iPod Simulator',
        'iPad',
        'iPhone',
        'iPod'
      ].includes(navigator.platform)
      // iPad on iOS 13 detection
      || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
  }


  if (state.pwa.showInstallPopup) {
    window.addEventListener('beforeinstallprompt', (event: any) => {
      event.preventDefault();
      setDefferedPrompt(event)
      setShow(state.pwa.showInstallPopup)
    });
  }


  let neverShowAgain = () => {
    dispatch({
      payload: {
        showInstallPopup: false
      },
      type: "SHOW_PWA_INSTALL_POPUP"
    })
  }

  const install = () => {
    setShow(false)

    if (defferedPrompt) {
      defferedPrompt.prompt();
      defferedPrompt.userChoice.then((choice: any) => {
        if (choice.outcome === 'accepted') {
        }
        defferedPrompt = null;
      })
    }
  }

  let fontSize = 12

  let clicked=()=>{
    setIsIos(false)
    window.localStorage.setItem("ios_show","shown")
  }


  return (
    <div>

      {isIos && <div id={"ios-guide"}>
        <div>
          <div style={{textAlign:"center",marginTop:"32px"}}>
            <img src="/assets/app/logo.png" alt="" style={{width:"120px"}}/>
          </div>

          <div style={{fontSize:"13px",textAlign:"center"}}>
            وب اپلیکیشن ماشین نو را به صفحه اصلی گوشی تان اضافه کنید
          </div>

          <div className={"installable-bordered"}>
            <div style={{fontSize: "13px"}}>
              1.در نوار پایین دکمه
              &nbsp;
              <img src="/assets/app/home-app.png" alt="" width={22} style={{verticalAlign: "middle"}}/>
              &nbsp;
              "share"
              را انتخاب کنید
            </div>

            <div style={{marginTop: "16px", fontSize: "13px"}}>
              2. در صفحه باز شده دکمه

              <span style={{display: "inline-block",padding:" 2px 8px 0",border:"1px solid #333",borderRadius:"4px",margin:"0 8px"}}>
                +
              </span>

              "Add To Home Screen"
              را انتخاب کنید
            </div>


            <div style={{marginTop: "16px", fontSize: "13px"}}>
              3. دکمه "Add" را انتخاب کنید

              را انتخاب کنید
            </div>

            <div style={{textAlign:"center",marginTop:"16px"}} onClick={clicked}>
              <span className={"btn-cancel"} style={{background:"#0e9ab2"}}>
                متوجه شدم
              </span>
            </div>

          </div>


        </div>

      </div>}

      <Dialog show={show && state.pwa.showInstallPopup} close={() => setShow(false)} title={"نصب برنامه ماشین نو"}
              height={130}>
        <div>
          <div style={{fontSize: `${fontSize}px`, lineHeight: "42px"}}>
            ماشین نو به لیست برنامه های شما اضافه شود؟
          </div>

          <div style={{textAlign:"center"}}>
            <span className={"btn-success small"} onClick={install} style={{fontSize: `${15}px`,paddingTop:"4px",background:"#0e9ab2",lineHeight:"32px"}}>بله</span>
            &nbsp;
            <span className={"btn-warning small"} style={{fontSize: `${fontSize}px`,lineHeight:"32px",paddingTop:"4px",background:"#ff0369",color:"#fff"}} onClick={() => setShow(false)}>نادیده گرفتن</span>
            {/*&nbsp;*/}
            {/*<span className={"btn-cancel small"} style={{fontSize: `${fontSize}px`,lineHeight:"36px"}} onClick={() => neverShowAgain()}>دیگر این پیغام را نشان نده</span>*/}
          </div>
        </div>

      </Dialog>
      {children}
    </div>
  );
};

export default InstallableLayout;