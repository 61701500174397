import React, {useEffect, useState} from 'react';
import InquiryLayout from "../../../Layouts/Inquiry/InquiryLayout";
import Input from "../../../Ui/Input/Input";
import TitleBox from "./TitleBox";
import LoadingButton from "../../../Ui/Button/LoadingButton";
import Dialog from "../../../Layouts/Dialog/Dialog";

import {Auth} from "../../../../Infrastructure/Auth/Auth";
import {webRequest} from "../../../../Services/apiService";
import xToast from "../../../../commons/xToast";
import {validateAuth, validateResponse} from "../../../../Infrastructure/Api/ValidateResponse";
import {useNavigate} from "react-router-dom";
import Toman from "../../../Layouts/Ui/Toman";
import {numberFormat} from "../../../../commons/number";
import {Payment} from "../../../../commons/Payment";

const CarLicenceInquiryPage = () => {
  let [mobile, setMobile] = useState("");
  let [nationCode, setNationCode] = useState("");
  let [loading, setLoading] = useState(false);
  let navigate = useNavigate()
  let [reviewDialog, setReviewDialog] = useState(false)
  let [inquiry, setInquiry] = useState<any>({})

  const [paymentLoadingWallet, setPaymentLoadingWallet] = useState(false)
  const [paymentLoadingGateway, setPaymentLoadingGateway] = useState(false)

  let [wallet, setWallet] = useState<any>(0)
  let [auth, setAuth] = useState<any>({})


  useEffect(() => {
    document.title = "استعلام گواهی نامه رانندگی"


    webRequest({
      path: "/pages/carLicence",
      data: {}
    }).then(response => {
      setInquiry(response.payload.inquiry)
    })

    if (Auth.check()) {
      let auth = Auth.get()
      setMobile(auth.user.username)
    }
  }, []);

  const check = (mode: string) => {
    setLoading(true);

    webRequest({
      path: "/car-licence/check",
      data: {
        mobile, nationCode,
        mode
      },
      auth: true,
    }).then((response: any) => {
      setLoading(false)

      try {
        validateResponse(response)
        validateAuth(response)
        if (response.payload.type == "dialog") {
          openReviewDialog()
          setWallet(response.payload.wallet)
          return;
        }


      } catch (e: any) {
        setLoading(false)
        if (e.message == "login") {
          navigate("/login?back=/inquiry/car-licence-inquiry")
          return;
        }
        xToast.error(e.message)
        setTimeout(function () {
          xToast.dismiss()
        }, 3000)
      }

    })
  }

  const pay = (type: any) => {
    if (type == "gateway") {
      setPaymentLoadingGateway(true)
    } else {
      setPaymentLoadingWallet(true)
    }

    webRequest({
      path: "/car-licence/pay",
      auth: true,
      data: {
        type,
        mobile,
        nationCode,
      }
    }).then(response => {
      setPaymentLoadingWallet(false)
      setPaymentLoadingGateway(false)
      try {
        validateAuth(response)
        validateResponse(response)

        if (response.payload.type == "gateway") {
          Payment.start(response.payload.data)
        } else {
          alert("from wallet")
        }
        setReviewDialog(false)
      } catch (e: any) {
        setLoading(false)
        if (e.message == "login") {
          navigate("/login?back=/inquiry/car-licence-inquiry")
          return;
        }
        xToast.error(e.message)
        setTimeout(function () {
          xToast.dismiss()
        }, 3000)
      }

    })
  }


  const openReviewDialog = (auth: any = null) => {
    if (!auth) {
      auth = Auth.get()
    }
    setAuth(auth)
    setReviewDialog(true)
    setWallet(auth.user.wallet)
  }


  return (
    <InquiryLayout>

      <Dialog show={reviewDialog} close={() => setReviewDialog(false)} title={"بررسی قبل از استعلام"} height={300}>
        {reviewDialog && <div style={{paddingTop: "16px", paddingLeft: "16px", paddingRight: "16px"}}>
          <div>
            <ul style={{listStyle: "none", fontFamily: "main-font-bold", padding: "0"}}>
              <li>نوع درخواست : استعلام گواهینامه رانندگی</li>
              <li style={{display: "flex", marginTop: "8px"}}>
                <div>مبلغ قابل پرداخت :</div>
                <Toman>{numberFormat(inquiry.inquiryPrice)}</Toman>
              </li>
              <li>
                <li style={{display: "flex", marginTop: "8px"}}>
                  <div>موجودی کیف پول :</div>
                  &nbsp;
                  <Toman>{numberFormat(wallet)}</Toman>
                </li>
              </li>
            </ul>
          </div>
          <div style={{fontFamily: "main-font-bold", fontSize: "15px", marginTop: "32px", width: "calc(100% - 32px)", left: "4px", position: "relative"}}>
            پرداخت با :
          </div>
          <div style={{display: "flex", position: "relative", top: "8px", width: "calc(100% - 0px)", margin: "auto", left: "4px"}}>
            &nbsp;
            <LoadingButton clickable={wallet > 0} text={"کیف پول"} type={wallet > 0 ? "primary" : "disabled"} clicked={() => pay("wallet")} loading={paymentLoadingWallet}/>
            &nbsp;
            &nbsp;
            &nbsp;
            <LoadingButton text={"درگاه پرداخت"} clicked={() => pay("gateway")} loading={paymentLoadingGateway}/>
          </div>
        </div>}
      </Dialog>

      <div style={{padding: "0 32px"}}>
        <TitleBox/>
        <div style={{height: "32px"}}>

        </div>
        <Input value={mobile + ""} setValue={setMobile} type={"number"} label={"شماره موبایل"}/>
        <div style={{marginTop: "8px"}}>
          <Input value={nationCode + ""} setValue={setNationCode} type={"number"} label={"کد ملی"}/>
        </div>


        <div style={{marginTop: "20px"}}>
          <LoadingButton text={"استعلام گواهینامه رانندگی"} clicked={() => check("dialog")} type={"success"} loading={loading}/>
        </div>
      </div>

    </InquiryLayout>
  );

};

export default CarLicenceInquiryPage;