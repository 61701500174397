import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {Auth} from "../../../../Infrastructure/Auth/Auth";
import {webRequest} from "../../../../Services/apiService";
import {validateAuth, validateResponse} from "../../../../Infrastructure/Api/ValidateResponse";
import xToast from "../../../../commons/xToast";
import "./styles.css"
import InquiryLayout from "../../../Layouts/Inquiry/InquiryLayout";
import Skeleton from "react-loading-skeleton";
import Plate from "../../../Ui/Plate/Plate";
import Button from "../../../Ui/Button/Button";
import Toman from "../../../Layouts/Ui/Toman";
import {numberFormat} from "../../../../commons/number";
import LoadingButton from "../../../Ui/Button/LoadingButton";
import {Payment} from "../../../../commons/Payment";

const ShowCardAndDocumentResultPage = () => {
  let params = useParams()
  let navigate = useNavigate();

  let [loading, setLoading] = useState(true);
  let [valid, setValid] = useState(true);
  let [payLoading, setPayLoading] = useState(false);

  let [response, setResponse] = useState<any>(undefined);

  useEffect(() => {
    let id = params.id;
    let auth = Auth.get()
    if (!auth) navigate("/login?back=/inquiry/card-and-document/" + id)

    webRequest({
      path: "/card-and-document/info",
      data: {id: id},
      auth: true,
    }).then(response => {
      setLoading(false)
      try {
        validateAuth(response)
        validateResponse(response)
        if (response.payload.type == "404") setValid(false)
        if (response.payload.type == "success") setResponse(response.payload)
      } catch (e: any) {
        if (e.message == "login") {
          navigate("/login?back=/inquiry/card-and-document/" + id)
          return;
        }
        xToast.error(e.message)
      }
    })


  }, [])

  const retry = () => {
    navigate("/inquiry/card-and-document")
  }


  const pay = () => {
    setPayLoading(true)
    webRequest({
      path: "/vehicle-violation/payment",
      data: {
        id: response.data.id
      },
      auth: true,
    }).then(response => {
      try {
        setPayLoading(false)

        validateAuth(response)
        validateResponse(response)


        if (response.payload.type == "success") {
          Payment.start(response.payload.data)
        }


      } catch (e: any) {
        if (e.message == "login") {
          navigate("/login?back=/inquiry/card-and-document" + params.id)
          return;
        }
        xToast.error(e.message)
      }

    })
  }




  return (
    <InquiryLayout>
      {loading &&
        <div style={{padding: "0 24px"}}>
          <Skeleton width={"100%"} height={"200px"} style={{marginBottom: "8px"}}/>
          <Skeleton width={"100%"} height={"200px"} style={{marginBottom: "8px"}}/>
        </div>
      }


      {!loading && valid && response.data.type == "hasNoInquiry" && <div style={{padding: "0 24px"}}>
        <h2 style={{textAlign: "center", fontSize: "16px"}}>خودروی شما بدون خلافی است</h2>
        <div>
          <Plate loadingMode={false} value={response.data.plate} onDone={() => {
          }} onUnDone={() => {
          }} readonly={true}/>
        </div>

        <div style={{textAlign: "center", fontWeight: "bold", marginTop: "16px"}}>
          <div>
            {response.data.data.mobile}
          </div>
          <div>
            {response.data.data.nationCode}
          </div>
        </div>
      </div>}

      {!loading && valid && response.data.type == "paymentFailed" && <div style={{padding: "0 24px"}}>
        <h2 style={{textAlign: "center", fontSize: "16px", color: "#ff4444", fontWeight: "bold"}}>پرداخت توسط کاربر لغو شد</h2>
        <div>
          <Plate loadingMode={false} value={response.data.plate} onDone={() => {
          }} onUnDone={() => {
          }} readonly={true}/>
        </div>

        <div style={{textAlign: "center", fontWeight: "bold", marginTop: "16px"}}>
          <div>
            {response.data.data.mobile}
          </div>
          <div>
            {response.data.data.nationCode}
          </div>
        </div>
      </div>}


      {!loading && valid && response.data.type == "hasError" && <div style={{padding: "0 24px"}}>
        <h2 style={{textAlign: "center", fontSize: "16px"}}>خطا در استعلام</h2>


        <div style={{color: "#e32020", fontWeight: "bold"}}>
          {response.data.description}
        </div>

        <div style={{marginTop: "16px"}}>
          <Plate loadingMode={false} value={response.data.plate} onDone={() => {
          }} onUnDone={() => {
          }} readonly={true}/>
        </div>

        <div style={{textAlign: "center", fontWeight: "bold", marginTop: "16px"}}>
          <div>
            {response.data.data.mobile}
          </div>
          <div>
            {response.data.data.nationCode}
          </div>
        </div>
      </div>}

      {!loading && valid && response.data.type == "hasInquiry" && <div style={{padding: "0 24px"}}>
        <h2 style={{textAlign: "center", fontSize: "16px"}}>
          استعلام با موفقیت انجام شد
        </h2>
        <Plate loadingMode={false} value={response.data.plate} onDone={() => {
        }} onUnDone={() => {
        }} readonly={true}/>

        <div style={{textAlign: "center", fontWeight: "bold", marginTop: "16px"}}>
          <div>
            {response.data.data.mobile}
          </div>
          <div>
            {response.data.data.nationCode}
          </div>
        </div>





        <div style={{marginTop: "32px"}}>
          <div >
            <div style={{marginBottom: "16px", boxShadow: "0 0 7px 0 #ccc", borderRadius: "8px", overflow: "hidden"}}>
              {Object.keys(response.data.result).map((key: any, index2: number) => {
                return <div className={`row${index2 % 2 == 0 ? "1" : "2"}`} key={index2}>
                  <div>{key}</div>
                  &nbsp;
                  :
                  &nbsp;
                  <div style={{fontWeight: "bold"}}>{response.data.result[key]}</div>
                </div>
              })}

            </div>
          </div>

        </div>

      </div>}


      {!loading && valid && response.data.type == "hasError" && <div style={{padding: "0 24px", marginTop: "32px"}}>
        <Button clicked={() => retry()} title={"اصلاح اطلاعات و تلاش مجدد"} type={"success"} block={true}/>
      </div>}

      {!loading && valid && response.data.type == "paymentFailed" && <div style={{padding: "0 24px", marginTop: "32px"}}>
        <Button clicked={() => retry()} title={"تلاش مجدد"} type={"success"} block={true}/>
      </div>}

    </InquiryLayout>
  );
};

export default ShowCardAndDocumentResultPage;