import React from 'react';
import useConfirmDialog, {dismissConfirmDialog} from "../../../hooks/confirmDialog";
import Dialog from "./Dialog/Dialog";

import "./ConfirmDialog.css"
import LoadingButton from "../Button/LoadingButton";
import ButtonLoader from "../Loaders/ButtonLoader";
import {toast} from "react-toastify";

const ConfirmDialog = () => {
  let dialog = useConfirmDialog()
  let zIndex = dialog.zIndex

  let positiveButtonText = dialog.data?.confirmButtonText ?? "بله"
  let negativeButtonText = dialog.data?.negativeButtonText ?? "خیر"
  let cancelButtonText = dialog.data?.cancelButtonText ?? "لغو کردن"


  let width = dialog.data?.width ?? 300
  let height = dialog.data?.height ?? 200

  let tempFunction = () => {
    dismissConfirmDialog()
  }

  const confirmClicked = () => {
    if (!dialog.loading){
      dialog.data?.onConfirmButtonClicked ? dialog.data?.onConfirmButtonClicked() : tempFunction()
    }
  }

  return (
    <>
      {dialog.isOpen && <Dialog title={dialog.data?.title + ""} setShow={dialog.dismiss} show={dialog.isOpen} width={width} height={height} zIndex={zIndex}>


        <div style={{position: "relative", height: "calc(100% )"}}>
          <div style={{padding: "8px 0", color: "#111 !important", fontSize: "15px"}}>
            {dialog.data?.description}
          </div>

          <div style={{display: "flex", width: "100%", marginTop: "16px", position: "absolute", bottom: "0", borderTop: "1px solid #eee", padding: "8px 0",}}>

            <div style={{width: "100%"}}>
              <span onClick={() => dialog.data?.onNegativeButtonClicked ? dialog.data?.onNegativeButtonClicked() : tempFunction()} className={"negative-button w-100"} style={{height: "48px", fontSize: "18px", border: "1px solid #2C4477", color: "#2C4477"}}>
                {negativeButtonText}
              </span>
            </div>
            &nbsp;
            &nbsp;
            <div style={{width: "100%"}}>
              <span onClick={() => confirmClicked()} className={"positive-button"} style={{height: "48px", fontSize: "18px"}}>
                {dialog.loading && <span className={`loading-button`} style={{marginTop: "0"}}>
                <ButtonLoader/>
                </span>}

                {!dialog.loading && <>{positiveButtonText}</>}
              </span>
            </div>


            {/*&nbsp;*/}
            {/*&nbsp;*/}
            {/*<div style={{width: "120px"}}>*/}
            {/*  {dialog.data?.cancelButtonText && <XButton type={"color"} text={cancelButtonText} onClick={() => dialog.data?.onCancelButtonClicked ? dialog.data?.onCancelButtonClicked() : tempFunction()}/>}*/}
            {/*</div>*/}


          </div>
        </div>

      </Dialog>}
    </>
  );
};

export default ConfirmDialog;