import {CookieManager} from "../StorageManager/CookieManager";

export class Auth {

  static check(): boolean {
    try {
      let auth = CookieManager.get("auth")
      if (auth == null || auth==undefined) {
        return false;
      }

      return typeof JSON.parse(auth!) == "object"
    } catch (e: any) {
      return false;
    }
  }

  static get() {
    try {
      return JSON.parse(CookieManager.get("auth") + "")
    } catch (e: any) {
      return null;
    }
  }

  static remove(name: any) {
    try {
      return CookieManager.set(name, null, 0)
    } catch (e: any) {
      return null;
    }
  }

  static user() {
    try {
      return JSON.parse(CookieManager.get("auth") + "").user
    } catch (e: any) {
      return null;
    }
  }

}