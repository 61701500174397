import React, {useState} from 'react';
import "./inquiryHeader.css"
import {Link, useNavigate} from "react-router-dom";
import {Auth} from "../../../Infrastructure/Auth/Auth";
import {getAvatar} from "../../../commons/authHelper";
import Menu from "../DrawerMenu/Menu";
import MenuIcon from "../DrawerMenu/MenuIcon";


const NavItem = ({title, clicked}: { title: string, clicked: any }) => {

  let tag = null
  if (typeof clicked === "string") {
    tag = <Link to={clicked} style={{textDecoration: "none", cursor: "pointer", color: "#000"}}>
      <div className={"d-flex"} style={{alignItems: "center"}}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" width={16} height={16} fill={"#666"}>
          <path d="M41.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.3 256 246.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z"/>
        </svg>
        <div style={{paddingRight: "8px", position: "relative", top: "2px", fontSize: "15px"}}>
          {title}
        </div>
      </div>
    </Link>
  } else {
    tag = <span onClick={clicked} style={{textDecoration: "none", cursor: "pointer", color: "#000"}}>
      <div className={"d-flex"} style={{alignItems: "center"}}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" width={16} height={16} fill={"#666"}>
          <path d="M41.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.3 256 246.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z"/>
        </svg>
        <div style={{paddingRight: "8px", position: "relative", top: "2px", fontSize: "15px"}}>
          {title}
        </div>
      </div>
    </span>
  }

  return (
    <li style={{paddingRight: "8px", cursor: "pointer", padding: "8px 0", borderBottom: "1px solid #eee", width: "calc(100% - 16px)", margin: "auto"}}>
      {tag}
    </li>
  );
};


const InquiryHeader = () => {
  let auth = Auth.get()
  let navigate = useNavigate();
  let [active, setActive] = useState<any | undefined>(undefined);


  let openAuth = () => {
    if (auth) {
      alert(1)
    } else {
      navigate("/login")
    }
  }


  let profile = <svg onClick={openAuth} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" width={24} height={24}>
    <path d="M224 256c70.7 0 128-57.31 128-128s-57.3-128-128-128C153.3 0 96 57.31 96 128S153.3 256 224 256zM224 16c61.76 0 112 50.24 112 112c0 61.76-50.24 112-112 112S112 189.8 112 128C112 66.24 162.2 16 224 16zM274.7 304H173.3C77.61 304 0 381.6 0 477.3c0 19.14 15.52 34.67 34.66 34.67h378.7C432.5 512 448 496.5 448 477.3C448 381.6 370.4 304 274.7 304zM413.3 496H34.67C24.37 496 16 487.6 16 477.3C16 390.6 86.58 320 173.3 320h101.3C361.4 320 432 390.6 432 477.3C432 487.6 423.6 496 413.3 496z"/>
  </svg>


  if (auth) {
    profile = <Link to={"/profile"}>
      <img className={"image-avatar"} src={getAvatar()} alt=""/>
    </Link>
  }

  let logout = () => {

  }


  return (
    <>
      <header className={"inquiry-header"}>
        <MenuIcon setActive={setActive}/>
        <Link to={"/"} className={"logo"}>
          <img src="https://mashinno.com/uploads/settings/settings-13-color.png" alt=""/>
        </Link>
        <div className={"auth"} style={{position:"relative",top:"4px"}}>
          {profile}
        </div>
      </header>

      <Menu active={active} setActive={setActive}>
        <div>
          <ul style={{padding: 0, listStyle: "none"}}>
            <NavItem title={"صفحه اصلی"} clicked={"/"}/>
            <NavItem title={"اسعلام عوارض آزادراهی"} clicked={"/inquiry/freeway-tolls"}/>
            <NavItem title={"لیست استعلام ها"} clicked={"/inquiries"}/>
            <NavItem title={"کیف پول"} clicked={"/wallet"}/>
          </ul>
        </div>
      </Menu>
    </>
  );
};

export default InquiryHeader;

