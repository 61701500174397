import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import StateProvider from "./state/StateProvider";
import InstallableLayout from "./Components/Layouts/InstallableLayout";
import {ToastContainer} from "react-toastify";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <StateProvider>
    <InstallableLayout>
      <ToastContainer position="top-center" autoClose={3000} hideProgressBar={true} newestOnTop={false} closeOnClick rtl={true} pauseOnFocusLoss draggable pauseOnHover theme="dark"/>
      <App/>
    </InstallableLayout>
  </StateProvider>
);

