import React from 'react';
import Dialog from "../../Layouts/Dialog/Dialog";

const CharDialog = ({showDialog,setShowDialog,dialogClicked}:{showDialog:boolean,setShowDialog:Function,dialogClicked:any}) => {
  return (
    <Dialog title={"انتخاب حرف پلاک"} show={showDialog} close={() => setShowDialog(false)} height={330}>
      <div className={"dialogBody"} >
        <span className={"charRow"} onClick={dialogClicked}>الف</span>
        <span className={"charRow"} onClick={dialogClicked}>ب</span>
        <span className={"charRow"} onClick={dialogClicked}>پ</span>
        <span className={"charRow"} onClick={dialogClicked}>ت</span>
        <span className={"charRow"} onClick={dialogClicked}>ث</span>
        <span className={"charRow"} onClick={dialogClicked}>ج</span>
        <span className={"charRow"} onClick={dialogClicked}>چ</span>
        <span className={"charRow"} onClick={dialogClicked}>ح</span>
        <span className={"charRow"} onClick={dialogClicked}>خ</span>
        <span className={"charRow"} onClick={dialogClicked}>د</span>
        <span className={"charRow"} onClick={dialogClicked}>ذ</span>
        <span className={"charRow"} onClick={dialogClicked}>ر</span>
        <span className={"charRow"} onClick={dialogClicked}>ز</span>
        <span className={"charRow"} onClick={dialogClicked}>ژ</span>
        <span className={"charRow"} onClick={dialogClicked}>س</span>
        <span className={"charRow"} onClick={dialogClicked}>ش</span>
        <span className={"charRow"} onClick={dialogClicked}>ص</span>
        <span className={"charRow"} onClick={dialogClicked}>ض</span>
        <span className={"charRow"} onClick={dialogClicked}>ط</span>
        <span className={"charRow"} onClick={dialogClicked}>ظ</span>
        <span className={"charRow"} onClick={dialogClicked}>ع</span>
        <span className={"charRow"} onClick={dialogClicked}>غ</span>
        <span className={"charRow"} onClick={dialogClicked}>ف</span>
        <span className={"charRow"} onClick={dialogClicked}>ق</span>
        <span className={"charRow"} onClick={dialogClicked}>ک</span>
        <span className={"charRow"} onClick={dialogClicked}>گ</span>
        <span className={"charRow"} onClick={dialogClicked}>ل</span>
        <span className={"charRow"} onClick={dialogClicked}>م</span>
        <span className={"charRow"} onClick={dialogClicked}>ن</span>
        <span className={"charRow"} onClick={dialogClicked}>و</span>
        <span className={"charRow"} onClick={dialogClicked}>ه</span>
        <span className={"charRow"} onClick={dialogClicked}>ی</span>
      </div>
    </Dialog>

  );
};

export default CharDialog;