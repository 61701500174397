import React, {useEffect} from 'react';
import MainLayout from "../../Layouts/MainLayout";
import {Link} from "react-router-dom";
import Skeleton from "react-loading-skeleton";

const AiPage = () => {

  let session = window.localStorage.getItem("limaster-session")
  let [loaded, setLoaded] = React.useState(false);


  if (!session) {
    let r = Math.random().toString(36).slice(2, 20);
    window.localStorage.setItem("limaster-session", r)
    session = r
  }


  let [width, setWidth] = React.useState("0");
  let [height, setHeight] = React.useState("0");


  let initSize = () => {
    setWidth(window.innerWidth + 'px')
    setHeight((window.innerHeight - 110) + 'px')
    let iframes = document.querySelectorAll('iframe');
    for (let i = 0; i < iframes.length; i++) {
      iframes[i].style.height = window.innerHeight + 'px';
    }

    if (window.innerHeight < 100) {
      setTimeout(function () {
        initSize()
      }, 200)
    }
  }

  useEffect(() => {
    initSize()
  }, []);


  let url = "https://ai-chat.limaster.ir?token=2eb176c4-5bb2-4827-8289-a6183670e9d4&session=" + session

  const onLoad = () => {
    setLoaded(true);
  }

  return (
    <MainLayout header={false}>

      {!loaded && <div style={{position: "fixed", height: height, width: width, overflow: "hidden", maxWidth: "500px", background: "#fff"}}>
        <Skeleton width={width} height={height}/>
      </div>}


      <div style={{width: width, height: height, overflow: "hidden", position: "relative", maxWidth: "500px"}}>
        <Link to="/">
          <img width={40} height={40} src={"/assets/app/home.gif"} style={{position: "absolute", top: "4px", left: "8px"}} alt={"home"}/>
        </Link>
        <iframe src={url} onLoad={onLoad} width={width} style={{maxWidth: 500,width:width, height: height,display:loaded?'block':'none'}} frameBorder={0}></iframe>
      </div>
    </MainLayout>
  );
};

export default AiPage;