import React from 'react';
import {Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/css';
import {Link} from "react-router-dom";


export interface Slide {
  id: string,
  link: string,
  image: string,
  title: string,
}

const BlogSlider = ({slides}: { slides: Slide[] }) => {
  return (
    <div className={"mt-5"}>

      <Swiper
        spaceBetween={12}
        style={{paddingRight: "20px", paddingLeft: "20px"}}
        slidesPerView={1.003}
        // loop={true}
        centeredSlides={true}
        grabCursor={true}
        onSlideChange={() => {
        }}
        onSwiper={(swiper: any) => {
        }}
      >

        {slides.map((row, index) => {
          return <SwiperSlide key={index}>
            <Link to={"/blog/" + row.id}>
              <div style={{position: "relative"}}>
                <img src={row.image} width={"100%"} alt="" style={{borderRadius: "8px", height: "auto", objectFit: "cover"}}/>
                <p style={{background:"rgba(24,24,24,0.6)",paddingRight:"16px",position: "absolute", bottom: "10px", color: "#fff", fontFamily: "main-font",width:"calc(100%  - 16px)",margin:"0",lineHeight:"24px",borderBottomRightRadius:"8px",borderBottomLeftRadius:"8px",paddingTop:"8px",paddingBottom:"8px"}}>{row.title}</p>
              </div>
            </Link>
          </SwiperSlide>
        })}
      </Swiper>




    </div>
  );
};

export default BlogSlider;