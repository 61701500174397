import React from 'react';

const TitleBox = () => {
  return (
    <>
      <h1 className={"fih1"}>استعلام و پرداخت عوارض آزادراه</h1>
      <p className={"type-info"}>
        ماشین نو ؛ سامانه استعلام و پرداخت عوارض آزادراه، جاده‌ای و بزرگراهی
      </p>
    </>
  );
};

export default TitleBox;