import React, {useRef, useState} from 'react';
import Input from "../../../Ui/Input/Input";
import {IPlate} from "../../../Ui/Plate/IPlate";
import Plate from "../../../Ui/Plate/Plate";
import Dialog from "../../../Layouts/Dialog/Dialog";
import CarSearchDialog from "./CarSearchDialog";
import {webRequest} from "../../../../Services/apiService";
import {validateAuth, validateResponse} from "../../../../Infrastructure/Api/ValidateResponse";
import xToast from "../../../../commons/xToast";
import LoadingButton from "../../../Ui/Button/LoadingButton";
import CodeMode from "../../Login/CodeMode";
import {CookieManager} from "../../../../Infrastructure/StorageManager/CookieManager";

interface Props {
  plate?: IPlate,
  show: boolean,
  setShow: Function,
  onPlateAdded: Function
}


const AddPlateDialog = (props: Props) => {
  let [mobile, setMobile] = useState("");
  let [nationCode, setNationCode] = useState("");

  let [loading, setLoading] = useState<boolean>(false);
  let [car, setCar] = useState<any>("");
  let [brand, setBrand] = useState<any>("");
  let carDialogRef: any = useRef()
  let [showCarDialog, setShowCarDialog] = useState(false);
  let [confirmMobile, setConfirmMobile] = useState<boolean>(false);
  let [time, setTime] = useState(0)
  let [code, setCode] = useState("")


  const confirm = (type: "sendCode" | "confirmAndAdd" | "create" = "create") => {

    setLoading(true)
    webRequest({
      path: "/plate/add",
      auth: true,
      data: {
        type, car: car.id, brand: brand.id, plate: props.plate, mobile, code, nationCode
      }
    }).then(response => {
      setLoading(false)
      try {
        validateAuth(response)
        validateResponse(response)
        let type = response.payload.type;
        if (type === "login") {
          handleLoginMode(response.payload.auth)
        } else if (type === "verifyAnotherMode") {
          handleVerifyAnotherMode()
        } else if (type === "anotherUsername") {
          handleAnotherUser(response.payload)
        } else if (type === "verify") {
          handleVerify(response.payload)
        } else if (type === "added") {
          handleAddedPlate()
        }
      } catch (e: any) {
        xToast.error(e.message)
      }
    })
  }

  const handleAnotherUser = (data: any) => {
    setTime(data.auth.time)
    setConfirmMobile(true)
    setCode("")
  }

  const handleVerifyAnotherMode = () => {
    handleAddedPlate()
  }

  const handleVerify = (data: any) => {
    if (data.auth) CookieManager.set("auth", JSON.stringify(data.auth), 100)
    handleAddedPlate()
  }

  const handleLoginMode = (data: any) => {
    setTime(data.time)
    setConfirmMobile(true)
    setCode("")
  }

  const handleAddedPlate = () => {
    setTime(0)
    setConfirmMobile(false)
    setShowCarDialog(false)
    xToast.success("عملیات با موفقیت انجام شد")
    props.onPlateAdded({
      plate: props.plate,
      mobile, nationCode, car, brand,
    })
  }


  const showCarModel = () => {
    setShowCarDialog(true);
    carDialogRef.current.style.position = "relative";
  }

  const closeCarDialog = () => {
    carDialogRef.current.style.position = "static";
    setShowCarDialog(false);
  }

  const carSelected = (car: any, brand: any) => {
    setCar(car)
    setBrand(brand)
    setShowCarDialog(false);
  }

  let carText = ""

  if (brand && car) {
    carText = brand.title + " - " + car.name
  }


  return (
    <div>

      <div style={{position: "relative", zIndex: props.show ? "10" : "-1"}}>
        <Dialog show={props.show} close={() => props.setShow(false)} title={"وارد کردن پلاک"} height={540}>
          <div style={{maxWidth: "260px", margin: "auto"}}>
            <div style={{fontSize: "15px", fontFamily: "main-font-bold", lineHeight: "40px"}}>
              پلاک خودروی خود را وارد کنید :
            </div>
            <Plate loadingMode={false} readonly={true} value={props.plate} onUnDone={() => {
            }} onDone={() => {
            }}/>
          </div>
          <div style={{margin: "20px auto", borderTop: "1px solid #eee"}}>

            <div className={"x-title mt-3"} style={{padding: "0 16px", fontSize: "18px"}}>
              اطلاعات تکمیلی
            </div>
            <div style={{padding: "0 16px 8px 16px", fontSize: 14}}>
              تکمیل اطلاعات استفاده از امکانات سایت را آسان تر می کند
            </div>

            <div className={"mt-4"} style={{maxWidth: "calc(100% - 30px)", margin: "8px auto"}}>
              <Input label={"شماره موبایل"} value={mobile} type={"number"} setValue={setMobile}/>
              <div className={"mt-4"}>
                <Input label={"کد ملی مالک ( اختیاری )"} value={nationCode} type={"number"} setValue={setNationCode}/>
              </div>

              <div className={"mt-4"} onClick={showCarModel}>
                <Input label={"مدل خودرو ( اختیاری )"} value={carText} type={"text"} setValue={() => {
                  setShowCarDialog(true)
                }}/>
              </div>

            </div>
          </div>
          <div style={{maxWidth: "calc(100% - 32px)", margin: "auto"}}>
            <LoadingButton text={"تایید و ثبت پلاک"} clicked={() => confirm("create")} loading={loading} type={"primary"}/>
          </div>
        </Dialog>
      </div>
      <div style={{position: "relative", zIndex: showCarDialog ? "11" : "-1"}} ref={carDialogRef}>
        <Dialog show={showCarDialog} close={() => closeCarDialog()} title={"مدل خودرو"} height={550}>
          <CarSearchDialog carSelected={carSelected}/>
        </Dialog>
      </div>

      <div style={{position: "relative", zIndex: confirmMobile ? "11" : "-1"}} ref={carDialogRef}>
        <Dialog show={confirmMobile} close={() => setConfirmMobile(false)} title={"تایید شماره موبایل"} height={360}>
          <div style={{padding:"0 16px"}}>
            <CodeMode code={code} setCode={setCode} confirmCode={() => confirm("confirmAndAdd")} loading={loading} mobile={mobile} time={time} setTime={setTime} sendCodeAgain={() => confirm("sendCode")}/>
          </div>
        </Dialog>
      </div>

    </div>
  );
};

export default AddPlateDialog;