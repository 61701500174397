import React from 'react';
import {Link, useNavigate} from "react-router-dom";
import {CookieManager} from "../../../Infrastructure/StorageManager/CookieManager";

const ProfileMenus = () => {
  let navigate = useNavigate()

  const logout = () => {
    CookieManager.set("auth", null, 0)
    navigate("/login")
  }

  return (
    <div>
      <ul style={{listStyle: "none", padding: "0",}}>
        <li style={{width: "calc(100% - 32px)", borderBottom: "1px solid #eee", margin: "auto", paddingBottom: "8px", display: "flex", alignItems: "center"}}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width={16} style={{transform: "rotateY(-180deg)"}}>
            <path
              d="M76.29 154.2C73.41 157.7 69.22 159.8 64.72 159.1C60.23 160.2 55.86 158.5 52.69 155.3L4.686 107.3C-1.562 101.1-1.562 90.94 4.686 84.69C10.93 78.44 21.06 78.44 27.31 84.69L62.92 120.3L131.7 37.76C137.4 30.97 147.5 30.05 154.2 35.71C161 41.37 161.9 51.45 156.3 58.24L76.29 154.2zM76.29 314.2C73.41 317.7 69.22 319.8 64.72 319.1C60.23 320.2 55.86 318.5 52.69 315.3L4.686 267.3C-1.562 261.1-1.562 250.9 4.686 244.7C10.93 238.4 21.06 238.4 27.31 244.7L62.92 280.3L131.7 197.8C137.4 190.1 147.5 190.1 154.2 195.7C161 201.4 161.9 211.5 156.3 218.2L76.29 314.2zM191.1 96C191.1 87.16 199.2 80 207.1 80H496C504.8 80 512 87.16 512 96C512 104.8 504.8 112 496 112H207.1C199.2 112 191.1 104.8 191.1 96zM191.1 256C191.1 247.2 199.2 240 207.1 240H496C504.8 240 512 247.2 512 256C512 264.8 504.8 272 496 272H207.1C199.2 272 191.1 264.8 191.1 256zM159.1 416C159.1 407.2 167.2 400 175.1 400H496C504.8 400 512 407.2 512 416C512 424.8 504.8 432 496 432H175.1C167.2 432 159.1 424.8 159.1 416zM63.1 448C46.33 448 31.1 433.7 31.1 416C31.1 398.3 46.33 384 63.1 384C81.67 384 95.1 398.3 95.1 416C95.1 433.7 81.67 448 63.1 448z"/>
          </svg>
          <Link to="/inquiries" style={{textDecoration: "none", color: "#222", paddingRight: "8px", position: "relative", top: "2px"}}>لیست استعلام ها</Link>
        </li>

        <li style={{width: "calc(100% - 32px)", borderBottom: "1px solid #eee", margin: "auto", paddingBottom: "8px", paddingTop: "8px", display: "flex", alignItems: "center"}}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width={16}>
            <path d="M464 32C472.8 32 480 39.16 480 48C480 56.84 472.8 64 464 64H80C53.49 64 32 85.49 32 112V400C32 426.5 53.49 448 80 448H432C458.5 448 480 426.5 480 400V176C480 149.5 458.5 128 432 128H112C103.2 128 96 120.8 96 112C96 103.2 103.2 96 112 96H432C476.2 96 512 131.8 512 176V400C512 444.2 476.2 480 432 480H80C35.82 480 0 444.2 0 400V112C0 67.82 35.82 32 80 32H464zM360 288C360 274.7 370.7 264 384 264C397.3 264 408 274.7 408 288C408 301.3 397.3 312 384 312C370.7 312 360 301.3 360 288z"/>
          </svg>
          <Link to="/wallet" style={{textDecoration: "none", color: "#222", paddingRight: "8px", position: "relative", top: "2px"}}>کیف پول</Link>
        </li>

        <li style={{width: "calc(100% - 32px)", margin: "auto", paddingBottom: "8px", paddingTop: "8px", display: "flex", alignItems: "center"}}>

          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" width={16}>
            <path d="M232 224c-13.25 0-24 14.33-24 31.1S218.7 288 232 288S256 273.7 256 256S245.3 224 232 224zM260.4 1.443l-160 40C78.1 46.79 64 65.1 64 88.03V480H16C7.164 480 0 487.2 0 496C0 504.8 7.164 512 16 512H288c17.67 0 32-14.33 32-32V49.15c0-10.14-2.611-20.34-8.523-28.59C299.4 3.707 279.3-3.279 260.4 1.443zM288 480H96V88.04c0-7.373 5-13.75 12.12-15.53l160-40C272.9 31.22 277.9 32.38 281.8 35.41C285.8 38.47 288 43.07 288 48.04V480zM560 480H512V144c0-44.18-35.82-79.1-79.1-79.1H368c-8.836 0-16 7.162-16 15.1s7.164 16 16 16h64c26.51 0 48 21.49 48 48V480c0 17.67 14.33 32 32 32h48c8.836 0 16-7.164 16-15.1C576 487.2 568.8 480 560 480z"/>
          </svg>

          <span onClick={logout} style={{cursor: "pointer", textDecoration: "none", color: "#222", position: "relative", top: "2px", paddingRight: "8px"}}>خروج از حساب کاربری</span>
        </li>

      </ul>
    </div>
  );
};

export default ProfileMenus;