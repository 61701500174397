import React from 'react';

const IconInfo = ({color,styles,width,height}:{color:string,styles:object,width:number,height:number}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 192 512" fill={color} width={width} height={height } style={styles}>
      <path d="M144 80c0 26.5-21.5 48-48 48s-48-21.5-48-48s21.5-48 48-48s48 21.5 48 48zM0 224c0-17.7 14.3-32 32-32H96c17.7 0 32 14.3 32 32V448h32c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H64V256H32c-17.7 0-32-14.3-32-32z"/>
    </svg>
  );
};

export default IconInfo;