const appReducer = (state: any, action: any) => {
  let newState = state


  switch (action.type) {
    case "DISPATCH_STATE":
      let result = window.localStorage.getItem("state")
      if (result) {
        let parsedData
        try {
          parsedData = JSON.parse(result);
        } catch (e) {
          parsedData = state
        }

        let initState = {
          ...state,
          ...parsedData
        }
        initState.isLoaded = true;
        newState = initState
      } else {
        newState = state
        newState.isLoaded = true;
      }

      break;

    case "SAVE_ARTICLES":
      newState = {...state, articles: action.payload}
      break;


    case "SAVE_SLIDES":
      newState = {...state, slides: action.payload}
      break;

    case "ADD_CAR":
      newState = {...state, cars: action.payload}
      break;


    case "SHOW_PWA_INSTALL_POPUP":
      newState = {...state, pwa: action.payload}
      break;




  }

  if (newState.isLoaded) {
    window.localStorage.setItem("lastChange", "ADD_TO_CART")
    window.localStorage.setItem("state", JSON.stringify(newState))
  }

  return newState
}

export default appReducer
