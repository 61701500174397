import React from 'react';

const HomeIcon = ({color}: { color: string }) => {
  return (
    <div className={"tab-icon-container"}>
      <img src="/assets/app/home.gif" width={32} alt=""/>
    </div>
  );
};

export default HomeIcon;