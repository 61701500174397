import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {webRequest} from "../../../Services/apiService";
import Skeleton from "react-loading-skeleton";
import HtmlText from "../../Ui/HtmlText";
import "./BlogSinglePage.css"
import BackIcon from "../../Ui/BackIcon";

const BlogSinglePage = () => {
  let params = useParams()
  let [data, setData] = useState<undefined | any>(undefined)

  let navigate = useNavigate()

  useEffect(() => {

    webRequest({
      path: "/pages/blogSingle",
      data: {
        id: params.id,
      }
    }).then(response => {
      try {
        setData(response.payload.extra)
      } catch (e: any) {
      }
    })

  }, [params.id]);

  const backClicked = () => {
    navigate(-1)
  }


  return (
    <>

      <div className={"blog-header"}>
        <div>
          <BackIcon width={16} height={16} color={"#000"} onClick={backClicked}/>
        </div>
        <span className={"blog-title"}>
          {data && <>{data.title}</>}
        </span>
      </div>

      {data && <div style={{padding: 16}} className={"blog-single"}>
        <img src={data.image} alt="" className={"blog-main-image"} style={{maxWidth: "100% !important", width: "100% !important", margin: "16px 0 !important"}}/>
        <HtmlText text={data.description}/>
      </div>}

      {!data && <div style={{padding: 16,marginTop:"54px"}}>
        <Skeleton width={"100%"} height={"200px"}/>

        <Skeleton width={"100%"} height={"12px"} style={{marginTop: "16px"}}/>
        <Skeleton width={"100%"} height={"12px"}/>
        <Skeleton width={"100%"} height={"12px"}/>
        <Skeleton width={"40%"} height={"12px"}/>

        <Skeleton width={"100%"} height={"12px"}/>
        <Skeleton width={"100%"} height={"12px"}/>
        <Skeleton width={"60%"} height={"12px"}/>


        <Skeleton width={"100%"} height={"12px"}/>
        <Skeleton width={"100%"} height={"12px"}/>
        <Skeleton width={"100%"} height={"12px"}/>
        <Skeleton width={"100%"} height={"12px"}/>
        <Skeleton width={"22%"} height={"12px"}/>


        <Skeleton width={"100%"} height={"12px"}/>
        <Skeleton width={"100%"} height={"12px"}/>
        <Skeleton width={"100%"} height={"12px"}/>
        <Skeleton width={"100%"} height={"12px"}/>
        <Skeleton width={"100%"} height={"12px"}/>
        <Skeleton width={"100%"} height={"12px"}/>
        <Skeleton width={"100%"} height={"12px"}/>
        <Skeleton width={"60%"} height={"12px"}/>


        <Skeleton width={"100%"} height={"12px"}/>
        <Skeleton width={"100%"} height={"12px"}/>
        <Skeleton width={"100%"} height={"12px"}/>
        <Skeleton width={"100%"} height={"12px"}/>
        <Skeleton width={"100%"} height={"12px"}/>
        <Skeleton width={"100%"} height={"12px"}/>
        <Skeleton width={"100%"} height={"12px"}/>
        <Skeleton width={"40%"} height={"12px"}/>


        <Skeleton width={"100%"} height={"12px"}/>
        <Skeleton width={"100%"} height={"12px"}/>
        <Skeleton width={"100%"} height={"12px"}/>
        <Skeleton width={"100%"} height={"12px"}/>
        <Skeleton width={"80%"} height={"12px"}/>


      </div>}


    </>
  );
};

export default BlogSinglePage;