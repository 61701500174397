import React, {useEffect} from 'react';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import HomePage from "./Components/Pages/Home/HomePage";
import AboutUsPage from "./Components/Pages/AboutUs/AboutUsPage";
import CarsPage from "./Components/Pages/Cars/CarsPage";
import AiPage from "./Components/Pages/Ai/AiPage";
import Story from "./Components/Ui/Story/Story";
import {initializeTagManager} from "./gtm";
import FreewayInquiryPage from "./Components/Pages/Inquiries/FreewayInquiry/FreewayInquiryPage";
import "./App.css"
import 'react-toastify/dist/ReactToastify.css';
import LoginPage from "./Components/Pages/Login/LoginPage";
import ConfirmDialog from "./Components/Ui/ConfirmDialog/ConfirmDialog";
import BlogSinglePage from "./Components/Pages/Blog/BlogSinglePage";
import BlogPage from "./Components/Pages/Blog/BlogPage";
import ShopPage from "./Components/Pages/Shop/ShopPage";
import ThirdParty from "./Components/Pages/Insurance/ThirdParty";
import ThirdPartyMotor from "./Components/Pages/Insurance/ThirdPartyMotor";
import CarBody from "./Components/Pages/Insurance/CarBody";
import FreewayReview from "./Components/Pages/Inquiries/FreewayInquiry/FreewayReview/FreewayReview";
import ProfilePage from "./Components/Pages/Profile/ProfilePage";
import FreewayGatewayCallback from "./Components/Pages/Inquiries/FreewayInquiry/Callback/FreewayGatewayCallback";
import WalletPage from "./Components/Pages/WalletPage/WalletPage";
import WalletPageCallback from "./Components/Pages/WalletPage/WalletPageCallback";
import InquiriesPage from "./Components/Pages/Inquiries/Inquiries/InquiriesPage";
import CarLicenceInquiryPage from "./Components/Pages/Inquiries/CarLicenceInquiry/CarLicenceInquiryPage";
import VehicleViolationInquiryPage from "./Components/Pages/Inquiries/VehicleViolationInquiry/VehicleViolationInquiryPage";
import CarLicenceCallback from "./Components/Pages/Inquiries/CarLicenceInquiry/CarLicenceCallback";
import ShowInquiryResultPage from "./Components/Pages/Inquiries/VehicleViolationInquiry/ShowInquiryResultPage";
import LicenseNegativeScorePage from "./Components/Pages/Inquiries/LicenseNegativeScore/LicenseNegativeScorePage";
import LicenseNegativeResultPage from "./Components/Pages/Inquiries/LicenseNegativeScore/LicenseNegativeResultPage";
import CardAndDocumentPage from "./Components/Pages/Inquiries/CardAndDocument/CardAndDocumentPage";
import ShowCardAndDocumentResultPage from "./Components/Pages/Inquiries/CardAndDocument/ShowCardAndDocumentResultPage";
import ShowActivePlatesResultPage from "./Components/Pages/Inquiries/ActivePlates/ShowActivePlatesResultPage";
import ActivePlatesInquiryPage from "./Components/Pages/Inquiries/ActivePlates/ActivePlatesInquiryPage";


function App() {

  useEffect(() => {
    initializeTagManager()
  }, []);

  return (
    <BrowserRouter>
      <ConfirmDialog/>
      <Routes>
        <Route path={"/"} element={<HomePage/>}/>
        <Route path={"/inquiry/freeway-tolls"} element={<FreewayInquiryPage/>}/>
        <Route path={"/inquiry/freeway-tolls/:plate"} element={<FreewayReview/>}/>
        <Route path={"/about-us"} element={<AboutUsPage/>}/>
        <Route path={"/inquiries"} element={<InquiriesPage/>}/>
        <Route path={"/partnership"} element={<AboutUsPage/>}/>
        <Route path={"/cars"} element={<CarsPage/>}/>
        <Route path={"/ai"} element={<AiPage/>}/>
        <Route path={"/story/:path"} element={<Story/>}/>
        <Route path={"/login"} element={<LoginPage/>}/>
        <Route path={"/blog"} element={<BlogPage/>}/>
        <Route path={"/shop"} element={<ShopPage/>}/>
        <Route path={"/blog/:id/:slug?"} element={<BlogSinglePage/>}/>
        <Route path={"/insurance/third-party"} element={<ThirdParty/>}/>
        <Route path={"/insurance/third-party-motor"} element={<ThirdPartyMotor/>}/>
        <Route path={"/insurance/car-body"} element={<CarBody/>}/>
        <Route path={"/profile"} element={<ProfilePage/>}/>
        <Route path={"/wallet"} element={<WalletPage/>}/>
        <Route path={"/freeway/callback/:id"} element={<FreewayGatewayCallback/>}/>
        <Route path={"/wallet-callback"} element={<WalletPageCallback/>}/>
        <Route path={"/inquiry/car-license-inquiry"} element={<CarLicenceInquiryPage/>}/>
        <Route path={"/inquiry/car-license-inquiry/callback"} element={<CarLicenceCallback/>}/>
        <Route path={"/inquiry/vehicle-violation-inquiry"} element={<VehicleViolationInquiryPage/>}/>
        <Route path={"/inquiry/vehicle-violation-inquiry/:id"} element={<ShowInquiryResultPage/>}/>
        <Route path={"/inquiry/license-negative-score"} element={<LicenseNegativeScorePage/>}/>
        <Route path={"/inquiry/license-negative-score/:id"} element={<LicenseNegativeResultPage/>}/>

        <Route path={"/inquiry/card-and-document"} element={<CardAndDocumentPage/>}/>
        <Route path={"/inquiry/card-and-document/:id"} element={<ShowCardAndDocumentResultPage/>}/>


        <Route path={"/inquiry/active-plates"} element={<ActivePlatesInquiryPage/>}/>
        <Route path={"/inquiry/active-plates/:id"} element={<ShowActivePlatesResultPage/>}/>


      </Routes>
    </BrowserRouter>
  );
}

export default App;
