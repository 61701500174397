import {useEffect, useState} from 'react';

let showConfirm: any = null;
let _dismiss: any = null;
let _loading: any = null;


interface IProps {
  title: string,
  description: string,

  onConfirmButtonClicked: Function,
  onCancelButtonClicked?: Function,
  onNegativeButtonClicked?: Function,

  confirmButtonText?: string
  cancelButtonText?: string
  negativeButtonText?: string,
  width?: number,
  height?: number,
  zIndex?: number
}


const useConfirmDialog = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [data, setData] = useState<IProps | undefined>(undefined);
  const [zIndex, setZIndex] = useState<number>(4000);
  let [loading, setLoading] = useState<boolean>(false);


  const dismiss = () => {
    setIsOpen(false);
  }


  useEffect(() => {
    showConfirm = (props: IProps) => {
      setIsOpen(true);
      setData(props)
      setZIndex(props.zIndex ?? 4001)
      setLoading(false)
    };

    _dismiss = (props: IProps) => {
      setIsOpen(false);
      setData(undefined)
      setLoading(false)
    };

    _loading = (show:boolean) => {
      setLoading(show)
    };


  }, []);


  return {
    isOpen,
    dismiss,
    data,
    zIndex,
    loading
  };
};

export default useConfirmDialog;

export const showConfirmDialog = (props: IProps) => {
  if (showConfirm) {
    showConfirm(props);
  }
};

export const dismissConfirmDialog = () => {
  if (_dismiss) {
    _dismiss()
  }
}

export const showLoadingOnConfirm = () => {
  if (_loading) {
    _loading(true)
  }
}

export const hideLoadingOnConfirm = () => {
  if (_loading) {
    _loading(false)
  }else{
    setTimeout(function (){
      hideLoadingOnConfirm()
    },300)
  }
}

