import React from 'react';

const AboutIcon = ({color}:any) => {
  return (
    <div className={"tab-icon-container"} >
      <img src="/assets/app/car.gif" width={32} height={32} alt=""/>
    </div>
  );
};

export default AboutIcon;