import React from 'react';
import AvatarFileHandler from "./AvatarFileHandler";
import InquiryLayout from "../../Layouts/Inquiry/InquiryLayout";
import ProfileMenus from "./ProfileMenus";

const ProfilePage = () => {
  return (
    <InquiryLayout>
      <AvatarFileHandler/>
      <ProfileMenus/>
    </InquiryLayout>
  );
};

export default ProfilePage;