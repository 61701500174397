import React from 'react';
import Skeleton from "react-loading-skeleton";


const HomeItem = () => {


  return (
    <a href={""} className={"nav-item home-item"} style={{margin: "auto"}}>
      <div className={"nav-image"} style={{background: "#" + ""}}>
        <Skeleton width={"40px"} height={"40px"} style={{borderRadius: "12%"}}/>
      </div>
      <span>
            <Skeleton width={"50px"}/>
          </span>
    </a>
  );
};


const HomeSkeleton = () => {
  let width = window.innerWidth;
  if (width > 500) {
    width = 500
  }
  width = width - 48 - 30


  let xWidth = parseInt(width / 2.5 + "") + "px"
  return (
    <div>
      <div style={{display: "block", height: "280px", position: "relative", top: 16}}>
        <Skeleton width={"3%"} height={"200px"} style={{display: "inline-block", position: "absolute", right: "0", top: "0"}}/>
        <Skeleton width={"90%"} height={"200px"} style={{margin: "auto", display: "block", position: "absolute", left: 0, right: 0, top: "0"}}/>
        <Skeleton width={"3%"} height={"200px"} style={{display: "inline-block", position: "absolute", left: "0", top: "0"}}/>
      </div>

      <div className={"tac"} style={{paddingTop: 0}}>


        <div style={{display: "flex"}}>
          <HomeItem/>
          <HomeItem/>
          <HomeItem/>
        </div>

        <div style={{display: "flex", marginTop: "16px"}}>
          <HomeItem/>
          <HomeItem/>
          <HomeItem/>
        </div>

      </div>


      <div style={{display: "flex", marginTop: "32px",overflow:"hidden"}}>
        <Skeleton width={xWidth} height={"180px"} style={{display: "inline-block", float: "right", marginRight: "24px", marginLeft: "10px"}}/>
        <Skeleton width={xWidth} height={"180px"} style={{display: "inline-block", float: "right", marginLeft: "10px"}}/>
        <Skeleton width={width} height={"180px"} style={{display: "inline-block", float: "right", marginLeft: "10px"}}/>
      </div>

    </div>
  );
};

export default HomeSkeleton;