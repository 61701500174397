import React, {useEffect} from 'react';
import {Link} from "react-router-dom";
import XImage from "../../Layouts/XImage";
import useAppContext from "../../../state/useAppContext";

const Story = ({story, index}: any) => {



  return <div className={`swiper-slide story-container ${index == 0 ? "first-story" : ""}`} style={{width:'64px'}}>
    <div>
      <Link className="story cp d-block" to={"/story/" + story.slug} style={{display: 'block'}}>
        <div className={"story-wrapper"}>
          <XImage src={story.icon} alt={story.title} width={54} height={54} optimizeImage={false}/>
        </div>
      </Link>
    </div>
  </div>
}


const Stories = ({stories}: { stories: any[] }) => {
  let {state} = useAppContext()

  useEffect(() => {
    if (!state.isLoaded) return;

    let event=new CustomEvent("create-story-scroll");
    dispatchEvent(event);
  }, [state.isLoaded]);

  return (
    <>
      <section className="product-meta mb-2" style={{overflowX:"auto",marginRight:"8px",marginLeft:"8px"}}>
        <div className={"stories-swiper  stories"} dir={"rtl"}>
          <div className={'swiper-wrapper'}>
            {stories.map((row: any, index: number) => {
              return <Story key={row.id} index={index} story={row}/>
            })}
          </div>
        </div>
      </section>
    </>
  );
};

export default Stories;
