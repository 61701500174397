import {IState} from "../../state/initState";
import {Dispatch} from "react";
import {ActionType} from "../../state/useAppContext";

export class CarManager {

  static getCars(state: IState) {
    return state.cars
  }

  static has(state: IState, car: any): boolean {
    let result = state.cars.filter((row: any) => row.id === car.id)
    return result.length > 0;
  }

  static add(state: IState, dispatch: Dispatch<ActionType>, car: any) {
    let cars = state.cars
    if (this.has(state, car)) {
      return;
    } else {
      cars.push(car)
      dispatch({
        type: "ADD_CAR",
        payload: cars
      })
    }
  }

  static delete(state: IState, dispatch: Dispatch<ActionType>, car: any) {
    let result = state.cars.filter((row: any) => row.id !== car.id)
    dispatch({
      type: "ADD_CAR",
      payload: result
    })
  }


}
