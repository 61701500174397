import React, {useEffect, useState} from 'react';
import {webRequest} from "../../../../Services/apiService";
import Skeleton from "react-loading-skeleton";


const Loader = () => {


  const Partial = () => {
    return <div style={{display: "block"}}>
      <Skeleton width={"48px"} height={"48px"} style={{display: "inline-block", float: "right"}}/>
      <div style={{position: "relative", top: "-15px"}}>
        <Skeleton width={"100px"} style={{display: "inline-block", float: "right", marginRight: "12px"}}/>
        <Skeleton width={"calc(50%)"} style={{display: "inline-block", float: "right", marginRight: "12px"}}/>
      </div>
    </div>
  }


  return (
    <>
      <Partial/>
      <Partial/>
      <Partial/>
      <Partial/>
      <Partial/>
      <Partial/>
      <Partial/>
      <Partial/>
      <Partial/>
      <Partial/>
    </>
  );
};

const CarSearchDialog = ({carSelected}: { carSelected: Function }) => {
  let [loaded, setLoaded] = useState<boolean>(false);

  let [brands, setBrands] = useState<any[]>([]);
  let [carData, setCarData] = useState<any[]>([]);
  let [cars, setCars] = useState<any[]>([]);
  let [brand, setBrand] = useState<any>(undefined);
  let [mode, setMode] = useState("brand")


  useEffect(() => {
    webRequest({
      path: "/brands",
    }).then(response => {
      setBrands(response.payload.brands);
      setCarData(response.payload.cars);
      setLoaded(true)
    })
  }, []);


  const getCars = (brandId: number) => carData.filter((row: any) => row.brand_id === brandId)


  const goto = (brand:any) => {
    let cars = getCars(brand.id)
    setBrand(brand)
    setCars(cars)
    setMode("car")
  }

  const confirmCar = (car: any) => {
    setMode("brand")
    setLoaded(false)
    setCars([])
    setCarData([])
    setBrands([])
    setBrand(undefined)
    carSelected(car,brand)
  }


  return (
    <div>
      {/*<div style={{maxWidth: "calc(100% - 32px)", marginTop: "16px"}}>*/}
      {/*  <Input type={"text"} label={"جست و جوی برند"} value={keyword} setValue={setKeyword}/>*/}
      {/*</div>*/}

      <div style={{paddingTop:"8px"}}>
        {!loaded && <Loader/>}
      </div>

      {mode === "brand" && <>{loaded && <>{brands.map((row: any, index: number) => {
        return <div key={index} style={{height: "54px", display: "flex", alignItems: "center", borderBottom: "1px solid #eee", cursor: 'pointer'}} onClick={() => goto(row)}>
          <img src={row.image} alt="" style={{width: "48px"}}/>
          <div style={{paddingRight: "12px"}}>
            <div style={{color: "#222", fontSize: "16px", fontWeight: "bold"}}>{row.title}</div>
            <div style={{fontSize: "14px"}}>
              {getCars(row.id).length}
              &nbsp;
              خودرو
            </div>
          </div>
        </div>
      })}
      </>}
      </>}

      {mode === "car" && <>{loaded && <>{cars.map((row: any, index: number) => {
        return <div key={index} style={{height: "54px", display: "flex", alignItems: "center", borderBottom: "1px solid #eee", cursor: 'pointer'}} onClick={() => confirmCar(row)}>{row.title}
          <img src={"https://mashinno.com" + row.image} alt="" style={{width: "48px"}}/>
          <div style={{paddingRight: "12px"}}>
            <div style={{color: "#222", fontSize: "16px", fontWeight: "bold"}}>{row.name}</div>
          </div>
        </div>
      })}
      </>}
      </>}


    </div>
  );
};

export default CarSearchDialog;