import React, {useEffect, useState} from 'react';
import {webRequest} from "../../../Services/apiService";
import BlogSlider from "./BlogSlider";
import BlogSwiper from "./BlogSwiper";
import "./BlogPage.css"
import {Link, useNavigate} from "react-router-dom";
import BlogLoader from "./BlogLoader";
import ArticleLoader from "./ArticleLoader";
import BackIcon from "../../Ui/BackIcon";

const BlogPage = () => {
  let [data, setData] = useState<undefined | any>(undefined)
  let [loading, setLoading] = useState(false);
  let navigate = useNavigate();


  useEffect(() => {
    webRequest({
      path: "/pages/blogPage",
    }).then(response => {
      try {

        setData(response.payload)
      } catch (e: any) {
      }
    })
  }, []);

  let loadMore = () => {
    setLoading(true)
    webRequest({
      path: "/pages/blogPage",
      data: {
        page: data.latest.currentPage + 1
      }
    }).then(response => {
      setLoading(false)
      setData({
        ...data,
        latest: {
          ...data.latest,
          records: [
            ...data.latest.records,
            ...response.payload.latest.records
          ]
        }
      })
    })
  }


  const backClicked = () => {
    navigate(-1)
  }

  return (
    <>
      <div className={"blog-header"}>
        <div>
          <BackIcon width={16} height={16} color={"#000"} onClick={backClicked}/>
        </div>
        <span style={{fontSize:"13px"}}>
          مجله خودرویی ماشین نو
        </span>
      </div>


      <div style={{paddingTop: "48px"}}>
        {!data && <BlogLoader/>}
        {data && <>
          <BlogSlider slides={data.slides}/>
          <BlogSwiper articles={data.populars} title={"محبوب ترین ها"}/>

          <div style={{marginTop: "32px", padding: "0 16px", paddingBottom: "32px"}}>

            <div className={"blog-part"} style={{marginTop: "2px", background: "#f8f8f8", borderRadius: "8px", lineHeight: "24px", padding: '0',}}>
              <div className={"header"} style={{paddingRight: "8px", marginTop: "0", background: "transparent", width: "calc(100% - 32px)", paddingTop: "0", height: "40px"}}>
                آخرین مقالات
              </div>
            </div>

            {data.latest.records.map((row: any, index: number) => {
              return <Link to={"/blog/" + row.id} key={index} className={"article-row"}>
                <div className={"image-part"}>
                  <img src={row.image} alt=""/>
                </div>
                <div className={"description-part"}>
                  <h3>
                    {row.title}
                  </h3>
                </div>
              </Link>
            })}
          </div>


          {data.latest.lastPage > data.latest.currentPage && <div style={{textAlign: "center", paddingBottom: "32px"}}>
            <div style={{textAlign: "center", cursor: "pointer", color: "#10c0f1"}}>
            <span style={{borderBottom: "1px dashed #10c0f1"}} onClick={loadMore}>
              مقالات بیشتر
            </span>
            </div>
          </div>}

          {loading && <div style={{padding: '0 16px'}}><ArticleLoader/></div>}

        </>}
      </div>


    </>
  );
};

export default BlogPage;