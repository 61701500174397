import React from 'react';

const TitleBox = () => {
  return (
    <>
      <h1 className={"fih1"}>استعلام وضعیت کارت و سند خودرو</h1>
      <p className={"type-info"}>
        هزینه نمایش جزئیات استعلام وضعیت کارت و سنت خودرو ۶٬۲۰۰ تومان است و ماشین نو دخل و تصرفی در تعیین این هزینه ندارد.
      </p>
    </>
  );
};

export default TitleBox;