import {Dispatch, useContext} from "react";
import AppContext from "./appContext";
import {IState} from "./initState";

export type ActionType = {
  type: string;
  payload: any
};

interface Context {
  state: IState,
  dispatch: Dispatch<ActionType>;
}

const useAppState = (): Context => {
  return useContext(AppContext) as Context
}

export default useAppState
