import {useEffect, useRef, useState} from "react";
import useAppContext from "../../../state/useAppContext";
import {webRequest} from "../../../Services/apiService";
import {validateAuth, validateResponse} from "../../../Infrastructure/Api/ValidateResponse";
import {Auth} from "../../../Infrastructure/Auth/Auth";
import {useNavigate} from "react-router-dom";
import {CookieManager} from "../../../Infrastructure/StorageManager/CookieManager";
import Skeleton from "react-loading-skeleton";

const AvatarFileHandler = () => {

  let {state, dispatch} = useAppContext()
  let avatarRef: any = useRef()
  let imageRef: any = useRef()

  let navigate = useNavigate()
  let [avatar, setAvatar] = useState("")


  let [data, setData] = useState<any | undefined>(undefined)


  const avatarClicked = () => {
    avatarRef.current.click()
  }

  function uploadAvatar(avatar: string) {
    webRequest({
      path: "/profile/uploadAvatar",
      auth: true,
      data: {
        avatar
      }
    }).then(response => {
      try {
        validateAuth(response)
        validateResponse(response)
        CookieManager.set("auth", JSON.stringify(response.payload.payload), 100)
      } catch (e) {
      }
    })
  }

  useEffect(() => {
    if (!state.isLoaded) return;
    webRequest({
      path: "/profile/getInfo",
      auth: true
    }).then(response => {
      try {
        validateAuth(response.payload)
        validateResponse(response)

        setData(response.payload)
        setTimeout(function (){
          initAvatar()
        },1000)
      }catch (e:any){
        if (e.message=="login"){
          navigate("/login?back=/profile")
        }
      }

    })
  }, [state.isLoaded]);

  function initAvatar() {
    avatarRef.current.onchange = null
    avatarRef.current.addEventListener("change", (event: any) => {
      let fileReader = new FileReader()
      fileReader.onloadend = () => {
        setAvatar(fileReader.result as unknown as string)
        uploadAvatar(fileReader.result as unknown as string)
      }
      fileReader.readAsDataURL(event.target.files[0])
    })
  }


  let auth = Auth.get()
  if (!auth) {
    navigate("/login?back=/profile")
    return <></>
  }


  return (
    <>
      {data && <>
        <div className="" style={{padding: "0 16px",display:"flex",alignItems:"center",height:"64px"}}>
          <input type="file" className={"hidden"} ref={avatarRef}/>
          <img
            src={data.avatar}
            ref={imageRef}
            width={48}
            onClick={avatarClicked}
            height={48}
            className="img-fluid img-profile-panel rounded-circle me-3 shadow-md"
            alt=""
            style={{borderRadius: "100%"}}
          />
          <div className="" style={{paddingRight:"8px",}}>
            <h6 className="font-14" style={{marginTop:"24px",marginBottom:"0"}}>{data.name}</h6>
            <h6 className="font-14" style={{marginTop:"8px"}}>
              {data.username}
            </h6>
          </div>
        </div>

      </>}

      {!data && <div style={{position:"relative",top:"-32px"}}>
        <Skeleton width={"100%"} height={"100vh"}/>
      </div>}
    </>
  );
};

export default AvatarFileHandler;
