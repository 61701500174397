import React from 'react';
import MainLayout from "../../Layouts/MainLayout";
import CarList from "./CarList";

const CarsPage = () => {


  return (
    <MainLayout>
      <div className="">
        <CarList/>
      </div>
    </MainLayout>
  );
};

export default CarsPage;