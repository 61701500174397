import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {webRequest} from "../../../../../Services/apiService";
import InquiryLayout from "../../../../Layouts/Inquiry/InquiryLayout";
import Plate from "../../../../Ui/Plate/Plate";
import {numberFormat} from "../../../../../commons/number";
import xToast from "../../../../../commons/xToast";
import {validateResponse} from "../../../../../Infrastructure/Api/ValidateResponse";
import LoadingButton from "../../../../Ui/Button/LoadingButton";
import Skeleton from "react-loading-skeleton";
import {Payment} from "../../../../../commons/Payment";
import {Auth} from "../../../../../Infrastructure/Auth/Auth";
import Dialog from "../../../../Layouts/Dialog/Dialog";
import Toman from "../../../../Layouts/Ui/Toman";
import Faqs from "../Faqs";

const FreewayReview = () => {

  let params = useParams()
  let [loading, setLoading] = useState(true);
  let [plate, setPlate] = useState<any | undefined>(undefined);
  let [inquiry, setInquiry] = useState<any>(undefined);
  let navigate = useNavigate()
  let [moving, setMoving] = useState<boolean>(false);
  let [wallet, setWallet] = useState<number>(0);
  let [preShow, setPreShow] = useState<boolean>(false);
  let [dialogLoading, setDialogLoading] = useState<boolean>(false);
  let [paymentLoadingWallet, setPaymentLoadingWallet] = useState<boolean>(false);
  let [paymentLoadingGateway, setPaymentLoadingGateway] = useState<boolean>(false);
  let [faqs,setFaqs] = useState<[]>([]);

  useEffect(() => {
    webRequest({
      path: '/freeway/inquiry',
      data: {
        plate: params.plate
      },
      auth: true
    }).then(response => {
      setLoading(false);
      try {
        validateResponse(response)
        setInquiry(response.payload.inquiry)
        setPlate(response.payload.plate)
        setFaqs(response.payload.faqs)
        setWallet(response.payload.wallet)
      } catch (e: any) {
        xToast.error(e.message)
      }
    })
  }, [params]);


  const openPaymentDialog = () => {
    if (!Auth.check()) {
      navigate("/login?back=/inquiry/freeway-tolls/" + params.plate)
      return;
    }
    setDialogLoading(true)
    webRequest({
      path: "/wallet/get",
      auth: true,
    }).then(response => {
      setDialogLoading(false)
      setWallet(response.payload)
      setPreShow(true)
    })
  }


  let pay = (type: any) => {
    if (type == "wallet") setPaymentLoadingWallet(true)
    else setPaymentLoadingGateway(true)

    setLoading(true)
    webRequest({
      path: "/freeway/payment",
      data: {
        inquiry, plate,
        amount: inquiry.total_amount,
        type
      },
      auth: true
    }).then(response => {
      setPaymentLoadingWallet(false)
      setPaymentLoadingGateway(false)
      try {
        validateResponse(response)

        if (response.payload.type == "gateway") {
          Payment.start(response.payload.data)
        } else {
          alert("from wallet")
        }

        setPreShow(false)
        setMoving(true)
      } catch (e: any) {
        setMoving(false)
      }
    })

  }

  return (
    <InquiryLayout>
      {!moving && <>

          <Plate loadingMode={loading} value={plate} readonly={true}/>
          <div className={"mt-2"}>

            {inquiry && <>
              <div className={"d-flex justify-content-between"} style={{padding: "32px", alignItems: "center"}}>

                <div>
                  <div style={{fontFamily: "main-font-bold", fontSize: "16px"}}>
                    عوارض آزادراهی
                  </div>
                  <div style={{fontSize: "13px"}}>
                    آزادراه‌های تحت پوشش سامانه آنی‌رو
                  </div>

                </div>

                <div>
                  <div>
                    {numberFormat(inquiry.total_amount / 10)}
                    &nbsp;
                    تومان
                  </div>
                </div>
              </div>
            </>}


            {!inquiry && <div style={{padding: "16px", marginTop: "32px"}}>
              <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                <div>
                  <div>
                    <Skeleton width={100} height={20}/>
                  </div>
                  <div style={{marginTop: "12px"}}>
                    <Skeleton width={180} height={12}/>
                  </div>
                </div>
                <div>
                  <div>
                    <Skeleton width={80} height={15}/>
                  </div>
                </div>
              </div>
            </div>}

            {(inquiry && inquiry.total_amount && inquiry.total_amount > 0) ? (
              <div style={{padding: "0 32px"}}>
                <LoadingButton text={"پرداخت"} clicked={openPaymentDialog} type={"success"} loading={dialogLoading}/>
              </div>
            ):null}

          </div>



        {!inquiry && <div style={{marginTop:"32px",padding:"0 32px"}}>
          <Skeleton width={"100%"} height={54} style={{marginBottom:"8px"}}/>
          <Skeleton width={"100%"} height={54} style={{marginBottom:"8px"}}/>
          <Skeleton width={"100%"} height={54} style={{marginBottom:"8px"}}/>
          <Skeleton width={"100%"} height={54} style={{marginBottom:"8px"}}/>
          <Skeleton width={"100%"} height={54} style={{marginBottom:"8px"}}/>
          <Skeleton width={"100%"} height={54} style={{marginBottom:"8px"}}/>
        </div>}

        {inquiry && <div style={{marginTop:"32px",padding:"0 16px"}}>
          <Faqs records={faqs}/>
        </div>}


        <br/>
        <br/>
        <br/>



      </>}




      <Dialog show={preShow} close={() => setPreShow(false)} title={"بررسی قبل از پرداخت"} height={350}>
        {preShow && <div style={{paddingTop: "16px"}}>
          <Plate loadingMode={false} value={plate} readonly={true}/>
          <div>
            <ul style={{listStyle: "none", fontFamily: "main-font-bold"}}>
              <li>نوع درخواست : عوارض آزادراهی</li>
              <li style={{display: "flex", marginTop: "8px"}}>
                <div>مبلغ قابل پرداخت :</div>
                <Toman>{numberFormat(inquiry.total_amount / 10)}</Toman>
              </li>
              <li>
                <li style={{display: "flex", marginTop: "8px"}}>
                  <div>موجودی کیف پول :</div>
                  <Toman>{numberFormat(wallet)}</Toman>
                </li>
              </li>
            </ul>
          </div>


          <div style={{fontFamily: "main-font-bold", fontSize: "15px", marginTop: "32px", width: "calc(100% - 32px)", margin: "auto", left: "4px", position: "relative"}}>
            پرداخت با :
          </div>
          <div style={{display: "flex", position: "relative", top: "-25px", width: "calc(100% - 32px)", margin: "auto", left: "4px"}}>
            <LoadingButton text={"کیف پول"} clicked={() => pay("wallet")} loading={paymentLoadingWallet}/>
            &nbsp;
            &nbsp;
            &nbsp;
            &nbsp;
            <LoadingButton text={"درگاه پرداخت"} clicked={() => pay("gateway")} loading={paymentLoadingGateway}/>
          </div>


        </div>}
      </Dialog>

      {moving && <div style={{textAlign: "center", height: "100px", position: "fixed", left: "0", right: "0", top: "0", bottom: "0", margin: "auto auto"}}>
        <div>
          در حال انتقال به درگاه پرداخت
        </div>
        <div className="button-loader-dark" style={{width: "12px", height: "12px", position: "relative", top: 8}}></div>
      </div>}
    </InquiryLayout>
  );
};

export default FreewayReview;