import {Dispatch} from "react";
import {ActionType} from "../../state/useAppContext";
import {Auth} from "../Auth/Auth";

const STATUS_OK = 200;
const STATUS_TOKEN_EXPIRE = 422;
const STATUS_VALIDATION_ERROR = 501;
const STATUS_ERROR = 500;
const STATUS_HAS_NO_ACCESS = 403;

const ERROR_MESSAGES = {
  STATUS_TOKEN_EXPIRE: "خطا در احراز هویت",
  STATUS_VALIDATION_ERROR: "خطای اطلاعات ورودی",
  STATUS_ERROR: "خطای نامشخص",
  STATUS_HAS_NO_ACCESS: "Unauthorized"
}

export interface ApiResponse {
  status: number,
  success: boolean,
  payload: any,
  message?: string
}


export function validateResponse(response: ApiResponse, isFromMainFile = false) {

  if (response.status === STATUS_OK) {
    return response;
  }

  if (isFromMainFile) {
    throw new ResponseException("خطایی رخ داده است لطفا مجددا تلاش کنید", STATUS_ERROR)
  }

  if (response.status !== STATUS_TOKEN_EXPIRE) {
    throw new ResponseException(response.message)
  }

}

export function validateAuth(response: ApiResponse) {
  if (response.status === STATUS_TOKEN_EXPIRE) {
    Auth.remove("auth")
    throw new Error("login")
  }
  return response;
}


export function responseErrorHandler() {
  return {
    props: {
      status: STATUS_ERROR,
      error: true
    }
  }
}


export class ResponseException {
  message;
  status;

  constructor(message?: string, status = 500) {
    this.message = message
    this.status = STATUS_VALIDATION_ERROR
  }
}


export class AuthException {
  constructor(dispatch: Dispatch<ActionType> | null = null) {
    if (dispatch) {
      dispatch({
        type: "LOGIN",
        payload: {}
      })
      window.location.href = "/user/login?back=/user/cart"
    }

  }
}

