import React, {useEffect, useState} from 'react';
import {useNavigate, useSearchParams} from "react-router-dom";
import {webRequest} from "../../../Services/apiService";
import {validateAuth, validateResponse} from "../../../Infrastructure/Api/ValidateResponse";
import xToast from "../../../commons/xToast";
import InquiryLayout from "../../Layouts/Inquiry/InquiryLayout";
import Toman from "../../Layouts/Ui/Toman";
import {numberFormat} from "../../../commons/number";
import LoadingButton from "../../Ui/Button/LoadingButton";
import Skeleton from "react-loading-skeleton";
import {Payment} from "../../../commons/Payment";

const WalletPageCallback = () => {
  let [params] = useSearchParams();
  let [data, setData] = useState<any>(undefined)
  let [loading, setLoading] = useState<boolean>(false)
  let [paymentLoading, setPaymentLoading] = useState<boolean>(false)

  let navigate = useNavigate()


  useEffect(() => {
    webRequest({
      path: "/wallet/getResult",
      auth: true,
      data: {
        id: params.get("id")
      }
    }).then(response => {
      try {
        validateAuth(response)
        validateResponse(response);
        if (!response.payload.valid) xToast.error("تراکنش یافت نشد");
        setData(response.payload)
      } catch (e: any) {
        if (e.message == "login") window.location.href = "/"
        xToast.error("تراکنش یافت نشد");
      }
    })
  }, []);


  let paid = false;
  if (data && data.status == "پرداخت شده") paid = true;


  const back = () => navigate("/wallet")

  const payAgain = () => {
    setLoading(true);
    webRequest({
      path: "/wallet/recharge",
      data: {
        id: data.id,
      },
      auth: true
    }).then((response) => {
      try {
        setLoading(false)
        validateAuth(response)
        validateResponse(response)
        Payment.start(response.payload)
        setPaymentLoading(true)
      } catch (e: any) {
        if (e.message == "login") {
          navigate("/login?back=/wallet-callback?id="+data.id)
        } else {
          xToast.error(e.message)
        }
      }
    })
  }


  return (
    <InquiryLayout>
      {!paymentLoading && <>
        {!data && <div style={{border: "1px solid #eee", borderRadius: "12px", padding: "32px 16px", width: "calc(100% - 64px)", margin: "auto", position: "relative", top: "0px", paddingTop: "32px"}}>
          <div style={{width: '120px', margin: "auto", textAlign: "center"}}>
            <Skeleton width={110} height={110} style={{borderRadius: "100%"}}/>
          </div>
          <div style={{margin: "auto", textAlign: "center", fontFamily: "main-font-bold", fontSize: "20px", marginTop: "24px"}}>
            <Skeleton width={"320px"} height={20}/>
          </div>
          <div style={{display: "flex", justifyContent: "center", marginTop: "8px"}}>
            <Skeleton width={"100px"} height={20}/>
          </div>
          <div style={{display: "flex", justifyContent: "center", marginTop: "8px"}}>
            <Skeleton width={"200px"} height={20}/>
          </div>
          <div style={{paddingBottom: "12px", marginTop: "24px"}}>
            <Skeleton width={"100%"} height={48}/>
          </div>
        </div>}
        {data && <div style={{border: "1px solid #eee", borderRadius: "12px", padding: "8px 16px", width: "calc(100% - 64px)", margin: "auto", position: "relative", top: "0px", paddingTop: "32px"}}>
          <div style={{width: '120px', margin: "auto", textAlign: "center"}}>
            {data.status == "پرداخت نشده" && <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="110px" height="110px" fill="#EF4056">
              <path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM175 175c9.4-9.4 24.6-9.4 33.9 0l47 47 47-47c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9l-47 47 47 47c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-47-47-47 47c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l47-47-47-47c-9.4-9.4-9.4-24.6 0-33.9z"/>
            </svg>}
            {data.status == "پرداخت شده" && <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="110px" height="110px" fill="#0AAF58">
              <path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209L241 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L335 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z"/>
            </svg>}
          </div>
          <div style={{margin: "auto", textAlign: "center", fontFamily: "main-font-bold", fontSize: "20px", marginTop: "8px"}}>
            {!paid && "شارژ کیف پول موفقیت آمیز نبود!"}
            {paid && "شارژ کیف پول با موفقیت انجام شد"}
          </div>
          <div style={{display: "flex", justifyContent: "center", marginTop: "8px"}}>
            <div style={{fontFamily: "main-font-bold"}}> مبلغ :</div>
            &nbsp;
            <Toman>{numberFormat(data.amount)}</Toman>
          </div>
          <div style={{display: "flex", justifyContent: "center", marginTop: "8px"}}>
            <div style={{fontFamily: "main-font-bold"}}> موجودی فعلی کیف پول :</div>
            &nbsp;
            <Toman>{numberFormat(data.wallet)}</Toman>
          </div>
          <div style={{paddingBottom: "12px"}}>
            {!paid && <LoadingButton text={"پرداخت مجدد"} clicked={payAgain} loading={loading} type={"primary"}/>}
            {paid && <LoadingButton text={"بازگشت به کیف پول"} clicked={back} loading={loading} type={"success"}/>}
          </div>
        </div>}
      </>}

      {paymentLoading && <div style={{textAlign: "center", height: "100px",position:"fixed",left:"0",right:"0",top:"0",bottom:"0",margin:"auto auto"}}>
        <div>
          در حال انتقال به درگاه پرداخت
        </div>
        <div className="button-loader-dark" style={{width: "12px", height: "12px",position:"relative",top:8}}></div>
      </div>}

    </InquiryLayout>
  );
};

export default WalletPageCallback;