import React from 'react';
import "./menu.css"

const Menu = ({children, active, setActive}: { children: React.ReactNode, active: any, setActive: any }) => {

  const closeMenu = () => {
    if (active) {
      setActive(false)
    }
  }

  const closeFromBackdrop = (event: any) => {
    if (event.target.classList.contains("navigation-menu-container")) {
      closeMenu()
    }
  }

  return (
    <>
      <div className={`navigation-menu-container ${active ? "active" : ""}`} onClick={closeFromBackdrop}>
      </div>
      <nav className={`navigation ${active ? "active" : ""}`}>
        <div className={"navigation-header"}>
          <img src="https://mashinno.com/uploads/settings/settings-13-color.png" alt=""/>
          <div className={'menu-icon'} onClick={closeMenu}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 352 512" width={20} height={20}>
              <path d="M242.7 256l100.1-100.1c12.3-12.3 12.3-32.2 0-44.5l-22.2-22.2c-12.3-12.3-32.2-12.3-44.5 0L176 189.3 75.9 89.2c-12.3-12.3-32.2-12.3-44.5 0L9.2 111.5c-12.3 12.3-12.3 32.2 0 44.5L109.3 256 9.2 356.1c-12.3 12.3-12.3 32.2 0 44.5l22.2 22.2c12.3 12.3 32.2 12.3 44.5 0L176 322.7l100.1 100.1c12.3 12.3 32.2 12.3 44.5 0l22.2-22.2c12.3-12.3 12.3-32.2 0-44.5L242.7 256z"/>
            </svg>
          </div>
        </div>
        {children}
      </nav>
    </>
  );
};

export default Menu;