import React from 'react';
import {Link} from "react-router-dom";

const NavigationItem = ({title, image, className, link, width, height}: {
  width?: number,
  height?: number,
  title: string,
  image: string,
  className: string,
  link?: string
}) => {

  if (!width) {
    width = 32
  }

  if (!height) {
    height = 32
  }

  return (
    <Link to={link!} className={"nav-item home-item"}>
      <div className={`nav-image ${className}`}>
        <img src={image} alt="" width={width + "px"} height={height + "px"}/>
      </div>
      <span style={{fontSize: "9pt",color:"#222", lineHeight: "16px"}}>{title}</span>
    </Link>
  );
};

export default NavigationItem;