import React from 'react';
import {Link, useLocation} from "react-router-dom";

interface Props {
  title: string,
  link: string,
  icon: React.ReactNode
}

const Tab = (props: Props) => {
  let location = useLocation()
  return (
    <li>
      <Link to={props.link} className={props.link === location.pathname?"active":""}>
        {props.icon}
        <span style={{fontSize:"10pt"}}>{props.title}</span>
      </Link>
    </li>
  );
};

export default Tab;