import React, {useEffect, useRef, useState} from 'react';
import "./plate.css"
import useAppContext from "../../../state/useAppContext";
import {IPlate} from "./IPlate";
import {getOnlyNumbers, isNumeric} from "../../../commons/number";
import CharDialog from "./CharDialog";
import FlagSign from "./FlagSign";
import Skeleton from "react-loading-skeleton";

interface Props {
  onDone?: Function,
  onUnDone?: Function,
  value?: IPlate | null|undefined,
  readonly?: boolean,
  styles?: {},
  loadingMode: boolean
}

const Plate = (props: Props) => {
    const {state} = useAppContext()

    let [middleTemp, setMiddleTemp] = useState(false);

    let readOnly = props.readonly ? props.readonly : false

    let [left, setLeft] = useState("")
    let [right, setRight] = useState("")
    let [middle, setMiddle] = useState("")
    let [char, setChar] = useState("")

    let [showDialog, setShowDialog] = useState(false)

    let leftRef: any = useRef()
    let middleRef: any = useRef()
    let rightRef: any = useRef()
    let charRef: any = useRef()


    const leftKeyUp = (event: any) => {
      if (readOnly) return;
      if (event.key.toLowerCase() === "backspace") return;
      if (event.target.value.length === 2) {
        // checkDone()
        setShowDialog(true)
      }
    }


    const done = () => {
      if (readOnly) return;
      if (props.onDone) {
        if (!leftRef.current.value) {
          return;
        }

        let l = leftRef.current.value
        let m = middleRef.current.value
        let r = rightRef.current.value

        try {
          check()
          let plate: IPlate = {
            left: l,
            middle: m,
            right: r,
            char: charRef.current.innerText
          }

          props.onDone(plate)
        } catch (e:any) {
          // props.onUnDone!()
        }


      }
    }


    let check = () => {
      if (leftRef.current.value.length !== 2) throw new Error("left error")
      if (middleRef.current.value.length !== 3) throw new Error("middle error")
      if (rightRef.current.value.length !== 2) throw new Error("right error")
      if (charRef.current.innerText.length < 1) throw new Error("char error")
    }


    const checkDone = () => {
      if (readOnly) return;
      setTimeout(function () {
        try {
          check()
          done()
        } catch (e: any) {
          console.log(e.message)
          props.onUnDone!()
        }
      }, 20)
    }


    const rightKeyUp = (event: any) => {
      if (readOnly) return;
      if (event.key.toLowerCase() === "backspace") {
        if (event.target.value.length === 0) {
          setRight("")
          middleRef.current.focus()
        }
      }
    }


    const initShowDialog = () => {
      if (readOnly) return;
      setShowDialog(true)
    }


    const middleKeyUp = (event: any) => {
      if (event.key.toLowerCase() === "backspace") {
        if (event.target.value.length === 0) {
          setMiddle("")
          if (middleTemp) {
            leftRef.current.focus()
            setChar("")
            return;
          } else {
            setMiddleTemp(true)
          }
        }
      }
      if (event.target.value.length === 3) rightRef.current.focus()
    }


    const initLeft = (e: any) => {
      if (readOnly) return;
      let value = e.target.value
      if (!((value + "").trim() === "")) if (!isNumeric(value)) return;
      if ((value + "").includes("0")) return;
      value = getOnlyNumbers(value)
      if (value.length === 3) return
      setLeft(value)
    }

    const initMiddle = (e: any) => {
      if (readOnly) return;
      let value = e.target.value
      if (!((value + "").trim() === "")) if (!isNumeric(value)) return;
      if ((value + "").includes("0")) return;
      value = getOnlyNumbers(value)
      if (value.length >= 4) return
      setMiddle(value)
    }


    useEffect(() => {
      if (props.value) {
        setLeft(props.value.left)
        setMiddle(props.value.middle)
        setRight(props.value.right)
        setChar(props.value.char)
      }

      document.addEventListener("keyup", () => {
        if (readOnly) return;
        checkDone()
      })
    }, [state.isLoaded, props.value])


    const initRight = (e: any) => {
      if (readOnly) return;
      let value = e.target.value
      if (!((value + "").trim() === "")) if (!isNumeric(value)) return;
      if (value.length === 1 && (value + "").includes("0")) return;

      value = getOnlyNumbers(value)

      if (value.length === 3) return
      setRight(value)
      checkDone()
    }

    const dialogClicked = (event: any) => {
      if (readOnly) return;
      setChar(event.target.innerHTML)
      setShowDialog(false)
      middleRef.current.focus()
      checkDone()
    }

    // let _middle = toPersianNumber(middle)
    // let _right = toPersianNumber(right)
    // let _left = toPersianNumber(left)


    return (
      <div className={"plate"} style={{...props.styles}}>
        {!props.loadingMode && <>
          <CharDialog showDialog={showDialog} setShowDialog={setShowDialog} dialogClicked={dialogClicked}/>
          <FlagSign/>

          <div className={"inpContainer"}>
            <input type="text" className={"noBorder"} value={left} onChange={initLeft} ref={leftRef} onKeyUp={leftKeyUp}/>

            {left.length == 0 && <>
              <span className={"dash1"}></span>
              <span className={"dash2"}></span>
            </>}
          </div>


          <span className={`char`} onClick={initShowDialog} ref={charRef}>
          <span style={{position: "relative", left: "4px", fontSize: "16px"}}>{char}</span>
          <svg className={"dash4Caret"} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" width="20" height="20" fill="#888">
            <path d="M137.4 374.6c12.5 12.5 32.8 12.5 45.3 0l128-128c9.2-9.2 11.9-22.9 6.9-34.9s-16.6-19.8-29.6-19.8L32 192c-12.9 0-24.6 7.8-29.6 19.8s-2.2 25.7 6.9 34.9l128 128z"/>
          </svg>
        </span>

          {char.length === 0 && <>
            <span className={"dash5"}></span>
          </>
          }


          <div className={"inpContainer2"}>
            <input type="text" className={"noBorder"} value={middle} onChange={initMiddle} ref={middleRef} onKeyUp={middleKeyUp}/>
            {middle.length == 0 && <>
              <span className={"dash1"}></span>
              <span className={"dash2"}></span>
              <span className={"dash3"}></span>
            </>}
          </div>

          <div className={"divider"}>
            <div className={"inpContainer3"}>
              <input type="text" className={"noBorder"} value={right} onChange={initRight} ref={rightRef} onKeyUp={rightKeyUp}/>
              {right.length === 0 && <>
                <span className={"dash1"}></span>
                <span className={"dash2"}></span>
              </>}
            </div>
          </div>

          <div className={"country"}>
            ایران
          </div>

        </>}

        {props.loadingMode && <>
          <FlagSign/>

          <div style={{display: "flex"}}>
            <div style={{display: "flex", alignItems: "center", paddingLeft: "12px"}}>
              <Skeleton width={16}/>
              &nbsp;
              <Skeleton width={16}/>
            </div>

            <div style={{display: "flex", alignItems: "center", paddingLeft: "16px"}}>
              <Skeleton width={16}/>
              &nbsp;
              <Skeleton width={16}/>
            </div>

            <div style={{display: "flex", alignItems: "center", paddingLeft: "16px"}}>
              <Skeleton width={16}/>
              &nbsp;
              <Skeleton width={16}/>
              &nbsp;
              <Skeleton width={16}/>
            </div>

            <div style={{width: "1px", background: "#eee", position: "relative", left: "8px"}}></div>

            <div style={{display: "flex", alignItems: "center", paddingLeft: "20px"}}>
              <Skeleton width={16}/>
              &nbsp;
              <Skeleton width={16}/>
            </div>


          </div>

        </>}
      </div>
    );
  }
;

export default Plate;
