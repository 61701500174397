import React, {useEffect, useState} from 'react';
import InquiryLayout from "../../../Layouts/Inquiry/InquiryLayout";
import {useLocation, useNavigate, useParams, useSearchParams} from "react-router-dom";
import {webRequest} from "../../../../Services/apiService";
import {validateAuth, validateResponse} from "../../../../Infrastructure/Api/ValidateResponse";
import xToast from "../../../../commons/xToast";

import "./style.css"


const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};


const CarLicenceCallback = () => {
    const query = useQuery();

    let [loaded, setLoaded] = useState(false)
    let navigate = useNavigate()
    let [response, setResponse] = useState<any>(undefined)


    useEffect(() => {
      const id = query.get('id');
      webRequest({
        auth: true,
        path: `/car-licence/cast`,
        data: {
          id
        }
      }).then(response => {
        setLoaded(true)
        try {
          validateAuth(response)
          validateResponse(response)

          setResponse(response.payload)


        } catch (e: any) {
          setLoaded(true)
          if (e.message == "login") {
            navigate("/login?back=/inquiry/car-licence-inquiry/callback?id=" + id)
            return;
          }
          xToast.error(e.message)
          setTimeout(function () {
            xToast.dismiss()
          }, 3000)
        }
      })
    }, [])


    return (
      <InquiryLayout>
        <div style={{padding: "0 32px"}}>
          {response && <div>
            <h2 style={{textAlign: "center", fontSize: "16px"}}>{response.description}</h2>

            {response.licenses.map((row: any, index: number) => {
              return <div key={index}>
                <br/>
                <br/>
                <>
                  {Object.keys(row).map((key: any, index2: number) => {
                    return <div className={`row${index2 % 2 == 0 ? "1" : "2"}`} key={index2}>
                      <div>{key}</div>
                      &nbsp;
                      :
                      &nbsp;
                      <div style={{fontWeight: "bold"}}>{row[key]}</div>
                    </div>
                  })}

                </>
              </div>
            })}

          </div>}
        </div>
      </InquiryLayout>
    );
  }
;

export default CarLicenceCallback;