import React, {useState} from 'react';
import Skeleton from "react-loading-skeleton";
import HtmlText from "../../../Ui/HtmlText";
import LongDescription from "./LongDescription";

const Faqs = ({records}: { records: undefined | [] }) => {

  let [active, setActive] = useState(-1);


  const switchActive = (index: number) => {
    if (active == index) {
      setActive(-1)
    } else {
      setActive(index)
    }
  }

  return (
    <div style={{padding: "0 16px"}}>

      <div className={"tac"} style={{fontFamily: "main-font-bold", fontSize: 20, marginBottom: "8px"}}>
        سوالات متداول شما
      </div>

      {!records && <div>
        <Skeleton width={"100%"} style={{borderRadius: "12px", marginTop: "8px"}} height={72}/>
        <Skeleton width={"100%"} style={{borderRadius: "12px", marginTop: "8px"}} height={72}/>
        <Skeleton width={"100%"} style={{borderRadius: "12px", marginTop: "8px"}} height={72}/>
        <Skeleton width={"100%"} style={{borderRadius: "12px", marginTop: "8px"}} height={72}/>
        <Skeleton width={"100%"} style={{borderRadius: "12px", marginTop: "8px"}} height={72}/>
        <Skeleton width={"100%"} style={{borderRadius: "12px", marginTop: "8px"}} height={72}/>
      </div>}


      {records && <div>
        {records.map((row: any, index: number) => {
          let isOpen = active == index;
          return <div key={index} className={"faq-row"} onClick={() => switchActive(index)}>
            <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
              <div>
                {row.title}
              </div>

              {isOpen && <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" width={16} fill={"#000"}>
                <path d="M192 160c1.1 0 3.969 .7344 5.531 2.219l151.1 144c3.187 3.047 3.312 8.125 .25 11.31c-3.094 3.188-8.156 3.281-11.31 .25L192 179.1L45.53 317.8c-3.219 3.031-8.281 2.938-11.31-.25c-3.062-3.188-2.937-8.266 .25-11.31l151.1-144C188 160.7 190 160 192 160z"/>
              </svg>}

              {!isOpen && <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" width={16} fill={"#000"}>
                <path d="M191.1 352c-1.1 0-3.969-.7344-5.531-2.219L34.47 205.8c-3.187-3.047-3.312-8.125-.25-11.31c3.094-3.188 8.156-3.281 11.31-.25l146.5 138.7l146.5-138.7c3.219-3.031 8.281-2.938 11.31 .25c3.062 3.188 2.937 8.266-.25 11.31l-151.1 144C195.1 351.3 193.1 352 191.1 352z"/>
              </svg>}
            </div>

            {isOpen &&<div className={"mt-5 faq-desc"}>
               <HtmlText text={row.description}/>
            </div>}


          </div>

        })}
      </div>}


    </div>
  );
};

export default Faqs;