import React from 'react';

interface Props {
  width: number,
  height: number,
  color: string,
  onClick: Function
}

const BackIcon = (props: Props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill={props.color} width={props.width} height={props.height} onClick={() => props.onClick()}>
      <path d="M509.7 261.7c3.125-3.125 3.125-8.188 0-11.31l-152-152C356.1 96.78 354.1 95.1 352 95.1s-4.094 .7813-5.656 2.344c-3.125 3.125-3.125 8.188 0 11.31l138.3 138.3H8c-4.406 0-8 3.578-8 8C0 260.4 3.594 263.1 8 263.1h476.7l-138.3 138.3c-3.125 3.125-3.125 8.188 0 11.31s8.188 3.125 11.31 0L509.7 261.7z"/>
    </svg>
  );
};

export default BackIcon;