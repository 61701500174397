import React, {useEffect, useState} from 'react';
import MainLayout from "../../Layouts/MainLayout";

import "./HomePage.css"
import Slider, {Slide} from "./Slider";
import Blog, {ILiteArticle} from "./Blog";
import Icons from "./Icons";
import {webRequest} from "../../../Services/apiService";
import {validateResponse} from "../../../Infrastructure/Api/ValidateResponse";

import 'react-loading-skeleton/dist/skeleton.css'
import HomeSkeleton from "./HomeSkeleton";
import Stories from "./Stories";

const HomePage = () => {

  let [articles, setArticles] = useState<ILiteArticle[]>([])
  let [slides, setSlides] = useState<Slide[]>([])
  let [loaded, setLoaded] = useState(false)
  let [stories, setStories] = useState([])

  useEffect(() => {

    try {
      let result = window.localStorage.getItem("ARTICLES")
      let slides = window.localStorage.getItem("SLIDES")
      let stories = window.localStorage.getItem("STORIES")
      if (typeof result == "string" && typeof slides == "string") {
        setArticles(JSON.parse(result))
        setSlides(JSON.parse(slides))
        setLoaded(true)
      }
      if (stories) {
        setStories(JSON.parse(stories))
      }

    } catch (e) {
    }

    webRequest({
      path: "/pages/home",
    }).then(response => {
      try {
        validateResponse(response)
        setArticles(response.payload.blog)
        setSlides(response.payload.slides)
        setStories(response.payload.stories)

        window.localStorage.setItem("ARTICLES", JSON.stringify(response.payload.blog))
        window.localStorage.setItem("SLIDES", JSON.stringify(response.payload.slides))
        window.localStorage.setItem("STORIES", JSON.stringify(response.payload.stories))

        setLoaded(true)

      } catch (e: any) {
        setArticles([])
        setSlides([])
        setLoaded(true)
      }
    })
  }, []);





  return (
    <MainLayout>
      <Stories stories={stories}/>
      {loaded && <Slider slides={slides}/>}
      {loaded && <Icons/>}
      {loaded && <Blog articles={articles}/>}
      <br/>
      <br/>
      {!loaded && <HomeSkeleton/>}
    </MainLayout>
  );
};

export default HomePage;
