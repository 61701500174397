import React from 'react';

const MenuIcon = ({setActive}: { setActive: any }) => {


  const init = () => {
    setActive(true)
  }

  return (
    <div className={"nav-icon"} onClick={() => init()}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="24" height="24">
        <path
          d="M16 72C16 58.75 26.75 48 40 48H88C101.3 48 112 58.75 112 72V120C112 133.3 101.3 144 88 144H40C26.75 144 16 133.3 16 120V72zM32 72V120C32 124.4 35.58 128 40 128H88C92.42 128 96 124.4 96 120V72C96 67.58 92.42 64 88 64H40C35.58 64 32 67.58 32 72zM496 88C500.4 88 504 91.58 504 96C504 100.4 500.4 104 496 104H176C171.6 104 168 100.4 168 96C168 91.58 171.6 88 176 88H496zM496 248C500.4 248 504 251.6 504 256C504 260.4 500.4 264 496 264H176C171.6 264 168 260.4 168 256C168 251.6 171.6 248 176 248H496zM496 408C500.4 408 504 411.6 504 416C504 420.4 500.4 424 496 424H176C171.6 424 168 420.4 168 416C168 411.6 171.6 408 176 408H496zM88 208C101.3 208 112 218.7 112 232V280C112 293.3 101.3 304 88 304H40C26.75 304 16 293.3 16 280V232C16 218.7 26.75 208 40 208H88zM88 224H40C35.58 224 32 227.6 32 232V280C32 284.4 35.58 288 40 288H88C92.42 288 96 284.4 96 280V232C96 227.6 92.42 224 88 224zM16 392C16 378.7 26.75 368 40 368H88C101.3 368 112 378.7 112 392V440C112 453.3 101.3 464 88 464H40C26.75 464 16 453.3 16 440V392zM32 392V440C32 444.4 35.58 448 40 448H88C92.42 448 96 444.4 96 440V392C96 387.6 92.42 384 88 384H40C35.58 384 32 387.6 32 392z"/>
      </svg>
    </div>
  );
};

export default MenuIcon;