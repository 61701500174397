import React from 'react';

const TitleBox = () => {
  return (
    <>
      <h1 className={"fih1"}>استعلام پلاک های فعال</h1>
      <p className={"type-info"}>
        هزینه نمایش جزئیات پلاک های فعال 4٬000 تومان است و ماشین نو دخل و تصرفی در تعیین این هزینه ندارد.
      </p>
    </>
  );
};

export default TitleBox;