import axios from 'axios';
import { appConfig } from "../config";
import {Auth} from "../Infrastructure/Auth/Auth";

class InitProps {
  data?: any = {};
  method?: any = "POST";
  path: string = "/";
  auth?: boolean
}

class ApiService {
  async request(props: InitProps) {
    if (!props.path.startsWith("/")) props.path = "/" + props.path
    if (!props.method) props.method = "POST"


    let auth=null
    if (props.auth){
      auth = Auth.get();
    }

    let authData: any
    if (auth && auth.user) {
      authData = `Bearer ${auth?.authorisation?.token}`;
    } else {
      authData = null
    }

    try {
      const response = await axios({
        url: appConfig.api + appConfig.endPoint + props.path,

        method: props.method,
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': authData
        },
        data: props.data
      });
      return response.data;
    } catch (error) {
      // Handle error appropriately here
      console.error('API request error:', error);
      throw error;
    }
  }
}

let apiResponse = new ApiService();

export const webRequest = async (props: InitProps) => {
  return await apiResponse.request(props);
}

export default apiResponse;
