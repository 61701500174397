import React, {useEffect, useState} from 'react';
import Tab from "./Tab";
import HomeIcon from "./HomeIcon";
import {useLocation} from "react-router-dom";
import CarIcon from "./CarIcon";
import useAppContext from "../../../state/useAppContext";





const MainViewPager = () => {
  let [active, setActive] = useState("/")
  let location = useLocation();

  useEffect(() => {
    let pathName = location.pathname
    setActive(pathName)
  }, [location]);

  let activeColor = "#000"
  let inactiveColor = "#888"





  return (
    <ul className={"h-navigation"}>
      <Tab title={"خانه"} link={"/"} icon={<HomeIcon color={active === "/" ? activeColor : inactiveColor}/>}/>
      <Tab title={"خودرو های من"} link={"/cars"} icon={<CarIcon color={active === "/cars" ? activeColor : inactiveColor}/>}/>
    </ul>
  );

};

export default MainViewPager;