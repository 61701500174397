import React, {useLayoutEffect, useState} from 'react';
import "./MainLayout.css"
import MainViewPager from "./ViewPager/MainViewPager";
import MainFooter from "./Footer/MainFooter";
import useAppContext from "../../state/useAppContext";
import {isPwa} from "../../commons";

const MainLayout = ({children, header}: { children: React.ReactNode, header?: boolean }) => {


  let showHeader=false
  if (header===undefined){
    showHeader=true
  }else{
    showHeader=header
  }

  let {state} = useAppContext()

  let [showIcon, setShowIcon] = useState(false)
  let [canInstall, setCanInstall] = useState(false)
  let [defferedPrompt, setDefferedPrompt] = useState<any>()


  useLayoutEffect(() => {
    if (!state.isLoaded) {
      return;
    }

    if (isPwa()) {
      return;
    }

    window.addEventListener('beforeinstallprompt', (event: any) => {
      setCanInstall(true)
      event.preventDefault();
      initShowIcon(true)
      setDefferedPrompt(event)
    });

    initShowIcon(canInstall)

  }, [state.isLoaded, state.pwa.showInstallPopup]);

  const initShowIcon = (canInstall: boolean) => {
    if (isPwa()) return
    if (canInstall) {
      setShowIcon(!state.pwa.showInstallPopup)
    }
  }


  const install = () => {

    if (defferedPrompt) {
      defferedPrompt.prompt();
      defferedPrompt.userChoice.then((choice: any) => {
        if (choice.outcome === 'accepted') {
        }
        defferedPrompt = null;
      })
    }
  }


  return (
    <>
      {showHeader && <header id={"main-header"} style={{position: "relative"}}>
        <div className={"header-top tac"}>
          <a href="https://mashinno.com" style={{marginTop:"-20px"}}>
            <img className={"logo"} src="/assets/app/logo.png" alt=""/>
          </a>
          {showIcon &&
            <img src="/assets/app/install.png" onClick={install}
                 style={{width: "40px", cursor: "pointer", position: "absolute", left: "8px"}} alt=""/>}
        </div>
        <MainViewPager/>
      </header>
      }
      <main style={{paddingBottom: "120px"}}>
        {children}
      </main>
      <MainFooter/>
    </>

  );
};

export default MainLayout;