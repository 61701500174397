import React, {useEffect, useRef, useState} from 'react';
import {webRequest} from "../../../Services/apiService";
import {useLocation, useNavigate} from "react-router-dom";

const Slug = () => {


  let timerRef: any = useRef(null);
  const location = useLocation();
  let [loaded, setLoaded] = useState(false);
  let [story, setStory] = useState<any | undefined>(undefined);
  const navigate = useNavigate();
  let result: string = location.pathname.replace("/story/", "")


  let clicked = () => {
    navigate(-1)
  }

  useEffect(() => {

    let clicked = false;


    document.ontouchstart = function (event: any) {
      event.preventDefault()
      event.stopPropagation()

      if (event.target.tagName.toLowerCase() === "img") {
        clicked = true;
      }
    }
    document.oncontextmenu = function (event: any) {
      event.preventDefault()
      event.stopPropagation()

      if (event.target.tagName.toLowerCase() === "img") {
        clicked = true;
      }
    }

    document.ontouchend = function (event: any) {
      if (event.target.tagName.toLowerCase() === "img") {
        clicked = false;
      }
    }

    document.onmouseover = function (event: any) {
      if (event.target.tagName.toLowerCase() === "img") {
        clicked = true;
      }
    }

    document.onmouseout = function (event: any) {
      if (event.target.tagName.toLowerCase() === "img") {
        clicked = false;
      }
    }

    try {
      let stories: any = window.localStorage.getItem("stories")
      if (stories) {
        stories = JSON.parse(stories)
        setStory(stories[result])
      }
    } catch (e) {
    }


    webRequest({
      path: "/story",
      method: "POST",
      data: {
        slug: result,
      }
    }).then(response => {
      setLoaded(true)
      clicked=false
      setStory(response.payload)

      let stories: any = window.localStorage.getItem("stories")
      if (!stories) {
        stories = {}
      } else {
        stories = JSON.parse(stories)
      }

      stories[result] = response.payload;
      window.localStorage.setItem("stories", JSON.stringify(stories))
    })



    let width = 1;
    let interval = setInterval(function () {

      if (clicked || !loaded) {
        return;
      }

      let windowsWidth = window.innerWidth;
      if (windowsWidth > 500) {
        windowsWidth = 500
        windowsWidth -= 8;
      } else {
        windowsWidth -= 20;
      }


      if (!timerRef.current) return;

      timerRef.current.style.width = width + "px"

      if (width > windowsWidth) {
        clearInterval(interval)
        imageClicked()
        width = 1;
        timerRef.current.style.width = width + "px"
      }
      width++
    }, 10)


    return () => {
      clearInterval(interval);
    };

  }, [location.pathname,loaded]);


  let imageClicked = () => {

    setLoaded(false)
    try {

      if (story.hasNext) {
        navigate("/story/" + story.nextSlug, {
          replace: true
        })
      } else {
        navigate(-1)
      }
    } catch (e) {

    }

  }

  try {

  } catch (err: any) {
    return <></>
  }

  if (!story) {
    return <div className={"story-loader"} style={{
      width: '100%',
      height: '100vh',
      background: "#111",
      lineHeight: "100vh",
      textAlign: "center",
      color: "#fff"
    }}>
      در حال بارگیری ...
    </div>
  }



  return (
    <div className={"story-viewer"}>

      <div className={"story-timer-container"}>
        <div className={"story-timer-placeholder"}></div>
        <div className={"story-timer"} ref={timerRef}></div>
      </div>

      <div className={"type-container"}>
        <span className={"close"} onClick={clicked}>
          <i className={"bi bi-x-lg fs-3 text-white"}>X</i>
        </span>
        <picture>
          <source srcSet={"https://mashinno.com/"+story.path}/>
          <img src={"https://mashinno.com"+story.path} style={{maxHeight: "100%"}} alt={story.title} onClick={imageClicked}/>
        </picture>
      </div>
    </div>
  );
};

export default Slug;

