import {Auth} from "../Infrastructure/Auth/Auth";

export const getAvatar = () => {
  try {
    let auth = Auth.get()
    let defaultAvatar = "https://mashinno.com/assets/images/app/default_avatar.png"
    if (!auth) return defaultAvatar
    if (!auth.user?.avatar) return defaultAvatar;
    let prefix = "https://mashinno.com/x-api/files/uploads/avatars/"
    return prefix + auth.user.avatar
  } catch (error) {
    return "https://mashinno.com/assets/images/app/default_avatar.png"
  }
}