import React, {useEffect, useState} from 'react';
import "./Login.css"
import MobileMode from "./MobileMode";
import CodeMode from "./CodeMode";
import {webRequest} from "../../../Services/apiService";
import {validateResponse} from "../../../Infrastructure/Api/ValidateResponse";
import xToast from "../../../commons/xToast";
import {CookieManager} from "../../../Infrastructure/StorageManager/CookieManager";
import {Auth} from "../../../Infrastructure/Auth/Auth";
import {useNavigate, useParams, useSearchParams} from 'react-router-dom';

const LoginPage = () => {
  const navigate = useNavigate();
  let params = useParams()
  const [searchParams, setSearchParams] = useSearchParams();
  let [back, setBack] = useState<any | undefined>(undefined);

  let [mobile, setMobile] = useState("");
  let [code, setCode] = useState("");
  let [loading, setLoading] = useState(false);
  let [mode, setMode] = useState("mobile")
  let [time, setTime] = useState(0)


  useEffect(() => {
    document.title = "ماشین نو | ورود به حساب کاربری";
    setBack(searchParams.get("back"))
    if (Auth.check()) {
      navigate(-1);
    }
  }, []);


  const loginClicked = () => {
    setLoading(true)
    webRequest({
      path: "/auth/sendCode",
      data: {mobile: mobile},
    }).then(response => {
      setLoading(false)
      try {
        validateResponse(response)
        setMode("code")
        setLoading(false)
        setTime(response.payload.time)
      } catch (e: any) {
        xToast.error(e.message)
      }
    })
  }


  let sendCode = () => {
    setMode("mobile")
    setCode("")
  }


  const confirmCode = () => {
    setLoading(true)
    webRequest({
      path: "/auth/verify",
      data: {mobile, code}
    }).then((response: any) => {
      setLoading(false)

      try {
        validateResponse(response)

        if (response.payload) {
          if (response.payload.authorisation) {
            CookieManager.set("auth", JSON.stringify(response.payload), 100)

            if (back) {
              navigate(back)
            } else {
              navigate("/")
            }

          } else {
            xToast.error(response.message)
          }
        } else {
          xToast.error(response.message)
        }
      } catch (e: any) {
        xToast.error(e.message)
      }
    })

  }

  const sendCodeAgain = () => {
    sendCode()
    setCode("")
    loginClicked()
  }


  return (
    <section className={"auth-container"}>
      <div className={"x-container"}>
        {mode == "mobile" && <MobileMode onLoginClicked={loginClicked} mobile={mobile} setMobile={setMobile} loading={loading}/>}
        {mode == "code" && <CodeMode setTime={setTime} code={code} setCode={setCode} confirmCode={confirmCode} loading={loading} mobile={mobile} time={time} sendCodeAgain={sendCodeAgain}/>}
      </div>
    </section>
  );
};

export default LoginPage;