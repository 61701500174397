import React, {useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";
import {webRequest} from "../../../../Services/apiService";
import {validateAuth, validateResponse} from "../../../../Infrastructure/Api/ValidateResponse";
import xToast from "../../../../commons/xToast";
import {Payment} from "../../../../commons/Payment";
import InquiryLayout from "../../../Layouts/Inquiry/InquiryLayout";
import Dialog from "../../../Layouts/Dialog/Dialog";
import LoadingButton from "../../../Ui/Button/LoadingButton";
import Input from "../../../Ui/Input/Input";
import TitleBox from "./TitleBox";
import {IPlate, IPlateDetail} from "../../../Ui/Plate/IPlate";
import {CookieManager} from "../../../../Infrastructure/StorageManager/CookieManager";
import CodeMode from "../../Login/CodeMode";
import Skeleton from "react-loading-skeleton";
import Faqs from "../VehicleViolationInquiry/Faqs";
import ReviewBeforePayment from "./ReviewBeforePayment";

const LicenseNegativeScorePage = () => {
  let [response, setResponse] = useState<any>(undefined)
  let [loading, setLoading] = useState(false);

  let [mobile, setMobile] = useState("");
  let [number, setNumber] = useState("");
  let [nationalCode, setNationalCode] = useState("");

  let [showOtpDialog, setShowOtpDialog] = useState(false);


  let [plate, setPlate] = useState<IPlateDetail | undefined>(undefined);
  let [showInquiryTypeDialog, setShowInquiryTypeDialog] = useState(false);
  let [wallet, setWallet] = useState(false);
  let [inquiryPrice, setInquiryPrice] = useState(undefined);
  let [gatewayPaymentLoading, setGatewayPaymentLoading] = useState(false);
  let [walletPaymentLoading, setWalletPaymentLoading] = useState(false);

  let [miniPlate, setMiniPlate] = useState<IPlate | undefined>(undefined);
  let navigate = useNavigate()


  useEffect(() => {
    document.title = "استعلام و پرداخت خلافی خودرو";
  }, []);


  useEffect(() => {
    webRequest({
      path: '/pages/carLicenceNegativeScore',
      auth: true
    }).then(response => {
      setResponse(response.payload)


      try {
        // setPlate(response.payload.plate.plate.plate)
      } catch (e: any) {

      }
    })
  }, []);


  const inquiry = (type = null) => {
    if (type == null) {
      setLoading(true);
    }
    webRequest({
      path: "/license-negative-score/check",
      auth: true,
      data: {
        pluque: miniPlate,
        mobile,
        nationCode:nationalCode,
        number,
        type
      }
    }).then(response => {
      setWalletPaymentLoading(false)
      setGatewayPaymentLoading(false)

      try {
        setLoading(false)
        validateAuth(response)
        validateResponse(response)

        if (response.payload.type == "sentOtp") {
          setTime(response.payload.auth.time)
          if (response.payload.auth.status == "sentBefore") {
            xToast.warning(response.payload.auth.message)
            setTimeout(function () {
              xToast.dismiss()
            }, 3000)
          } else {
            xToast.success(response.payload.auth.message)
            setTimeout(function () {
              xToast.dismiss()
            }, 3000)
          }
          setShowOtpDialog(true)
          return;
        }


        if (response.payload.type == "showTypeDialog") {
          setWallet(response.payload.wallet)
          setInquiryPrice(response.payload.inquiryPrice)
          setShowInquiryTypeDialog(true)

          setWalletPaymentLoading(false)
          setGatewayPaymentLoading(false)
        }


        if (response.payload.type === "gateway") {
          Payment.start(response.payload.data)
        }

        if (response.payload.type == "success") {
          navigate("/inquiry/license-negative-score/" + response.payload.id)
          xToast.success("استعلام نمره منفی گواهینامه با موفقیت انجام شد")
          return;
        }


      } catch (e: any) {
        if (e.message == "login") {
          navigate("/login?back=/inquiry/license-negative-score")
          return;
        }
        xToast.error(e.message)
      }
    })
  }


  const onPlateChanged = (plates: IPlateDetail[], plate: IPlateDetail) => {
    setResponse({
      ...response,
      plates: plates,
    })
    setPlate(plate)
    setMiniPlate(plate.plate)
  }


  const onPlateStatusChanged = (done: boolean, plate: IPlate) => {
    if (done) setMiniPlate(plate)
    else setMiniPlate(undefined)
  }

  let [code, setCode] = useState("")
  let [codeLoading, setCodeLoading] = useState(false)
  let [time, setTime] = useState(0)

  const sendCodeAgain = () => {
    inquiry()
    setLoading(false)
    setShowOtpDialog(true)
  }

  const confirmCode = () => {
    setCodeLoading(true)
    webRequest({
      path: "/auth/verify",
      data: {mobile, code}
    }).then((response: any) => {
      setCodeLoading(false)

      try {
        validateResponse(response)

        if (response.payload) {
          if (response.payload.authorisation) {
            CookieManager.set("auth", JSON.stringify(response.payload), 100)
            setShowOtpDialog(false)

          } else {
            xToast.error(response.message)
          }
        } else {
          xToast.error(response.message)
        }
      } catch (e: any) {
        xToast.error(e.message)
      }
    })

  }


  const pay = (type: any) => {
    if (type == "wallet") {
      setWalletPaymentLoading(true)
    } else {
      setGatewayPaymentLoading(true)
    }
    inquiry(type)
  }


  return (
    <InquiryLayout>


      <Dialog show={showOtpDialog} close={() => setShowOtpDialog(false)} title={"تایید شماره موبایل"} height={350}>
        {showOtpDialog && <CodeMode code={code} setCode={setCode} confirmCode={confirmCode} loading={codeLoading} mobile={mobile} time={time} setTime={setTime} sendCodeAgain={sendCodeAgain}/>}
      </Dialog>


      <ReviewBeforePayment
        gatewayPaymentLoading={gatewayPaymentLoading}
        walletPaymentLoading={walletPaymentLoading}
        inquiryPrice={inquiryPrice}
        setShowReviewDialog={setShowInquiryTypeDialog}
        showReviewDialog={showInquiryTypeDialog}
        pay={pay}
        wallet={wallet}
      />


      <div className="x-card m16">
        <TitleBox/>

        <Input value={mobile} setValue={setMobile} type={"number"} label={"شماره موبایل مالک خودرو"}/>
        <div style={{marginTop: "16px"}}>
          <Input value={number} setValue={setNumber} type={"number"} label={"شماره گواهینامه"}/>
        </div>
        <div style={{marginTop: "16px"}}>
          <Input value={nationalCode} setValue={setNationalCode} type={"number"} label={"کد ملی مالک خودرو"}/>
        </div>
        <div style={{marginTop: "32px"}}>
          <LoadingButton text={"استعلام خلافی خودرو"} clicked={inquiry} type={"success"} loading={loading}/>
        </div>
      </div>


      {!response && <div style={{padding: "8px 16px"}}>
        <Skeleton width={"200px"} height={20}/>
        <Skeleton height={10} style={{marginTop: "16px"}}/>
        <Skeleton height={10}/>
        <Skeleton height={10}/>
        <Skeleton height={10}/>
        <Skeleton height={10}/>
        <Skeleton height={10}/>
        <Skeleton height={10} width={"200px"}/>
        <Skeleton height={10}/>
        <Skeleton height={10}/>
        <Skeleton height={10}/>
        <Skeleton height={10}/>
        <Skeleton height={10}/>
        <Skeleton height={10}/>
        <Skeleton height={10}/>
        <Skeleton height={10}/>
        <Skeleton height={10}/>
        <Skeleton height={10} style={{width: "100px"}}/>
        <Skeleton height={10}/>
      </div>}

      {/*{response && <Description/>}*/}


      <br/>
      {response && <Faqs records={response.faqs}/>}
      <br/>
      {/*<div style={{padding:"0 16px",borderRadius:"12px",overflow:"hidden"}}>*/}
      {/*  <LongDescription/>*/}
      {/*</div>*/}
    </InquiryLayout>
  );
};

export default LicenseNegativeScorePage;