import React, {useEffect} from 'react';
import Skeleton from "react-loading-skeleton";

const ThirdParty = () => {
  let [loaded, setLoaded] = React.useState(false);

  const onLoad = () => {
    setLoaded(true);
  }


  let [width, setWidth] = React.useState("0");
  let [height, setHeight] = React.useState("0");


  let initSize = () => {
    setWidth(window.innerWidth + 'px')
    setHeight((window.innerHeight - 110) + 'px')
    let iframes = document.querySelectorAll('iframe');
    for (let i = 0; i < iframes.length; i++) {
      iframes[i].style.height = window.innerHeight + 'px';
    }

    if (window.innerHeight < 100) {
      setTimeout(function () {
        initSize()
      }, 200)
    }
  }

  useEffect(() => {
    initSize()
  }, []);





  return (
    <>

      {!loaded && <div style={{padding: "16px"}}>
        <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
          <div style={{display: "flex"}}>
            <Skeleton width={48} height={48}/>
            &nbsp;&nbsp;&nbsp;
            <Skeleton width={48} height={48}/>
          </div>
          <div>
            <Skeleton width={140} height={30}/>
          </div>
        </div>

        <div style={{borderTop: "1px solid #eee", paddingTop: "16px", marginTop: "16px"}}>
          <Skeleton width={180} height={20}/>
        </div>

        <div style={{marginTop: "32px"}}>
          <div style={{marginTop: 24}}>
            <Skeleton width={100} height={12}/>
            <Skeleton width={"100%"} height={40}/>
          </div>
          <div style={{marginTop: 24}}>
            <Skeleton width={100} height={12}/>
            <Skeleton width={"100%"} height={40}/>
          </div>
          <div style={{marginTop: 24}}>
            <Skeleton width={100} height={12}/>
            <Skeleton width={"100%"} height={40}/>
          </div>
          <div style={{marginTop: 24}}>
            <Skeleton width={100} height={12}/>
            <Skeleton width={"100%"} height={40}/>
          </div>
          <div style={{marginTop: 24}}>
            <Skeleton width={100} height={12}/>
            <Skeleton width={"100%"} height={40}/>
          </div>
          <div style={{marginTop: 24}}>
            <Skeleton width={100} height={12}/>
            <Skeleton width={"100%"} height={40}/>
          </div>
          <div style={{marginTop: 24}}>
            <Skeleton width={100} height={12}/>
            <Skeleton width={"100%"} height={40}/>
          </div>


          <div style={{marginTop: 24,display:"flex",justifyContent:"flex-end"}}>
            <Skeleton width={100} height={40}/>
          </div>

        </div>


      </div>}


      <iframe
        src="https://mashinno.bimeh.com/thirdparty/planlist"
        frameBorder="0"
        onLoad={onLoad}
        style={{width: width, height: height,display:loaded?'block':'none'}}>
      </iframe>
    </>
  )
};

export default ThirdParty;