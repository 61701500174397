import Dialog from "../../Layouts/Dialog/Dialog";
import {useEffect, useState} from "react";
import {webRequest} from "../../../Services/apiService";
import {validateResponse} from "../../../Infrastructure/Api/ValidateResponse";
import Skeleton from "react-loading-skeleton";
import {CarManager} from "../../../Infrastructure/StorageManager/CarManager";
import useAppContext from "../../../state/useAppContext";


const Loader = () => {


  const Partial = () => {
    return <div style={{display: "block"}}>
      <Skeleton width={"48px"} height={"48px"} style={{display: "inline-block", float: "right"}}/>
      <div style={{position: "relative", top: "-15px"}}>
        <Skeleton width={"calc(100% - 60px)"} style={{display: "inline-block", float: "right", marginRight: "12px"}}/>
        <Skeleton width={"calc(50%)"} style={{display: "inline-block", float: "right", marginRight: "12px"}}/>
      </div>
    </div>
  }


  return (
    <>
      <Partial/>
      <Partial/>
      <Partial/>
      <Partial/>
      <Partial/>
      <Partial/>
    </>
  );
};


const AddCarDialog = ({show, setShow}: { show: boolean, setShow: Function }) => {

  let {state, dispatch} = useAppContext()

  let [brands, setBrands] = useState([])
  let [cars, setCars] = useState([])
  let [loaded, setLoaded] = useState(false)
  let [brandId, setBrandId] = useState(0)
  let [tempCars, setTempCars] = useState([])

  useEffect(() => {
    if (!show) {
      // setCars([])
      setBrandId(0)
      return;
    }

    webRequest({
      path: "/brands"
    }).then(response => {
      validateResponse(response)
      let _brands = response.payload.brands;
      let _cars = response.payload.cars;
      setBrands(_brands)
      setCars(_cars)
      setLoaded(true)
    })
  }, [show]);


  const brandClicked = (brand: any) => {
    setBrandId(brand.id)
    let _cars: any = []
    cars.map((row: any) => {
      if (row.brand_id === brand.id) {
        _cars.push(row)
      }
    })
    setTempCars(_cars)
  }

  const carClicked = (car: any) => {
    CarManager.add(state, dispatch, car)
    setBrandId(0)
    // setCars([])
    setShow(false)
  }


  return (
    <Dialog height={400} title={"افزودن خودرو جدید"} show={show} close={() => setShow(false)}>

      {show && <>

        {!loaded && <>
          <Loader/>
        </>}

        {loaded && <>


          {brandId === 0 && <>
            {brands.map((row: any) => {
              return <div key={row.id} style={{height: "48px", display: "flex", borderBottom: "1px solid #eee", paddingTop: "8px"}} onClick={() => brandClicked(row)}>
                <img src={row.image} alt="" style={{height: "38px", width: "48px"}}/>
                <div style={{lineHeight: "42px", paddingRight: "16px"}}>
                  {row.title}
                </div>
              </div>
            })}
          </>}


          {brandId > 0 && <>
            {tempCars.map((row: any) => {
              return <div key={row.id} style={{height: "54px", display: "flex", borderBottom: "1px solid #eee", paddingTop: "8px"}} onClick={() => carClicked(row)}>
                <img src={row.image} alt="" style={{height: "42px", width: "60px"}}/>
                <div style={{lineHeight: "42px", paddingRight: "16px"}}>
                  {row.name}
                </div>
              </div>
            })}
          </>}


        </>}


      </>}

    </Dialog>
  );
};

export default AddCarDialog;