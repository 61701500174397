import React from 'react';

import "./dialog.css"


interface Props {
  show: boolean
  close: Function,
  children: React.ReactNode,
  title: string,
  height: number
}

const Dialog = (props: Props) => {

  const close = (event: any) => {
    if (event.target.classList.contains("dialog-cover")) {
      props.close()
    }
  }
  return (
    <div dir={"rtl"} className={`dialog-cover ${props.show ? "active" : ""}`} onClick={close}>

      <div className={`dialog ${props.show ? "active" : ""}`} style={{height: `${props.height}px`}}>
        <div className={"dialog-header"}>
          <div style={{color:"#222",fontFamily:"main-font-bold",fontSize:"14px"}}>
            {props.title}
          </div>
          <div className={"close-icon"} onClick={() => props.close()}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" width={16} height={16} fill={"#333"}>
              <path d="M310.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L160 210.7 54.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L114.7 256 9.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 301.3 265.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L205.3 256 310.6 150.6z"/>
            </svg>
          </div>
        </div>
        <div className={"dialog-body"}>
          {props.show && <>
            {props.children}
          </>}
        </div>


      </div>
    </div>
  );
};

export default Dialog;