import React, {useState} from 'react';
import MainLayout from "../../Layouts/MainLayout";
import {webRequest} from "../../../Services/apiService";
import {validateResponse} from "../../../Infrastructure/Api/ValidateResponse";
import "./tl.css"
import xToast from "../../../commons/xToast";
import Input from "../../Ui/Input/Input";
import Textarea from "../../Ui/Input/Textarea";
import LoadingButton from "../../Ui/Button/LoadingButton";

const AboutUsPage = () => {

  let [name, setName] = useState("")
  let [phone, setPhone] = useState("")
  let [type, setType] = useState("")
  let [description, setDescription] = useState("")
  let [sending, setSending] = useState<boolean>(false)

  let clicked = () => {
    setSending(true)
    webRequest({
      data: {
        name, phone, type, description
      },
      path: "/save-partnership"
    }).then(response => {
      try {
        setSending(false)
        validateResponse(response)
        setDescription("")
        setName("")
        setPhone("")
        setType("")
        xToast.success(response.payload.message)
      } catch (e: any) {
        xToast.error(e.message)
      }
    })
  }

  return (
    <MainLayout header={false}>

      <div style={{height: "48px", background: "#f8f8f8", position: "fixed", left: "0", right: "0", top: "0", width: "100%", zIndex: 999, lineHeight: "48px",  borderBottom: "1px solid #eee", display: "flex", alignItems: "center"}}>

        <div className={"b7"} style={{width:"36px",height:"36px",textAlign:"center",borderRadius:"100%",marginRight:"8px"}}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 192 512" width={12} height={20} fill={"#fff"}>
            <path d="M144 80c0 26.5-21.5 48-48 48s-48-21.5-48-48s21.5-48 48-48s48 21.5 48 48zM0 224c0-17.7 14.3-32 32-32H96c17.7 0 32 14.3 32 32V448h32c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H64V256H32c-17.7 0-32-14.3-32-32z"/>
          </svg>
        </div>
        <div style={{marginRight:"8px"}}>
          درباره ماشین نو
        </div>
      </div>

      <div style={{background: "#fff"}}>
        <div className="timeline">
          <div className="outer">
            <div className="card">
              <div className="info">
                <h3 className="title">شروع ماشین نو</h3>
                <p>
                  فرقی نمیکنه ماشینت چی یا کجا باشه، بیمه بخواد یا استعلام، عوارضی باشه
                  یا خلافی، شخصی باشه یا شرکتی، مالک باشی یا راننده، مصرف کننده باشی یا
                  تعمیرکار، توی شهر بزرگ باشی یا دور ترین نقطه جغرافیایی ایران، روز باشه
                  یا شب، وقتی ماشین نو همه‌ی خدماتی که به عنوان یک دارنده خودرو به آن‌ها
                  نیاز دارید رو در ۲۴ ساعت شبانه‌روز فراهم کرده.
                </p>
              </div>
            </div>
            <div className="card">
              <div className="info">
                <h3 className="title1">سال 94</h3>
                <p>
                  داستان ماشین نو از سال ۱۳۹۴ شروع شد. از زمانی که تصمیم گرفتیم برای
                  خرید آنلاین قطعات خودرو و ارائه خدمات در ۲۴ ساعت شبانه‌روز؛ در ۷ روز
                  هفته در دسترس باشیم. روزهای سختی بود چون تامین کالا در فضای آنلاین کار
                  دشواری بود و برای همین در سال ۱۳۹۷ برای تسریع در تامین قطعات در وسعت
                  بالاتر برای خریداران و کمک به وسعت فروش کالا برای فروشندگان، شروع به
                  جذب فروشندگان لوازم یدکی با اعتبار بالا به عنوان فروشنده در ماشین نو
                  کردیم{" "}
                </p>
              </div>
            </div>
            <div className="card">
              <div className="info">
                <h3 className="title2">ادامه سال 97</h3>
                <p>
                  به این ترتیب اولین بازارچه آنلاین تخصصی لوازم یدکی خودرو رو راه اندازی
                  کردیم تا بتونیم با داشتن تولیدکنندگان، بازرگانان و فروشندگان کالا به
                  عنوان فروشنده در پلتفرم ماشین نو تعهد کنیم تا تمامی کالاها را در
                  کمترین زمان به شرط اصالت و تضمین عودت کالا به مشتریان خود عرضه کنیم و
                  سهمی در بازار آنلاین کشور برای فروشندگان سنتی لوازم یدکی و سهولت خرید
                  برای مصرف‌کنندگان ایجاد کنیم.
                </p>
              </div>
            </div>
            <div className="card">
              <div className="info">
                <h3 className="title3">سال 1401</h3>
                <p>
                  بعد از عبور از فراز و نشیب‌ها و جلب اعتماد مشتریان‌مون در سال ۱۴۰۱
                  تصمیم گرفتیم با افزودن خدماتی همچون استعلام و پرداخت خلافی خودرو،
                  استعلام و پرداخت عوارض آزادراهی، استعلام و پرداخت طرح ترافیک، استعلام
                  نمره منفی گواهینامه، خرید آنلاین بیمه‌نامه خودرویی قدمی هرچه مفیدتر در
                  بهبود و ارائه این خدمات برداریم{" "}
                </p>
              </div>
            </div>
            <div className="card">
              <div className="info">
                <h3 className="title4">سال 1402</h3>
                <p>
                  {" "}
                  سپس در سال ۱۴۰۲ به واسطه حمایت و اعتماد شما مشتریان عزیزمون تونستیم
                  اولین سوپر اپ خودرویی کشورمون رو راه اندازی کنیم تا شما عزیزان بتونید
                  با اضافه کردن اپلیکیشن ماشین نو به برنامه‌های موبایلتون در هر زمان و
                  در هر کجا به کالا و یا خدمات نیاز داشتید از اون استفاده کنید.
                </p>
              </div>
            </div>
          </div>
        </div>

      </div>

      <div className="question-survey change-main"
           style={{background: "#fff", margin: "16px", borderRadius: "12px", border: "1px solid #eee"}}>

        <div className='head-question tac'>
          <br/>
          <span className='headque-span'>فرم همکاری</span>
        </div>

        <div className='body-question' style={{padding: '0 24px', width: "calc(100% - 60px)"}}>
          <div className='input-main'>
            <Input value={name} setValue={setName} type={"text"} label={"نام و نام خانوادگی"}/>
          </div>

          <div className='input-main'>
            <Input value={phone} setValue={setPhone} type={"text"} label={"شماره تماس"}/>
          </div>

          <div className='input-main'>
            <Input value={type} setValue={setType} type={"text"} label={"نوع همکاری"}/>
          </div>

          <div className='textarea-main input-main'>
            <Textarea value={description} setValue={setDescription} type={"text"} label={"توضیحات"}/>

          </div>
        </div>
        <br/>
        <br/>
        <div className='tac mt-5' style={{width: "calc(100% - 48px)", margin: "32px auto"}}>
          <LoadingButton text={"ارسال درخواست"} clicked={clicked} loading={sending}/>
        </div>
        <br/>

        <div style={{textAlign: "justify", padding: "0 16px", fontSize: `${14}px`, color: "#444", lineHeight: "25px", borderTop: "1px solid #ddd", paddingTop: "16px"}}>

          <div>
            سیاست‏‌ رعایت حریم خصوصی کاربر:
          </div>
          اطلاعات شخصی کاربران در اپلیکیشن ماشین‌نو کاملا محرمانه خواهد بود. حریم شخصی کاربران حفظ خواهد شد و کاربر باید از هرگونه مشارکت و معاونت در هک کردن هر نوع اطلاعاتی خودداری کند.
          تمامی سفارش‌های کاربران، خدمات و پشتیبانی از طریق نام کاربری و رمز عبور انجام می‌شود و مشتری مسئول حفظ اطلاعات کاربری خواهد بود.
          همچنین بدین وسیله به اطلاع کاربران رسانده می‌شود که ماشین‌نو همانند سایر وب‌سایت‌ها از جمع‌آوری IP و کوکی‌ها استفاده می‌کند و اطلاعات را توسط کوکی (cookie) جمع آوری می کند. کوکی فایلی است که به درخواست یک سایت، توسط مرورگر ایجاد می‌شود و به سایت امکان ذخیره بازدید‌های شما و مناسب‌سازی آنها را فراهم می‌نماید. بسیاری از مرورگرها امکان غیرفعال کردن کوکی را فراهم کرده‌اند، به این منظور می‌توانید راهنمای مرورگرها را مطالعه کنید. لازم به توضیح است که پروتکل، سرور و لایه‌های امنیتی ماشین‌نو و روش‌های مناسب مدیریت داده‌ها حداکثر تلاش را به عمل می‌آورد که اطلاعات کاربران را محافظت و از دسترسی‌های غیرقانونی جلوگیری کند. طبیعتا مسئولیت هرگونه سواستفاده به شخص یا اشخاص متخلف مربوط بوده و ماشین‌نو حق اعتراض و پیگیری را از طریق قانونی بنابر صلاحدید خود محفوظ می‌دارد.
        </div>

      </div>
    </MainLayout>
  );
};

export default AboutUsPage;