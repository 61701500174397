import React, {useEffect, useState} from 'react';
import InquiryLayout from "../../../Layouts/Inquiry/InquiryLayout";
import {IPaginator} from "../../../../commons/IPaginator";
import {webRequest} from "../../../../Services/apiService";
import {Auth} from "../../../../Infrastructure/Auth/Auth";
import {useNavigate} from "react-router-dom";
import Plate from "../../../Ui/Plate/Plate";
import Toman from "../../../Layouts/Ui/Toman";
import {numberFormat} from "../../../../commons/number";
import Skeleton from "react-loading-skeleton";

const InquiriesPage = () => {

  let [data, setData] = useState<undefined | IPaginator<any>>()
  let navigate = useNavigate();


  useEffect(() => {
    if (!Auth.check()) {
      navigate("/login?back=/inquiries")
      return;
    }
    getData()

  }, []);

  let getData = () => {
    webRequest({
      path: "/inquiries/all",
      data: {
        page: 1
      },
      auth: true
    }).then(response => {
      try {
        setData(response.payload)
      } catch (e: any) {

      }

    })
  }


  return (
    <InquiryLayout>
      {!data && <div style={{padding:" 0 16px"}}>
        <Skeleton width={"100%"} height={"110px"} style={{marginBottom: "8px"}}/>
        <Skeleton width={"100%"} height={"110px"} style={{marginBottom: "8px"}}/>
        <Skeleton width={"100%"} height={"110px"} style={{marginBottom: "8px"}}/>
        <Skeleton width={"100%"} height={"110px"} style={{marginBottom: "8px"}}/>
        <Skeleton width={"100%"} height={"110px"} style={{marginBottom: "8px"}}/>
        <Skeleton width={"100%"} height={"110px"} style={{marginBottom: "8px"}}/>
        <Skeleton width={"100%"} height={"110px"} style={{marginBottom: "8px"}}/>
        <Skeleton width={"100%"} height={"110px"} style={{marginBottom: "8px"}}/>
      </div>}

      {data && <div style={{padding: "0 16px"}}>
        {data?.records.map((row, index) => {
          return <div key={index} style={{marginTop: "16px", borderBottom: "1px solid #eee", paddingBottom: "16px"}}>
            <div style={{display: "flex", alignItems: "center"}}>
              <div style={{zoom: "0.7"}}>
                <Plate loadingMode={false} value={row.plate} onDone={() => {
                }} onUnDone={() => {
                }}/>
              </div>
              <div style={{paddingRight: "16px"}}>
                <div style={{fontSize: "14px", fontFamily: "main-font-bold"}}>
                  {row.type == "freeway" && <>عوارض آزادراهی</>}
                </div>
                <div style={{marginTop: "2px"}}>
                  <Toman>{numberFormat(row.amount)}</Toman>
                </div>
                <div style={{fontSize: "14px", fontFamily: "main-font-bold"}}>
                  {row.createdAt}
                </div>
              </div>
            </div>
          </div>
        })}
      </div>}


    </InquiryLayout>
  );
};

export default InquiriesPage;