import React from 'react';
import ButtonLoader from "../Loaders/ButtonLoader";


import "./loadingButton.css"

interface Props {
  text: string,
  clicked: Function,
  loading: boolean,
  type?: "primary" | "success" | "green" | "disabled",
  clickable?: boolean
}


const LoadingButton = (props: Props) => {

  let _type = "primary"
  if (props.type) _type = props.type


  let clickable: any = true
  if (typeof props.clickable !== "undefined") clickable = props.clickable


  const checkClick = () => {
    let clickable: any = true
    if (typeof props.clickable !== "undefined") clickable = props.clickable
    if (clickable){
      if (!props.loading) props.clicked()
    }
  }

  return (
    <>
      <span className={`loading-button ${_type} ${clickable ? "" : "nc"}`} onClick={checkClick}>
          {props.loading && <ButtonLoader/>}
        {!props.loading && props.text}
      </span>
    </>
  );
};

export default LoadingButton;