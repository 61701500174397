export interface IPaymentLinkBuilder {
  action: string,
  inputs?: any,
  method: string
}

export class Payment {
  static start(data: IPaymentLinkBuilder) {
    if (data.method.toLowerCase() === "get") {
      window.location.href = data.action
      return;
    }
    let form = document.createElement("form")
    form.setAttribute("action", data.action)
    form.classList.add("hidden")
    form.setAttribute("method", data.method)
    form.classList.add("hidden")
    if (data.inputs) {
      Object.keys(data.inputs).map(key => {

        let input = document.createElement("input")
        input.setAttribute("name", key)
        input.setAttribute("value", data.inputs[key])
        form.appendChild(input)
      })
    }
    document.createElement("input")
    document.body.appendChild(form)
    form.submit()
  }
}
