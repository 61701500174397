import React, {useEffect} from 'react';
import Skeleton from "react-loading-skeleton";

const ShopPage = () => {
  let [loaded, setLoaded] = React.useState(false);

  let [width, setWidth] = React.useState("0");
  let [height, setHeight] = React.useState("0");

  const onLoad = () => {
    setLoaded(true);
  }


  let initSize = () => {
    setWidth(window.innerWidth + 'px')
    setHeight(window.innerHeight + 'px')
    let iframes = document.querySelectorAll('iframe');
    for (let i = 0; i < iframes.length; i++) {
      iframes[i].style.height = window.innerHeight + 'px';
    }

    if (window.innerHeight < 100) {
      setTimeout(function () {
        initSize()
      }, 200)
    } else {
      setTimeout(function () {
        initSize()
      }, 2000)
    }
  }

  useEffect(() => {
    initSize()
  }, []);


  return (
    <>

      {!loaded && <div style={{position: "fixed", height: height, width: width, overflow: "hidden", maxWidth: "500px", background: "#fff"}}>
        <Skeleton width={"100%"} height={48}/>
        <div style={{display: "flex", justifyContent: "center", alignItems: "center", marginTop: "16px", marginBottom: "16px"}}>
          <Skeleton width={54} height={54} style={{marginLeft: "12px", borderRadius: "100%"}}/>
          <Skeleton width={54} height={54} style={{marginLeft: "12px", borderRadius: "100%"}}/>
          <Skeleton width={54} height={54} style={{marginLeft: "12px", borderRadius: "100%"}}/>
          <Skeleton width={54} height={54} style={{borderRadius: "100%"}}/>
        </div>

        <div style={{display: "flex", marginTop: "16px"}}>
          <Skeleton width={"20px"} height={"200px"} style={{borderBottomLeftRadius: "12px", borderTopLeftRadius: "12px"}}/>
          <div style={{width: "10px"}}></div>
          <div style={{width: "calc(100%  - 60px)"}}>
            <Skeleton height={"200px"} style={{borderRadius: "12px"}}/>
          </div>
          <div style={{width: "10px"}}></div>
          <Skeleton width={"20px"} height={"200px"} style={{borderBottomRightRadius: "12px", borderTopRightRadius: "12px"}}/>
        </div>
        <div style={{marginTop: "16px"}}>
          <Skeleton width={"100%"} height={600}/>
        </div>
      </div>}


      <iframe
        src="https://mashinno.com"
        frameBorder="0"
        onLoad={onLoad}
        style={{width: width, height: height, display: loaded ? 'block' : 'none'}}>
      </iframe>
    </>
  );
};

export default ShopPage;