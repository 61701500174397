import React from 'react';
import Toman from "../../../Layouts/Ui/Toman";
import {numberFormat} from "../../../../commons/number";
import LoadingButton from "../../../Ui/Button/LoadingButton";
import Dialog from "../../../Layouts/Dialog/Dialog";

interface Props {
  showReviewDialog: boolean,
  setShowReviewDialog: Function,
  inquiryPrice: any,
  wallet: any,
  pay: Function,
  gatewayPaymentLoading: boolean
  walletPaymentLoading: boolean
}

const ReviewBeforePayment = (props: Props) => {
  return (
    <>
      <Dialog show={props.showReviewDialog} close={() => props.setShowReviewDialog(false)} title={"بررسی قبل از استعلام"} height={300}>
        {props.showReviewDialog && <div style={{paddingTop: "16px", paddingLeft: "16px", paddingRight: "16px"}}>
          <div>
            <ul style={{listStyle: "none", fontFamily: "main-font-bold", padding: "0",fontSize:"14px"}}>
              <li>نوع درخواست :  استعلام نمره منفی گواهینامه رانندگی</li>
              <li style={{display: "flex", marginTop: "8px",fontSize:"14px"}}>
                <div>مبلغ قابل پرداخت :</div>
                <Toman>{numberFormat(props.inquiryPrice)}</Toman>
              </li>
              <li>
                <li style={{display: "flex", marginTop: "8px",fontSize:"14px"}}>
                  <div>موجودی کیف پول :</div>
                  &nbsp;
                  <Toman>{numberFormat(props.wallet)}</Toman>
                </li>
              </li>
            </ul>
          </div>
          <div style={{fontFamily: "main-font-bold", fontSize: "14px", marginTop: "32px", width: "calc(100% - 32px)", left: "4px", position: "relative"}}>
            پرداخت با :
          </div>
          <div style={{display: "flex", position: "relative", top: "8px", width: "calc(100% - 0px)", margin: "auto", left: "4px"}}>
            <LoadingButton clickable={props.wallet > 0} text={"کیف پول"} type={props.wallet > 0 ? "primary" : "disabled"} clicked={() => props.pay("wallet")} loading={props.walletPaymentLoading}/>
            &nbsp;
            &nbsp;
            &nbsp;
            &nbsp;
            <LoadingButton text={"درگاه پرداخت"} clicked={() => props.pay("gateway")} loading={props.gatewayPaymentLoading}/>
          </div>
        </div>}
      </Dialog>
    </>
  );
};

export default ReviewBeforePayment;