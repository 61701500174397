import React from 'react';
import "./Alert.css"
import IconInfo from "../Icons/IconInfo";

interface Props {
  message: string;
  type: "success" | "warning" | "error"
}

const Alert = (props:Props) => {
  return (
    <div className={`alert alert-${props.type}`}>
      <div style={{display:"flex",alignItems:"center"}}>
        {props.type=="success" && <IconInfo color={"#fff"} width={10} height={10} styles={{borderRadius: "100%", border: "2px solid #fff", padding: "4px", cursor: "pointer"}}/>}
        {props.type=="warning" && <IconInfo color={"#e89848"} width={16} height={10} styles={{borderRadius: "100%", border: "2px solid #e89848", padding: "4px", cursor: "pointer"}}/>}
        <div style={{paddingRight:"8px"}}>
          {props.message}
        </div>
      </div>
    </div>
  );
};

export default Alert;