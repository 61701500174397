import React from 'react';
import {Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/css';


export interface Slide {
  link: string,
  path: string,
  caption: string,
}

const Slider = ({slides}: { slides: Slide[] }) => {
  return (
    <div className={"mt-5"}>

      <Swiper
        spaceBetween={12}
        style={{paddingRight: "20px", paddingLeft: "20px"}}
        slidesPerView={1.003}
        // loop={true}
        centeredSlides={true}
        grabCursor={true}
        onSlideChange={() => {
        }}
        onSwiper={(swiper: any) => {
        }}
      >

        {slides.map((row, index) => {
          return <SwiperSlide key={index}>
            <a href={row.link}>
              {/*<XImage width={undefined} height={undefined} src={row.path} alt={""} className={"home-slide"}/>*/}
              <img src={"https://mashinno.com" + row.path} width={"100%"} alt="" style={{borderRadius: "8px", height: "160px", objectFit: "cover"}}/>
            </a>
          </SwiperSlide>
        })}
      </Swiper>


    </div>
  );
};

export default Slider;