import React, {useEffect, useState} from 'react';
import InquiryLayout from "../../Layouts/Inquiry/InquiryLayout";
import Toman from "../../Layouts/Ui/Toman";
import {isNumeric, numberFormat} from "../../../commons/number";
import LoadingButton from "../../Ui/Button/LoadingButton";
import Dialog from "../../Layouts/Dialog/Dialog";
import Input from "../../Ui/Input/Input";
import xToast from "../../../commons/xToast";
import {webRequest} from "../../../Services/apiService";
import {validateAuth, validateResponse} from "../../../Infrastructure/Api/ValidateResponse";
import {CookieManager} from "../../../Infrastructure/StorageManager/CookieManager";
import {useNavigate} from "react-router-dom";
import {Payment} from "../../../commons/Payment";
import {IPaginator} from "../../../commons/IPaginator";
import Skeleton from "react-loading-skeleton";

const WalletPage = () => {

  let [loading, setLoading] = useState(false);
  let [show, setShow] = useState(false);
  let [amountText, setAmountText] = useState("");
  let [defaultMode, setDefaultMode] = useState(true);
  let [amount, setAmount] = useState("");
  let [selected, setSelected] = useState<boolean>(false);
  let navigate = useNavigate();
  let [loadingGate, setLoadingGate] = useState(false)


  let [data, setData] = useState<IPaginator<any> | undefined>(undefined)
  let [wallet, setWallet] = useState(-1)


  useEffect(() => {
    document.title = "کیف پول"
    if (!CookieManager.get("auth")) {
      navigate("/login?back=/wallet")
      return;
    }
    webRequest({path: "/wallet/page", auth: true}).then(response => {
      try {
        validateAuth(response);
        setData(response.payload.logs);
        setWallet(response.payload.wallet)
      } catch (e: any) {
        if (e.message == "login") navigate("/login?back=/wallet")
        else xToast.error(e.message)
      }
    })
  }, []);


  let paymentClicked = () => {
    if (!isNumeric(amount)) xToast.error("اعتبار وارد نشده یا اشتباه است")
    else if (parseInt(amount) <= 0) xToast.error("اعتبار وارد نشده یا اشتباه است")
    else if (parseInt(amount) < 5000) xToast.error("حد اقل مبلغ وارد شده باید 5,000 تومان باشد")
    else {
      setLoading(true);
      webRequest({
        path: "/wallet/charge",
        data: {
          amount: amount,
        },
        auth: true
      }).then((response) => {
        try {
          setLoading(false)
          validateAuth(response)
          validateResponse(response)
          Payment.start(response.payload)
          setLoadingGate(true)
        } catch (e: any) {
          xToast.error(e.message)
        }
      })
    }
  }


  const charge = (type: number) => {
    if (type == 0) setDefaultMode(true)
    else {
      setDefaultMode(false)
      setAmountText(type + "");
      setAmount(type + "")
    }
    setSelected(true)
    setShow(false)
  }


  return (
    <InquiryLayout>

      {!loadingGate && <>
        <Dialog show={show} close={() => setShow(false)} title={"انتخاب نحوه پرداخت"} height={350}>
          <div style={{borderBottom: "1px solid #eee", padding: '12px 16px', width: "calc(100% - 42px)", fontFamily: "main-font-bold", fontSize: '16px', cursor: "pointer"}} onClick={() => charge(0)}>مبلغ دلخواه</div>
          <div style={{borderBottom: "1px solid #eee", padding: '12px 16px', width: "calc(100% - 42px)", textAlign: "center", fontSize: '16px', cursor: "pointer"}} onClick={() => charge(20_000)}><Toman>20,000</Toman></div>
          <div style={{borderBottom: "1px solid #eee", padding: '12px 16px', width: "calc(100% - 42px)", textAlign: "center", fontSize: '16px', cursor: "pointer"}} onClick={() => charge(50_000)}><Toman>50,000</Toman></div>
          <div style={{borderBottom: "1px solid #eee", padding: '12px 16px', width: "calc(100% - 42px)", textAlign: "center", fontSize: '16px', cursor: "pointer"}} onClick={() => charge(100_000)}><Toman>100,000</Toman></div>
          <div style={{borderBottom: "1px solid #eee", padding: '12px 16px', width: "calc(100% - 42px)", textAlign: "center", fontSize: '16px', cursor: "pointer"}} onClick={() => charge(140_000)}><Toman>140,000</Toman></div>
          <div style={{padding: '12px 16px', width: "calc(100% - 42px)", textAlign: "center", fontSize: '16px', cursor: "pointer"}} onClick={() => charge(200_000)}><Toman>200,000</Toman></div>
        </Dialog>
        <section style={{width: "calc(100% - 48px)", margin: "auto"}}>
          <h1 style={{margin: "0", fontSize: "20px"}}>افزایش اعتبار کیف پول</h1>

          <div style={{display: 'flex', justifyContent: 'space-between', alignItems: "center", marginTop: "16px"}}>
            <div style={{display: "flex", height: "36px", alignItems: "center"}}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width={16} height={16}>
                <path d="M456 32C469.3 32 480 42.75 480 56C480 69.25 469.3 80 456 80H88C65.91 80 48 97.91 48 120V392C48 414.1 65.91 432 88 432H424C446.1 432 464 414.1 464 392V216C464 193.9 446.1 176 424 176H120C106.7 176 96 165.3 96 152C96 138.7 106.7 128 120 128H424C472.6 128 512 167.4 512 216V392C512 440.6 472.6 480 424 480H88C39.4 480 0 440.6 0 392V120C0 71.4 39.4 32 88 32H456zM352 304C352 286.3 366.3 272 384 272C401.7 272 416 286.3 416 304C416 321.7 401.7 336 384 336C366.3 336 352 321.7 352 304z"/>
              </svg>
              <div style={{paddingRight: "8px", fontFamily: "main-font-bold", position: "relative", top: "2px"}}>
                اعتبار کیف پول
              </div>
            </div>
            <div style={{position: "relative", top: "4px"}}>
              {data && <Toman>{numberFormat(wallet)}</Toman>}
              {!data && <Skeleton width={100} height={20}/>}
            </div>
          </div>

        </section>
        <section style={{width: "calc(100% - 48px)", margin: "24px auto 8px auto", fontFamily: "main-font-bold"}}>
          <div style={{color: "#444"}}>
            شیوه افزایش اعتبار را انتخاب کنید.
          </div>
          <div style={{background: "#f5faf8", border: "1px solid #abe8bf", marginTop: "8px", borderRadius: '16px', padding: "16px"}}>


            <div style={{display: "flex"}}>
              <div style={{width: 8, height: 8, border: "6px solid #0aaf58", borderRadius: "100%", marginRight: "2px"}}></div>

              <div>
                <div style={{paddingRight: "14px", fontFamily: "main-font-bold", color: "#444"}}>
                  شارژ با درگاه آنلاین
                </div>
                <div style={{paddingRight: "14px", color: "#222", fontFamily: "main-font-bold", marginTop: "8px"}}>
                  مبلغ اعتبار مورد نظر خود را انتخاب کنید
                </div>
              </div>
            </div>

            <div style={{border: "1px solid #eee", height: "48px", borderRadius: "12px", marginTop: "16px", width: "calc(100% - 100px)", margin: "16px auto 0", background: "#fff", paddingRight: "16px", display: "flex", justifyContent: "space-between", alignItems: "center", paddingLeft: "16px", cursor: "pointer"}} onClick={() => setShow(true)}>
              <div style={{position: "relative", top: "1px"}}>
                {selected && <>
                  {!defaultMode && <Toman>{numberFormat(amountText)}</Toman>}
                  {defaultMode && <>مبلغ دلخواه</>}
                </>}
                {!selected && <>
                  مبلغ اعتبار را انتخاب کنید.
                </>}
              </div>
              <div>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" width={16} height={16} fill="#444" style={{position: "relative", top: "6px"}}>
                  <path d="M201.4 374.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 306.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z"/>
                </svg>
              </div>
            </div>


            {defaultMode && selected && <div style={{width: "calc(100% - 64px)", margin: "32px auto"}}>
              <Input value={numberFormat(amount) + ""} setValue={setAmount} type={"number"} label={" مقدار دلخواه را وارد کنید (تومان) "}/>
            </div>}

            <div style={{width: "calc(100% - 64px)", margin: "0 auto", position: "relative", top: "-16px",marginTop:"32px"}}>
              <LoadingButton text={"اتصال به درگاه و پرداخت"} type={"success"} clicked={paymentClicked} loading={loading}/>
            </div>


          </div>
        </section>


        <section style={{width: "calc(100% - 48px)", margin: "24px auto 8px auto"}}>
          <div style={{color: "#444", fontFamily: "main-font-bold"}}>
            تاریخچه شارژ کیف پول
          </div>



          <div style={{paddingBottom:"32px"}}>
            {data && data.records.map((record, index) => {
              return <div style={{background: `${record.status == "پرداخت شده" ? "#f5faf8" : "#ffdfdf"}`, border: `${record.status == "پرداخت شده" ? "1px solid #abe8bf" : "1px solid   rgb(227, 185, 185"}`, marginTop: "8px", borderRadius: '16px', padding: "16px",fontSize:"16px"}} key={index}>
                <div style={{display: "flex"}}>
                  <div style={{paddingLeft: "8px"}}>مبلغ :</div>
                  <div>{<Toman>{numberFormat(record.amount)}</Toman>}</div>
                </div>
                <div style={{display: "flex"}}>
                  <div style={{paddingLeft: "8px"}}>وضعیت :</div>
                  <div style={{fontFamily: "main-font-bold"}}>{record.status}</div>
                </div>

                <div style={{display: "flex"}}>
                  <div style={{paddingLeft: "8px"}}>درگاه :</div>
                  <div style={{fontFamily: "main-font-bold"}}>{record.gateway.replace("درگاه","").replace("بانک","")}</div>
                </div>

                <div style={{display: "flex"}}>
                  <div style={{paddingLeft: "8px"}}>تاریخ تراکنش :</div>
                  <div style={{fontFamily: "main-font-bold"}}>{record.createdAt}</div>
                </div>


              </div>
            })}
            {!data && <>
              <Skeleton width={"100%"} height={"140px"} style={{borderRadius:"16px",marginTop:"8px"}}/>
              <Skeleton width={"100%"} height={"140px"} style={{borderRadius:"16px",marginTop:"8px"}}/>
              <Skeleton width={"100%"} height={"140px"} style={{borderRadius:"16px",marginTop:"8px"}}/>
              <Skeleton width={"100%"} height={"140px"} style={{borderRadius:"16px",marginTop:"8px"}}/>
              <Skeleton width={"100%"} height={"140px"} style={{borderRadius:"16px",marginTop:"8px"}}/>
            </>}
          </div>


        </section>


      </>}

      {loadingGate && <>
        <div style={{textAlign: "center", height: "100px", position: "fixed", left: "0", right: "0", top: "0", bottom: "0", margin: "auto auto"}}>
          <div>
            در حال انتقال به درگاه پرداخت
          </div>
          <div className="button-loader-dark" style={{width: "12px", height: "12px", position: "relative", top: 8}}></div>
        </div>
      </>}

    </InquiryLayout>
  );
};

export default WalletPage;