import React from 'react';
import Skeleton from "react-loading-skeleton";

const ArticleLoader = () => {
  return (
    <>
      <div style={{boxShadow: '0 0 7px 0 #ccc', borderRadius: "12px", padding: '4px 8px 0px 8px',marginBottom:"16px"}}>
        <Skeleton width="100%" height="180px"/>
        <Skeleton width="100%" height="12px" style={{marginTop: "8px"}}/>
      </div>

      <div style={{boxShadow: '0 0 7px 0 #ccc', borderRadius: "12px", padding: '4px 8px 0px 8px',marginBottom:"16px"}}>
        <Skeleton width="100%" height="180px"/>
        <Skeleton width="100%" height="12px" style={{marginTop: "8px"}}/>
      </div>

      <div style={{boxShadow: '0 0 7px 0 #ccc', borderRadius: "12px", padding: '4px 8px 0px 8px',marginBottom:"16px"}}>
        <Skeleton width="100%" height="180px"/>
        <Skeleton width="100%" height="12px" style={{marginTop: "8px"}}/>
      </div>

      <div style={{boxShadow: '0 0 7px 0 #ccc', borderRadius: "12px", padding: '4px 8px 0px 8px',marginBottom:"16px"}}>
        <Skeleton width="100%" height="180px"/>
        <Skeleton width="100%" height="12px" style={{marginTop: "8px"}}/>
      </div>

      <div style={{boxShadow: '0 0 7px 0 #ccc', borderRadius: "12px", padding: '4px 8px 0px 8px',marginBottom:"16px"}}>
        <Skeleton width="100%" height="180px"/>
        <Skeleton width="100%" height="12px" style={{marginTop: "8px"}}/>
      </div>

      <div style={{boxShadow: '0 0 7px 0 #ccc', borderRadius: "12px", padding: '4px 8px 0px 8px',marginBottom:"16px"}}>
        <Skeleton width="100%" height="180px"/>
        <Skeleton width="100%" height="12px" style={{marginTop: "8px"}}/>
      </div>

      <div style={{boxShadow: '0 0 7px 0 #ccc', borderRadius: "12px", padding: '4px 8px 0px 8px',marginBottom:"16px"}}>
        <Skeleton width="100%" height="180px"/>
        <Skeleton width="100%" height="12px" style={{marginTop: "8px"}}/>
      </div>


    </>
  );
};

export default ArticleLoader;