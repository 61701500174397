import {toast, TypeOptions} from "react-toastify";

export class XToast {
  private id: any = -1

  loading(message: string): XToast {
    this.id = toast.loading(message)
    return this;
  }

  update(type: TypeOptions, newMessage = '') {
    toast.update(this.id, {type, icon: false, closeButton: true, draggable: true, theme: "colored", render: newMessage})
  }

  error(message:any) {
    return toast.error(message,{
      type:"error",
      theme:"colored",
      autoClose:3000
    })
  }

  warning(message:any) {
    return toast.warning(message,{
      type:"warning",
      theme:"colored",
      autoClose:3000
    })
  }

  success(message:any) {
    return toast.success(message,{
      type:"success",
      theme:"colored",
      autoClose:3000
    })
  }

  updateAndDismiss(type: TypeOptions, newMessage = '', delay: number = 3000) {
    toast.update(this.id, {type, icon: false, theme: "colored", render: newMessage})
    if (delay > 0) setTimeout(() => {
      toast.dismiss(this.id)
    }, delay)
  }


  dismiss() {
    toast.dismiss(this.id)
  }


}


let xToast = new XToast();
export default xToast