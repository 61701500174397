import React, {useRef} from 'react';
import "./input.css"
import {getOnlyNumbers} from "../../../commons/number";

interface InputProps {
  value: string | undefined | null,
  setValue: Function,
  type: "text" | "number",
  label: string
}

const Textarea = (props: InputProps) => {

  let inputRef: any = useRef(null);

  let type = "text"
  if (props.type === "number") {
    type = "tel"
  }


  const initValue = (event: any) => {
    let value = event.target.value;

    if (props.type === "number") {
      value = getOnlyNumbers(value)
    }


    props.setValue(value)

  }

  let clicked = () => {
    inputRef.current?.focus()
  }

  return (
    <div className="input-wrapper">
      <textarea ref={inputRef} className={`input input-text ${props.type == "number" ? "ls" : ""}`} style={{height:"100px !important;",width:"100%"}} autoComplete="off" placeholder="" required value={props.value ?? +""} onChange={initValue}/>
      <label className="input-label" onClick={clicked}>{props.label}</label>
    </div>
  );
};

export default Textarea;