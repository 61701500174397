import React from 'react';
import Skeleton from "react-loading-skeleton";
import ArticleLoader from "./ArticleLoader";

const BlogLoader = () => {
  return (
    <>
      <div style={{display: "flex", marginTop: "16px"}}>
        <Skeleton width={"20px"} height={"180px"} style={{borderBottomLeftRadius: "12px", borderTopLeftRadius: "12px"}}/>
        <div style={{width: "10px"}}></div>
        <div style={{width: "calc(100%  - 60px)"}}>
          <Skeleton height={"180px"} style={{borderRadius: "12px"}}/>
        </div>
        <div style={{width: "10px"}}></div>
        <Skeleton width={"20px"} height={"180px"} style={{borderBottomRightRadius: "12px", borderTopRightRadius: "12px"}}/>
      </div>

      <div style={{width: "calc(100% - 32px)", margin: "16px auto"}}>
        <Skeleton height={"40px"}/>
      </div>


      <div style={{width: "calc(100% - 16px)", margin: "16px auto 16px 0", display: "flex"}}>
        <div style={{width: "38%"}}>
          <Skeleton height={"160px"}/>
        </div>
        <div style={{width: "38%", marginRight: "2%"}}>
          <Skeleton height={"160px"}/>
        </div>
        <div style={{width: "20%", marginRight: "2%"}}>
          <Skeleton height={"160px"}/>
        </div>


      </div>
      <div style={{width: "calc(100% - 32px)", margin: "16px auto"}}>
        <Skeleton height={"40px"}/>
      </div>
      <div style={{width:"calc(100% - 32px)", margin: "16px auto"}}>
        <ArticleLoader/>
      </div>
    </>

  );
};

export default BlogLoader;