import {UserState} from "./Types/UserState";

interface ICar {
  name: string,
  image: string,
  slug: string,
}

export interface IState {
  isLoaded: boolean,
  search: string
  pwa: {
    showInstallPopup: boolean
  },
  articles: any[],
  slides: any[],
  cars: ICar[],
  car: any,
  utmSource: {
    date: null,
    data: null
  } | null
}


let state: IState = {
  pwa: {
    showInstallPopup: true
  },
  slides: [],
  cars: [],
  car: {},
  isLoaded: false,
  search: "",
  articles: [],
  utmSource: null
}


export default state


