import React, {useEffect, useState} from 'react';

import "./styles.css"
import Alert from "../../../Ui/Alert/Alert";
import IconInfo from "../../../Ui/Icons/IconInfo";
import Switch from "react-switch";
import InquiryLayout from "../../../Layouts/Inquiry/InquiryLayout";
import Description from "./Description";
import {webRequest} from "../../../../Services/apiService";
import Faqs from "./Faqs";
import TitleBox from "./TitleBox";
import {IPlate, IPlateDetail} from "../../../Ui/Plate/IPlate";
import LoadingButton from "../../../Ui/Button/LoadingButton";
import Skeleton from "react-loading-skeleton";
import PlateTools from "../../../Ui/Plate/PlateTools/PlateTools";
import {useNavigate} from "react-router-dom";
import xToast from "../../../../commons/xToast";
import {validateResponse} from "../../../../Infrastructure/Api/ValidateResponse";
import LongDescription from "./LongDescription";

const FreewayInquiryPage = () => {
  let [autoPay, setAutoPay] = useState(false);
  let [response, setResponse] = useState<any>(undefined)
  let [loading, setLoading] = useState(false);
  const initAutoPay = (data: any) => setAutoPay(data);

  let [plate, setPlate] = useState<IPlateDetail | undefined>(undefined);

  let [miniPlate, setMiniPlate] = useState<IPlate | undefined>(undefined);
  let navigate = useNavigate()


  useEffect(() => {
    document.title = "استعلام و پرداخت عوارض آزادراه";
  }, []);


  useEffect(() => {
    webRequest({
      path: '/pages/freeway',
      auth: true
    }).then(response => {
      setResponse(response.payload)
      try {
        setPlate(response.payload.plate.plate)
        setMiniPlate(response.payload.plate.plate.plate)
      } catch (e: any) {

      }

      try {
        // setPlate(response.payload.plate.plate.plate)
      } catch (e: any) {

      }
    })
  }, []);


  const inquiry = () => {
    setLoading(true);
    webRequest({
      path: "/freeway/getPlateCode",
      auth: true,
      data: {
        plate: miniPlate
      }
    }).then(response => {
      try {
        validateResponse(response)
        navigate("/inquiry/freeway-tolls/" + response.payload)
      } catch (e: any) {
        xToast.error(e.message)
      }

    })
  }


  const onPlateChanged = (plates: IPlateDetail[], plate: IPlateDetail) => {
    setResponse({
      ...response,
      plates: plates,
    })
    setPlate(plate)
    setMiniPlate(plate.plate)
  }


  const onPlateStatusChanged = (done: boolean, plate: IPlate) => {
    if (done) setMiniPlate(plate)
    else setMiniPlate(undefined)
  }


  return (
    <InquiryLayout>

      <div className="x-card m16">
        <TitleBox/>

        <PlateTools
          plates={response ? response.plates : []}
          plate={(response && response.plate.hasPlate) ? plate : undefined}
          onPlateChanged={onPlateChanged}
          hasPlate={response && response.hasPlate}
          onPlateDone={onPlateStatusChanged}
          loadingMode={!response}/>


        {/*<div style={{display: "flex", alignItems: 'center', justifyContent: "space-between", padding: "0 8px", marginTop: "20px"}}>*/}
        {/*  <div style={{display: "flex", alignItems: 'center'}}>*/}
        {/*    <div style={{position: "relative", top: "1px", color: "#666", fontSize: "13px"}}>*/}
        {/*      پرداخت خودکار*/}
        {/*    </div>*/}
        {/*    &nbsp;*/}
        {/*    &nbsp;*/}
        {/*    <IconInfo color={"#061c2f"} width={12} height={12} styles={{borderRadius: "100%", border: "2px solid #061c2f", padding: "2px", cursor: "pointer"}}/>*/}
        {/*  </div>*/}
        {/*  <Switch checkedIcon={false} uncheckedIcon={false} height={18} width={40} onChange={initAutoPay} checked={autoPay}/>*/}
        {/*</div>*/}

        <div className={"mt-2"}>
          <Alert type={"warning"} message={" طبق قانون جدید عوارض آزادرا‌هی ثبت شده پس از ۱۵ روز مشمول جریمه دیرکرد می‌شود. "}/>
        </div>
        <div style={{marginTop:"32px"}}>
          <LoadingButton text={"استعلام عوارض آزادراهی"} clicked={inquiry} type={"success"} loading={loading}/>
        </div>
      </div>


      {!response && <div style={{padding: "8px 16px"}}>
        <Skeleton width={"200px"} height={20}/>
        <Skeleton height={10} style={{marginTop: "16px"}}/>
        <Skeleton height={10}/>
        <Skeleton height={10}/>
        <Skeleton height={10}/>
        <Skeleton height={10}/>
        <Skeleton height={10}/>
        <Skeleton height={10} width={"200px"}/>
        <Skeleton height={10}/>
        <Skeleton height={10}/>
        <Skeleton height={10}/>
        <Skeleton height={10}/>
        <Skeleton height={10}/>
        <Skeleton height={10}/>
        <Skeleton height={10}/>
        <Skeleton height={10}/>
        <Skeleton height={10}/>
        <Skeleton height={10} style={{width: "100px"}}/>
        <Skeleton height={10}/>
      </div>}

      {/*{response && <Description/>}*/}


      <br/>
      {response && <Faqs records={response.faqs}/>}
      <br/>
      {/*<div style={{padding:"0 16px",borderRadius:"12px",overflow:"hidden"}}>*/}
      {/*  <LongDescription/>*/}
      {/*</div>*/}
    </InquiryLayout>
  );
};

export default FreewayInquiryPage;