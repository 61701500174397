import React, {useEffect, useState} from 'react';
import InquiryLayout from "../../../../Layouts/Inquiry/InquiryLayout";
import {Link, useNavigate, useParams} from "react-router-dom";
import {webRequest} from "../../../../../Services/apiService";
import {validateAuth, validateResponse} from "../../../../../Infrastructure/Api/ValidateResponse";
import Skeleton from "react-loading-skeleton";
import Toman from "../../../../Layouts/Ui/Toman";
import {numberFormat} from "../../../../../commons/number";
import LoadingButton from "../../../../Ui/Button/LoadingButton";
import xToast from "../../../../../commons/xToast";
import {Payment} from "../../../../../commons/Payment";

const FreewayGatewayCallback = () => {
  let params = useParams()
  let [success, setSuccess] = useState<any>(undefined)
  let [loading, setLoading] = useState<boolean>(false)
  let [paymentLoading, setPaymentLoading] = useState<boolean>(false)
  let [loaded,setLoaded] = useState<boolean>(false)
  let [amount, setAmount] = useState(0)

  let navigate = useNavigate()

  useEffect(() => {
    webRequest({
      path: "/freeway/getResult",
      auth: true,
      data: {
        id: params.id
      }
    }).then(response => {
      try {
        setLoaded(true)
        setLoading(false)
        validateAuth(response.payload)
        validateResponse(response);
        setSuccess(response.payload.success)
        setAmount(response.payload.amount)
      } catch (e: any) {
        if (e.message == "login") window.location.href = "/"
        xToast.error("تراکنش یافت نشد");
      }
    })
  }, []);

  const back = () => navigate("/inquiry/freeway-tolls")

  const payAgain = () => {
    setLoading(true);
    webRequest({
      path: "/freeway/repayment",
      data: {
        id: params.id,
      },
      auth: true
    }).then((response) => {

      try {
        setLoading(false)
        validateAuth(response.payload)
        validateResponse(response)
        Payment.start(response.payload.data)
        setPaymentLoading(true)
      } catch (e: any) {
        if (e.message == "login") {
          navigate("/login?back=/wallet-callback?id=" + success.id)
        } else {
          xToast.error(e.message)
        }
      }
    })
  }


  return (
    <InquiryLayout>
      {!paymentLoading && <>
        {!loaded && <div style={{border: "1px solid #eee", borderRadius: "12px", padding: "32px 16px", width: "calc(100% - 64px)", margin: "auto", position: "relative", top: "0px", paddingTop: "32px"}}>
          <div style={{width: '120px', margin: "auto", textAlign: "center"}}>
            <Skeleton width={110} height={110} style={{borderRadius: "100%"}}/>
          </div>
          <div style={{margin: "auto", textAlign: "center", fontFamily: "main-font-bold", fontSize: "20px", marginTop: "24px"}}>
            <Skeleton width={"320px"} height={20}/>
          </div>
          <div style={{display: "flex", justifyContent: "center", marginTop: "8px"}}>
            <Skeleton width={"100px"} height={20}/>
          </div>

          <div style={{paddingBottom: "12px", marginTop: "24px"}}>
            <Skeleton width={"100%"} height={48}/>
          </div>
        </div>}

        {loaded && <div style={{border: "1px solid #eee", borderRadius: "12px", padding: "8px 16px", width: "calc(100% - 64px)", margin: "auto", position: "relative", top: "0px", paddingTop: "32px"}}>
          <div style={{width: '120px', margin: "auto", textAlign: "center"}}>
            {success.status == "پرداخت نشده" && <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="110px" height="110px" fill="#EF4056">
              <path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM175 175c9.4-9.4 24.6-9.4 33.9 0l47 47 47-47c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9l-47 47 47 47c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-47-47-47 47c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l47-47-47-47c-9.4-9.4-9.4-24.6 0-33.9z"/>
            </svg>}
            {success.status == "پرداخت شده" && <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="110px" height="110px" fill="#0AAF58">
              <path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209L241 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L335 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z"/>
            </svg>}
          </div>
          <div style={{margin: "auto", textAlign: "center", fontFamily: "main-font-bold", fontSize: "20px", marginTop: "8px"}}>
            {!success && "پرداخت لغو شد!"}
            {success && "پرداخت با موفقیت انجام شد"}
          </div>
          <div style={{display: "flex", justifyContent: "center", marginTop: "8px"}}>
            <div style={{fontFamily: "main-font-bold"}}> مبلغ :</div>
            &nbsp;
            <Toman>{numberFormat(amount)}</Toman>
          </div>

          <div style={{paddingBottom: "12px",marginTop:"32px"}}>
            {!success && <LoadingButton text={"پرداخت مجدد"} clicked={payAgain} loading={loading} type={"primary"}/>}
            {success && <LoadingButton text={"بازگشت به عوارض آزادراهی"} clicked={back} loading={loading} type={"success"}/>}
          </div>

          <div className={"tac mt-3"} style={{paddingBottom:"8px"}}>
            <Link to={"/inquiry/freeway-tolls"} style={{textDecoration:"none",color:"#0090a6",borderBottom:"1px dashed #0090a6"}}>
              بازگشت به عوارض آزادراهی
            </Link>
          </div>
        </div>}
      </>}

      {paymentLoading && <div style={{textAlign: "center", height: "100px", position: "fixed", left: "0", right: "0", top: "0", bottom: "0", margin: "auto auto"}}>
        <div>
          در حال انتقال به درگاه پرداخت
        </div>
        <div className="button-loader-dark" style={{width: "12px", height: "12px", position: "relative", top: 8}}></div>
      </div>}

    </InquiryLayout>
  );
};

export default FreewayGatewayCallback;