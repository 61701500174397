import React, {useState} from 'react';
import useAppContext from "../../../state/useAppContext";
import "./cars.css"
import AddCarDialog from "./AddCarDialog";
import {CarManager} from "../../../Infrastructure/StorageManager/CarManager";


const CarList = () => {
  let {state, dispatch} = useAppContext()

  let [showAddDialog, setShowAddDialog] = useState(false)


  if (!state.isLoaded) {
    return <></>
  }

  const removeCar = (row: any) => {
    CarManager.delete(state, dispatch, row)
  }


  let cars = state.cars

  if (typeof cars != "object") {
    cars = []
  }

  try {
    cars.map(row=>{})
  }catch (e:any){
    cars=[];
  }


  return (
    <div>
      <AddCarDialog show={showAddDialog} setShow={setShowAddDialog}/>
      {cars.length === 0 && <div className={"tac"} style={{color: "#444", marginTop: 32}}>
        تاکنون خودرویی اضافه نکرده اید
        <div style={{marginTop: "8px"}}>
          <span className={"link"} onClick={() => setShowAddDialog(true)}>
            افزودن خودرو
          </span>
        </div>
      </div>}

      {cars.length > 0 &&
        <div style={{display: "flex", justifyContent: "space-between", padding: "0 8px", fontSize: "13px"}}>
          <span style={{color: "#333"}}>خودرو های من</span>
          <span className={"link"} onClick={() => setShowAddDialog(true)}>
            افزودن خودرو
        </span>
        </div>}


      <div className={"cars"}>
        {cars.map((row: any, index) => {
          return <div key={index} style={{
            background: "#fff",
            marginTop: "8px",
            display: "flex",
            padding: "8px",
            margin: "8px",
            borderRadius: "8px"
          }}>
            <img src={row.image} style={{width: "120px"}} alt=""/>
            <div>
              <div style={{color: "#222", fontSize: "13px", paddingRight: "8px"}}>{row.name}</div>
              <div className={""} style={{marginTop: "32px"}}>
                <a href={"https://mashinno.com"+row.link}>
                      <span className={"button-1"}>
                  همه محصولات
                </span>
                </a>
                &nbsp;
                <span className={"button-2"} onClick={() => {
                  removeCar(row)
                }}>
                  حذف خودرو
                </span>
              </div>
            </div>
          </div>
        })}
      </div>


    </div>
  )

};

export default CarList;